import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Icon from './Icon';

const Remark = props => {

    return (
        <div className="fixed bottom-0 left-0 w-screen h-screen visible outro z-[999999999999]">
            <div className="border-[1px] border-[#DDD] px-[30px] py-[30px] tablet:max-w-[600px] w-[90%] max-w-[90%] tablet:w-auto max-h-[60vh] tablet:max-h-full h-auto absolute top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white opacity-100 z-[999999] overflow-y-scroll overflow-x-hidden">
                <button className="absolute right-[15px] top-[10px]" onClick={props.handleClose}>
                    <Icon className="text-black text-xs" icon={faXmark} />
                </button>
                {props.children}
            </div>
            <div className="absolute w-screen h-screen bg-black opacity-50 z-[999]"></div>
        </div>
    )
}

export default Remark