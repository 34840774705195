import {Comment as CommentObject} from "../../types/entities";
import {Comment} from "./Comment";

interface CommentTreeProps {
    comments: CommentObject[]
    onDelete: (c: CommentObject) => void
}

export const CommentTree = ({ comments, onDelete }: CommentTreeProps) => {
    return (
        <div>
            { comments.map((comment) => {
                return <Comment key={comment.id} comment={comment} onDelete={onDelete} />
            }) }
        </div>
    )
}