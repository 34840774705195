import React, {InputHTMLAttributes} from "react";
import {UseFormRegisterReturn} from "react-hook-form/dist/types/form";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    suffixElement?: Element | JSX.Element
    prefixElement?: Element | JSX.Element
    inputClassName?: string | object
    register?: UseFormRegisterReturn,
    error?: string,
    onChange?: (e) => void,
    name?: string,
    onInput?: (e) => void;
}

export default function Input({prefixElement, suffixElement, inputClassName, className, name, onInput, register, error, onChange,...props}:  InputProps) {
    return (
        <>
            <div className={`border border-gray-500 flex items-center h-10 space-x-2 ${error ? 'border-red-500' : 'border-gray-500'} ${className}`}>
                {prefixElement ? <div className='h-full flex items-center'>{prefixElement}</div> : null}
                <input {...register} name={name} className={`outline-none h-full max-w-full px-3 flex-1 placeholder:font-light ${inputClassName}`} {...props} onChange={onChange} onInput={onInput}/>
                {suffixElement ? <div className='h-full flex items-center'>{suffixElement}</div>  : null}
            </div>
            <small className='text-red-500 font-light text-xs'>{error}</small>
        </>

    )
}