import React, {useEffect, useRef, useState} from "react";
import SceneHeader from "./SceneHeader";
import SceneBottomBar from "./SceneBottomBar";
import {useGlobalState} from "../../utils/GlobalState";
import {useTranslation, WithTranslation, withTranslation} from "react-i18next";
import {useScene} from "../../providers/SceneProvider";
import i18n, {t} from "i18next";
import Api from "../../Api";
import {AxiosError} from "axios";
import CheckableSceneLabel from "../CheckableSceneLabel";
import {Button} from "../../styled-components/Button";
import {H4} from "../../styled-components/Headings";
import SceneErrorPopup from "./SceneErrorPopup";
import SanitizeHTML from "../SanitizeHTML";
import {isMobile, mobileModel, mobileVendor} from "react-device-detect";
import {TabletWidth} from "../../constants/ScreenWidth";
import CheckableObjectLabel from "../CheckableObjectLabel";
import BottomDrawer from "../BottomDrawer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {i18nStringFromObject} from "../../plugins/i18n";

function SceneNextLevelContainer(i18n: WithTranslation) {
    const [selectedLabel, setSelectedLabel] = useGlobalState('selectedLabel', null)
    const scene = useScene();
    const [missingLabels, setMissingLabels] = useState([]);
    const [showPostError, setShowPostError] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const {width} = useWindowDimensions()

    useEffect(() => {
        localStorage.setItem("notes", '');
        scene.setSkipOption(null)
    }, [])

    useEffect(() => {
        return () => {
            setSelectedLabel(null)
        }
    }, [])

    useEffect(() => {
        setMissingLabels(scene.labels)
    }, [])

    const submit = (skip = false) => {
        const values = {};

        if (skip) {
            values['next-level-dont-know'] = {
                annotations: [{
                    type: 'next-level-dont-know',
                    content: scene.skipOption
                }]
            }
        }

        if (scene.explicit) {
            values['explicit'] = {
                annotations: [{
                    type: 'explicit',
                }]
            }
        }

        if (scene.isChecked && scene.remark !== "") {
            values['remark'] = {
                annotations: [{
                    type: 'remark',
                    content: scene.remark
                }]
            }
        }

        if (Object.entries(values).length) {
            Api.post('/attach-annotations-to-photo',
                {
                    photoId: scene.image.id,
                    labels: values,
                    device: mobileVendor + ' ' + mobileModel
                },
                () => {
                    if (skip) {
                        scene.next(null)
                    }
                },
                null,
                null,
                (error: AxiosError) => {
                    if (skip) {
                        setShowPostError(true);
                    }
                })
        }

        if (!skip) {
            if (scene.selectedLabel) {
                setMissingLabels(scene.labels.filter(l => scene.selectedLabel.find(sl => l.id === sl.id) == null).map(l => l.id));
            } else {
                setMissingLabels(scene.labels.map(l => l.id));
            }

            if (scene.selectedLabel.length === scene.labels.length) {
                Api.post('/attach-checked-labels-to-photo',
                {
                    photoId: scene.image.id,
                    labels: scene.selectedLabel.map(l => ({
                        labelId: l.id,
                        valid: l.checkStatus
                    }))
                },
                () => {
                    scene.next(null)
                },
                null,
                null,
                (error: AxiosError) => {
                    setShowPostError(true);
                })
                setIsDisabled(true)
            }
        }
    }

    const TutorialCheck = () => {
        const { i18n } = useTranslation();

        return (
            <div className={"outro"}>
                <div className="h-full tablet:min-w-[400px] my-auto space-y-4 flex flex-col justify-center">
                    <div className="h-full my-auto space-y-4 flex flex-col justify-center">
                        <SanitizeHTML>
                            { i18nStringFromObject(scene.batch, 'batchIntroNextLevelCheck')}
                        </SanitizeHTML>
                    </div>
                </div>
            </div>
        )
    }

    const Skip = () => {
        const t = i18n.t;
        const input = useRef(null)
        const [message, setMessage] = useState('');

        const handleMessageChange = event => {
            setMessage(event.target.value);
        };

        const send = () => {
            if (message !== null) {
                scene.setSkipOption(message)
            }
        }

        useEffect(() => {
            if (scene.skipOption !== null) {
                submit(true)
            }
        }, [message])

        return (
            <div className={"outro"}>
                <div className={`h-full tablet:min-w-[400px] my-auto space-y-4 flex flex-col justify-center`}>
                    <div className="h-full my-auto space-y-4 flex flex-col justify-center">
                        <h1>{t("annotate.popups.skip.title")}</h1>
                        <p>{t("annotate.popups.skip.text")}</p>
                        <form>
                            <div className={`w-full block}`}>
                                <h2 className={"font-mabry text-[14px] font-normal"}>{t("annotate.popups.skip.explanation_title")}</h2>
                                <textarea id='remark'
                                          ref={input}
                                          className="w-full !mt-0 p-2 overflow-auto outline-0 resize-none text-[14px] font-mabry font-normal border-[1px] border-gray-500 min-h-[185px]"
                                          name="remark"
                                          value={message}
                                          onChange={handleMessageChange}/>
                                <h2 className={"font-mabry text-[14px] font-normal"}>{t("annotate.popups.skip.extra_info")}</h2>
                            </div>
                            <div className={"flex justify-center space-x-2 pt-3"}>
                                <Button disabled={!message} onClick={() => {send()} }
                                        $color={'light-primary'}>{t("annotate.popups.skip.actions.skip")}</Button>
                                <Button onClick={scene.toggleSkipRemark} $color={'light-white'}>{t("general.actions.cancel")}</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    const Remark = () => {
        const t = i18n.t;
        const input = useRef(null)

        const addRemark = () => {
            if (input && input.current) {
                scene.setRemark(input.current.value)
            }
            scene.setRemarkSubmitted(true)
        }

        return (
            <div className={"outro w-full"}>
                {scene.remarkSubmitted ? (
                    <div className={`h-full tablet:min-w-[400px] my-auto space-y-4 flex flex-col justify-center`}>
                        <div className="h-full my-auto space-y-4 flex flex-col justify-center">
                            <h1>{t("annotate.popups.remark.after_sent.title")}</h1>
                            <p>{t("annotate.popups.remark.after_sent.text")}</p>
                        </div>
                    </div>
                ) : (
                    <div className={`tablet:min-w-[400px] my-auto space-y-4 flex flex-col justify-center`}>
                        <h1>{t("annotate.popups.remark.title")}</h1>
                        <p dangerouslySetInnerHTML={{__html: t("annotate.popups.remark.text")}} />
                        <label className={`text-xs font-light space-x-1 flex-1 flex items-center`}>
                            <input id="explicit" type="checkbox"
                                   className="h-[16px] w-[16px] border-[1px] border-gray-warm rounded-none"
                                   onChange={() => scene.setExplicit(!scene.explicit)} checked={scene.explicit}
                            />
                            <span>{t("annotate.popups.remark.options.mark_as_explicit_content")}</span>
                        </label>
                        <label className={`text-xs font-light space-x-1 flex-1 flex items-center`}>
                            <input id="other-remark" type="checkbox"
                                   className="h-[16px] w-[16px] border-[1px] border-gray-warm rounded-none"
                                   onChange={() => scene.setIsChecked(!scene.isChecked)} checked={scene.isChecked}/>
                            <span>{t("annotate.popups.remark.options.add_remark")}</span>
                        </label>
                        <div className={`w-full ${scene.isChecked ? 'block' : 'hidden'}`}>
                            <h2 className={"font-mabry text-[14px] font-normal"}>{t("annotate.popups.remark.add_remark_explanation")}</h2>
                            <textarea id='remark'
                                      ref={input}
                                      className="w-full !mt-0 p-2 overflow-auto outline-0 resize-none text-[14px] font-mabry font-normal border-[1px] border-gray-500 min-h-[185px]"
                                      name="remark"/>
                            <h2 className={"font-mabry text-[14px] font-normal"}>{t("annotate.popups.remark.add_remark_extra_info")}</h2>
                        </div>
                        <div className={"flex relative tablet:flex-row flex-col tablet:justify-center w-full tablet:w-auto gap-2 pt-3"}>
                            <Button onClick={() => {
                                addRemark()
                            }}
                                    $color={'light-primary'}>{t("annotate.popups.remark.actions.add")}</Button>
                            <Button onClick={scene.toggleRemark}
                                    $color={'light-white'}>{t("general.actions.cancel")}</Button>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <>
            { (isMobile || window.innerWidth <= TabletWidth) ?
                <>
                    {(scene.overlay && !scene.isSubStep) && <div className={`absolute bottom-0 left-0 right-0 bg-black h-screen w-screen opacity-50 z-[78] transition-all duration-500 ease-in-out`}></div>}
                    <SceneHeader remark={<Remark />} tutorialElement={<TutorialCheck />} type='scene-next-level'/>
                    <BottomDrawer isNextLevel annotableLabel={
                        <>
                            {scene.labels.length === 0 && <span
                                className='text-center text-sm opacity-50 mt-2'>{t('annotate.labels_container.no_labels_found')}</span>}
                            {scene.labels?.map((label, key) => {
                                return <CheckableSceneLabel key={key} label={label} isMissing={missingLabels.includes(label.id)}/>
                            })}
                        </>
                    }/>
                    <div className={"bottom-0 fixed z-[101] w-full"}>
                        <SceneBottomBar skipRemark={<Skip />} canContinue={!isDisabled} onSubmit={() => {submit()}}/>
                        { showPostError && <SceneErrorPopup onClose={() => setShowPostError(false)} />}
                    </div>
                    { showPostError && <SceneErrorPopup onClose={() => setShowPostError(false)} />}
                </>
                :
                <>
                    <div className={scene.expanded ? 'w-[5%]' : 'bg-gray-200 w-5/12 h-full flex flex-col'}>
                        <SceneHeader remark={<Remark />} tutorialElement={<TutorialCheck />} type='scene-next-level'/>
                        <div className={scene.expanded ? 'hidden' : 'flex-1 flex flex-col overflow-y-auto px-9 px-2 mb-2 mt-6'}>
                            <div className={'mb-8'}>
                                <h1 className={'font-mabry font-medium text-[14px] pb-2'}>Opmerking van de controleur</h1>
                                <div className={'bg-gray-300 shadow p-4'}>
                                    <div className={"flex flex-1 space-x-5"}>
                                        <img className={"w-[80px] h-[80px]"} src={scene.nextLevelComment.annotator.profilePicture} alt=""/>
                                        <div>
                                            <H4>{scene.nextLevelComment.annotator.firstname}  {scene.nextLevelComment.annotator.surname}</H4>
                                            <p className={"italic text-[10px]"}>{scene.nextLevelComment.createdAt}</p>
                                            <p className={"mt-4"}>{ scene.nextLevelComment.comment }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*{ scene.batch.phase === "Fase 2" ?
                        <h1 className={'font-montserrat font-medium text-[14px] pb-2'}>AI-Tags nog te bepalen</h1> :
                        <h1 className={'font-montserrat font-medium text-[14px] pb-2'}>Tags nog te bepalen</h1>
                    }*/}
                            <div className="flex min-h-min flex-col w-full space-y-1" id='label-tray'>
                                {scene.labels.length === 0 && <span
                                    className='text-center text-sm opacity-50 mt-2'>{t('annotate.labels_container.no_labels_found')}</span>}
                                {scene.labels?.map((label, key) => {
                                    return <CheckableSceneLabel key={key} label={label} isMissing={missingLabels.includes(label.id)}/>
                                })}
                            </div>
                        </div>
                        <SceneBottomBar skipRemark={<Skip />} canContinue={!isDisabled} onSubmit={() => {submit()}}/>
                    </div>
                    { showPostError && <SceneErrorPopup onClose={() => setShowPostError(false)} />}
                </>
            }
        </>
    )
}

export default withTranslation()(SceneNextLevelContainer)