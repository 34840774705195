export default function BatchSkeleton() {
    return <div className="flex h-32 animate-pulse border border-gray-500">
        <div className='bg-gray-500 animate-pulse tablet-landscape:min-w-[180px]'/>
        <div className='flex flex-col w-full'>
            <div className='flex-1 flex flex-col justify-center px-6 space-y-2'>
                <div className='bg-gray-500 animate-pulse h-4 w-2/6' />
                <div className='bg-gray-500 animate-pulse h-3 w-2/4' />
            </div>
            <div className='flex flex-col'>
                <div />
                <div className='w-full h-4 bg-gray-500' />
            </div>
        </div>
    </div>
}