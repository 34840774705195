import Viewer from "../../components/Viewer";
import {useEffect} from "react";
import {useAnnotation} from "../../providers/AnnotationProvider";
import SceneProvider from "../../providers/SceneProvider";
import ObjectAnnotateContainer from "../../components/Scene/ObjectAnnotateContainer";

export default function ObjectAnnotatePage() {
    const annotationProvider = useAnnotation();

    //On tool and drawing change
    useEffect(() => {
        if (annotationProvider.annotorious) {
            annotationProvider.annotorious.setDrawingEnabled(annotationProvider.tool !== null)

            if (annotationProvider.tool) {
                annotationProvider.annotorious.setDrawingTool(annotationProvider.tool)
            }
        }
    }, [annotationProvider.tool])


    return (
        <div className='h-screen tablet:overflow-y-auto overflow-y-hidden w-full flex'>
            <SceneProvider>
                <Viewer/>
                <ObjectAnnotateContainer />
            </SceneProvider>
        </div>
    )
}
