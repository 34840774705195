import Input from "../Input";
import {Button} from "../../styled-components/Button";
import {useAuth} from "../../providers/AuthProvider";
import {useEffect, useRef, useState} from "react";
import Api from "../../Api";
import {Comment} from '../../types/entities'
import {useTranslation} from "react-i18next";
import Textarea from "../Textarea";

export const CommentInput = ({ parent, type, onComment }: { parent: string, type: string, onComment?: (c: Comment) => void }) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const [comment, setComment] = useState('')
    const [canSubmit, setCanSubmit] = useState<boolean>(false)

    useEffect(() => {
        setCanSubmit(comment && comment.length > 0)
        console.log(comment)
    }, [comment])

    const submitComment = () => {
        Api.post(
            'comment',
            { comment: comment, type, parent},
            ({data}) => {
                onComment(data)
                setComment('');
            },
            null,
            null,
            () => {

            }
        )
    }

    function auto_grow(element) {
        element.style.height = "20px";
        element.style.height = (element.scrollHeight) + "px";
    }

    return ( user &&
            <div className='min-h-12 flex flex-col tablet:flex-wrap space-y-6 tablet:space-y-0'>
                <div className={"flex flex-1 min-h-12 tablet:h-full w-full flex-row"}>
                    <div className='h-12 w-12'>
                        <img className='object-cover w-full h-full' src={user.profilePicture ? user.profilePicture : '/assets/images/TTP-avatar.png'} alt="" />
                    </div>
                    <Textarea
                        className='!h-full py-[15px] tablet:w-full border-none bg-white tablet:bg-gray-200 flex-1'
                        inputClassName='bg-transparent h-[20px] placeholder:italic placeholder:text-black text-sm'
                        placeholder={t('project.comments.add_comment.placeholder')}
                        value={comment}
                        onChange={(e) => {setComment(e.target.value); auto_grow(e.target)} }
                    />
                </div>
                <Button className={"py-5 mx-auto w-full self-end tablet:w-fit whitespace-nowrap tablet:mx-0 justify-center"} $color='light-primary' disabled={!canSubmit} onClick={submitComment}>
                    {t('project.comments.add_comment.send_comment')}
                </Button>
            </div>
    )
}