import Viewer from "../../components/Viewer";
import SceneProvider from "../../providers/SceneProvider";
import SceneNextLevelContainer from "../../components/Scene/SceneNextLevelContainer";

export default function SceneNextLevelPage() {
    return (
        <div className='h-screen tablet:overflow-y-auto overflow-y-hidden w-full flex'>
            <SceneProvider isNextLevelChecking>
                <Viewer isChecking />
                <SceneNextLevelContainer />
            </SceneProvider>
        </div>
    )
}