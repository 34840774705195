import tw from "tailwind-styled-components";

export const Tag = tw.div<{className?: string}>`
    inline-block
    bg-black
    text-white
    font-bold
    text-xs
    ${({className}) => !(['p-', 'py-'].some(_ => className?.includes(_))) && 'py-2'}
    ${({className}) => !(['p-', 'px-'].some(_ => className?.includes(_))) && 'px-5'}
`