import Popup from "../Popup";
import {useTranslation} from "react-i18next";

const SceneErrorPopup = ({ onClose }: {onClose: () => void}) => {
    const { t } = useTranslation();

    return (
        <Popup handleClose={onClose}>
            <p className={'pt-3 pb-2'}>
                { t('general.default_error_try_reload') }
            </p>
        </Popup>
    )
}

export default SceneErrorPopup;
