import {Button} from "../styled-components/Button";
import React, {useEffect} from "react";
import {useScene} from "../providers/SceneProvider";
import SanitizeHTML from "./SanitizeHTML";
import i18n, {t} from "i18next";

const Stop = ({ className, handleClose }: { className?: string; handleClose?: () => void }) => {

    const close = () => {
        handleClose()
    }

    return (
        <div className={`${className} fixed top-0 left-0 w-screen my-0 mx-0 h-screen flex overflow-hidden items-center justify-center justify-items-center visible`}>
            <div className="border-[1px] border-[#DDD] px-[30px] py-[30px] max-w-[700px] mx-[80px] my-[80px] bg-white opacity-100 z-[999999]">
                    <p className={'pb-4 font-normal'}>
                        <h1>Bedankt voor het meedoen met deze oefening!</h1>
                        <p>Vul het feedbackformulier in om je bevindingen door te geven aan ons! Het platform is nog volop in ontwikkeling en daar nemen we jouw feedback in mee. Wij zijn je zeer dankbaar voor je hulp!</p>
                    </p>
                    <Button $color={'light-primary'} className="px-4 w-fit mx-auto"
                            onClick={close}
                    >{t('general.actions.finish')}</Button>
            </div>
            <div className="absolute w-full h-full bg-black opacity-50 z-[999]"></div>
        </div>
    )
}

export default Stop