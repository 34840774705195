import React, {ReactNode, useContext, useEffect, useState} from "react";
import Api from "../Api";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

export interface LanguageContextType {
    languages: string[]
}

export const LanguageContext = React.createContext<LanguageContextType>(null!);

export const useLanguages = () => useContext(LanguageContext)

export default function LanguageProvider({ children }: { children: ReactNode }) {
    const { i18n } = useTranslation();
    const [languages, setLanguages] = useState<string[]>([])

    useEffect(() => {
        Api.get('/websiteContent/languages',
            ({ data }) => {
                setLanguages(data)
            })
    }, [])

    useEffect(() => {
        const lang = localStorage.getItem('language');
        i18n.changeLanguage(lang);
    }, [i18n])

    useEffect(() => {
        localStorage.setItem('language', i18n.language);
    }, [i18n.language])

    const value = { languages }

    return <LanguageContext.Provider value={ value }>{ children }</LanguageContext.Provider>
}