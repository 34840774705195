import {withTranslation} from "react-i18next";

function ErrorPage({status}: {status: number}) {

    if (status === 404) {
        return (
            <div>404: not found</div>
        )
    } else {
        return (
            <div>500: Server error</div>
        )
    }

}

export default withTranslation()(ErrorPage)