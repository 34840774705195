import {useTranslation, withTranslation} from "react-i18next";
import NavBar from "../components/NavBar";
import {H1, H3, H5} from "../styled-components/Headings";
import Input from "../components/Input";
import {Button} from "../styled-components/Button";
import {useEffect, useRef, useState} from "react";
import Api from "../Api";
import {useAuth} from "../providers/AuthProvider";
import Footer from "../components/Footer";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {TabletWidth} from "../constants/ScreenWidth";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Icon from "../components/Icon";
import {faTrash} from "@fortawesome/free-solid-svg-icons";


function ProfileSettingsPage() {
    const { t } = useTranslation();
    const auth = useAuth()
    const submitButton = useRef(null);
    const urlPromoRef = useRef(null);
    const [image, setImage] = useState()
    const [imagePreview, setImagePreview] = useState(null);
    const [removedImage, setRemovedImage] = useState(false);
    const {width} = useWindowDimensions()

    const profileSchema = yup.object({
        firstname: yup.string().required(t('profile.settings.form.firstname_required_error')),
        middleName: yup.string(),
        surname: yup.string().required(t('profile.settings.form.lastname_required_error'))
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(profileSchema)
    });

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [])

    useEffect(() => {
        Api.get('/users/me',
            ({data}) => {
                auth.setUser({...auth.user, ...data})
            },
            null,
            null,
            () => {

            })
    }, [])


    const onSubmit = ({firstname, surname, middleName} : {firstname: string, surname: string, middleName: string}) => {
        const formData = new FormData()
        formData.append('firstname', firstname)
        formData.append('middleName', middleName)
        formData.append('surname', surname)
        if(typeof (image) !== 'undefined'){
            formData.append('image', image)
        }

        if (removedImage) {
            formData.append('image', null)
        }

        Api.post('/users/me',
            formData,
            ({data}) => {
                auth.setUser({...auth.user, ...data})
            },
            null,
            null,
            () => {

            }
        )
    }

    const getError = (key: string): null | string => {
        if (errors[key]) {
            return errors[key].message as unknown as string
        }

        return null
    }

    const deleteUser = () => {
        Api.delete(`/users/me`,
            () => {
                auth.logout()
            },
            null,
            null,
            () => {

            })
    }

    const removeImage = () => {
        setRemovedImage(true);
        setImage(null);
        setImagePreview(null);
    };

    const uploadImage = (event) => {
        setImage(event.target.files[0])
        setRemovedImage(false);
        var selectedFile = event.target.files[0];
        var reader = new FileReader();

        var imgtag = document.getElementById("profilePicture") as HTMLInputElement;
        imgtag.title = selectedFile.name;

        reader.onload = function(event) {
            imgtag.src = event.target.result as string;
            setImagePreview(event.target.result)
        };

        reader.readAsDataURL(selectedFile);
    }

    const getProfilePicture = (): string => {
        if (removedImage) {
            return '/assets/images/TTP-avatar.png';
        }

        if (imagePreview) {
            return imagePreview;
        }

        if (auth.user.profilePicture) {
            return auth.user.profilePicture;
        }

        return '/assets/images/TTP-avatar.png';
    }

    const AccountSettings = () => {
        return (
            <div>
                <form className={"flex flex-col space-y-8"} onSubmit={handleSubmit(onSubmit)} >
                    <H3 className={""}>{ t('profile.settings.title') }</H3>
                    <div className={"flex flex-col lg:flex-row space-x-0 space-y-8 lg:space-y-0 lg:space-x-24"}>
                        <div className='bg-gray-500 aspect-profile min-w-[180px] max-w-[180px] relative'>
                            { auth.user.profilePicture && removedImage === false &&
                                <Button type="button" onClick={removeImage} className={"absolute p-0 w-[30px] !h-[30px] items-center justify-center"} $color={"light-primary"}>
                                    <Icon icon={faTrash} />
                                </Button>
                            }

                            <img id="profilePicture" className='object-cover w-full h-full' src={getProfilePicture()} alt="" />
                            <label htmlFor="file-input" className={"absolute bottom-3 right-3 hover:cursor-pointer"}>
                                <img src="/assets/images/profilepicture-change.svg" alt="Change profile picture"/>
                            </label>
                            <input ref={urlPromoRef} className={"hidden"} id="file-input" type="file" onChange={uploadImage}/>
                        </div>
                        <div className={"flex flex-col tablet:flex-1 w-full tablet:w-auto space-y-3"}>
                            <H5>Algemeen</H5>
                            <div className={"flex flex-col tablet:space-y-5 tablet:max-w-[580px]"} >
                                <div className={"flex flex-col tablet:flex-row tablet:space-x-3"}>
                                    <div className={'flex-1 mb-[10px] tablet:mb-0'}>
                                        <label className='font-mabry font-semibold tablet-landscape:font-normal text-[14px] mb-1'>{ t('profile.settings.form.firstname_label') }</label>
                                        <Input type='text' defaultValue={auth.user.firstname} register={register('firstname')} error={getError('firstname')}/>
                                    </div>
                                    <div className={'flex-none mb-[10px] tablet:mb-0 tablet:max-w-[100px]'}>
                                        <label className='font-mabry font-semibold tablet-landscape:font-normal text-[14px] mb-1'>{ t('profile.settings.form.middlename_label') }</label>
                                        <Input type="text" defaultValue={auth.user.middleName} register={register('middleName')} error={getError('middleName')}/>
                                    </div>
                                    <div className={'flex-1 mb-[10px] tablet:mb-0'}>
                                        <label className='font-mabry font-semibold tablet-landscape:font-normal text-[14px] mb-1'>{ t('profile.settings.form.lastname_label') }</label>
                                        <Input type="text" defaultValue={auth.user.surname} register={register('surname')} error={getError('surname')}/>
                                    </div>
                                </div>
                                <div className={'flex-1'}>
                                    <label className='font-mabry font-semibold tablet-landscape:font-normal text-[14px] mb-1'>{ t('profile.settings.form.email_label') }</label>
                                    <Input disabled={true} className={"text-gray-500"} type="email" defaultValue={auth.user.email}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Button type='submit' $color='light-primary' className='block w-full flex tablet:hidden justify-center space-y-3 mt-8 px-20' ref={submitButton}>{ t('profile.settings.save_changes') }</Button>

                    <hr className={"border-t-2 invisible tablet:visible border-[#d7d7d7] col-span-full"}/>

                    {/*Delete*/}
                    <div className={"flex-1 flex-col lg:pl-[calc(180px+6rem)] space-y-4"}>
                        <H5>{ t('profile.settings.delete_account.title') }</H5>
                        <div className={"flex-1 flex flex-col space-y-4 tablet:flex-row flex-wrap tablet:items-center tablet:max-w-[580px]"}>
                            <p className={'mr-3 mb-4 tablet:mb-0'}>{ t('profile.settings.delete_account.label') }</p>
                            <Button onClick={deleteUser} $color='light-white' className={"justify-center"}>{ t('profile.settings.delete_account.delete_button') }</Button>
                        </div>
                    </div>

                    <hr className={"border-t-2 invisible tablet:visible border-[#d7d7d7] col-span-full"}/>

                    <div className='hidden tablet:flex justify-end space-y-3 mt-8'>
                        <Button type='submit' $color='light-primary' className='px-20' ref={submitButton}>{ t('profile.settings.save_changes') }</Button>
                    </div>
                </form>
            </div>
        )
    }

    return (
        <div className='min-h-screen'>
            <NavBar />
            <div className='bg-primary-light'>
                <div className='container mx-auto h-56 flex'>
                    <div className='py-8'>
                        <H1 className="p-3 bg-white uppercase break-words text-primary-light">{ t('navbar_and_footer.profile_dropdown.settings') }</H1>
                    </div>
                </div>
            </div>
            { width > TabletWidth &&
                <div className='bg-gray-100'>
                    <div className={"container mx-auto"}>
                        <div className={"pt-6"}>
                            <Navvie/>
                        </div>
                    </div>
                </div>
            }


            {/*Block*/}
            <div className={"container mx-auto mt-10 mb-10"}>
                <AccountSettings/>
            </div>

            <Footer />
        </div>
    )
}

const Navvie = () => {
    const { t } = useTranslation();
    return (
        <div className={"flex items-start justify-between mr-2"}>
            <div className="h-12 flex items-center">
                <div
                    className={"relative text-black text-[14px] h-full flex items-center justify-center w-[200px] border-b-4 border-b-[#D7D7D7] font-bold"}
                    >
                    { t('profile.settings.title') }
                </div>
                {/*<div*/}
                {/*    className={"relative text-black text-[14px] h-full flex items-center justify-center w-[200px] border-b-4 border-b-[#D7D7D7] font-bold"}*/}
                {/*>*/}
                {/*    Notificaties*/}
                {/*</div>*/}
                {/*<div*/}
                {/*    className={"relative text-black text-[14px] h-full flex items-center justify-center w-[200px] border-b-4 border-b-[#D7D7D7] font-bold"}*/}
                {/*>*/}
                {/*    Wachtwoord*/}
                {/*</div>*/}
                {/*<div*/}
                {/*    className={"relative text-black text-[14px] h-full flex items-center justify-center w-[200px] border-b-4 border-b-[#D7D7D7] font-bold"}*/}
                {/*>*/}
                {/*    Privacy*/}
                {/*</div>*/}
            </div>
        </div>
        )
}


export default withTranslation()(ProfileSettingsPage)

