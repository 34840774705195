import {WithTranslation, withTranslation} from "react-i18next";
import {Button} from "../styled-components/Button";
import Icon from "./Icon";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "./Dropdown";
import {useEffect, useState} from "react";
import {useLanguages} from "../providers/LanguageProvider";
import {buttonClasses} from "@mui/material";

interface LanguageSwitcherProps extends WithTranslation {
    $small?: boolean
    type?: 'light' | 'dark',
    buttonClassName?: string
}

function LanguageSwitcher({ $small, type, i18n, buttonClassName }: LanguageSwitcherProps) {
    const languages = useLanguages();
    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang)
        localStorage.setItem("language", lang);}

    const lgns = (): string[] => {
        return languages.languages ?? []
    }

    return (
        <Dropdown >
            <Button $text={type === 'light' ? 'black' : 'white'} $small={$small} className={buttonClassName}>
                <span className='uppercase'>{i18n.language}</span>
                <Icon className='transform -translate-y-0.5 ml-1' icon={faChevronDown} />
            </Button>
            <ul className={type === 'light' ? 'bg-white' : 'bg-black'}>
                {lgns().map((language, key) => {
                    return <li key={key} className={`py-4 w-full text-center font-bold ${type === 'light' ? 'text-black' : 'text-white'} cursor-pointer uppercase`} onClick={() => changeLanguage(language)}>{language}</li>
                })}
            </ul>
        </Dropdown>
    )
}

export default withTranslation()(LanguageSwitcher)