import Icon from "./Icon";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {LaptopWidth, TabletWidth} from "../constants/ScreenWidth";

interface CollapseProps {
    children: JSX.Element[],
    className?: string,
    navClassName?: string,
    icon: IconProp,
    color?: string
}

export default function Collapse({children, className, navClassName, icon, color}: CollapseProps) {
    const [open, setOpen] = useState(false)
    const toggleOpen = () => setOpen(!open)
    const {width} = useWindowDimensions();

    return (
        <>
            <div className={`${open && (width > TabletWidth) ? 'pb-4' : ''} ${className}`}>
                <div className={`${navClassName} select-none flex cursor-pointer items-center py-3`} onClick={toggleOpen}>
                    <div className='flex-1'>{children[0]}</div>
                    <Icon className={`${color ? color : 'text-primary-light'}  ${open ? 'rotate-180' : 'rotate-0'}`} icon={icon} />
                </div>
                { width > LaptopWidth && <div className={`${!open ? 'hidden' : ''}`}>{children.slice(1)}</div>}
            </div>
            { width <= LaptopWidth && <div className={`${!open ? 'hidden' : ''} container mx-auto py-5`}>{children.slice(1)}</div>}
        </>
    )
}