import {WithTranslation, withTranslation} from "react-i18next";
import NavBar from "../components/NavBar";
import {H1, H2, H5, SUBTITLE} from "../styled-components/Headings";
import {Button} from "../styled-components/Button";
import React, {useEffect, useState} from "react";
import Footer from "../components/Footer";
import {Link, useNavigate} from "react-router-dom";
import Api from "../Api";
import ParticipationBlock from "../components/ParticipationBlock";
import useWindowDimensions from "../hooks/useWindowDimensions";
import YoutubeContainer from "../components/YoutubeContainer";
import {i18nStringFromObject} from "../plugins/i18n";

function HomePage({t, i18n}: WithTranslation) {
    const [homeData, setHomeData] = useState(null);
    const [projects, setProjects] = useState([])
    const navigate = useNavigate();
    const {width} = useWindowDimensions()
    const storedValues = localStorage.getItem('checkboxValues');


    useEffect(() => {
        Api.get('/projectsSummary',
            ({data}) => {
                setProjects(data.items)
            })
        Api.get('/home',
            ({data}) => {
                setHomeData(data)
            })
    }, [])

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [])

    const navigateToUrlWithParams = (path: string) => {
        const params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
        navigate(`${path}${params.return ? `?return=${params.return}` : ''}`)
    }

    return (
        <div className={"w-full"}>
            <NavBar />
            <div className='bg-gray-200'>
                <div className={`${width < 500 ? 'h-[100vw]' : 'h-[500px]'} tablet-landscape:h-[710px]  w-full bg-gray-500`} style={{
                    backgroundImage: `url(./assets/images/TTP-header-website.jpeg)`,
                    backgroundRepeat: `no-repeat`,
                    backgroundSize: `cover`
                }}>
                    <div className='container mx-auto h-full relative'>
                        <H1 className='uppercase bg-white w-fit p-2 absolute top-[90px] text-black'>{t('home.header_title')}</H1>
                    </div>
                </div>
            </div>


            {/*Samen brengen we het verleden in beeld section*/}
            <div className={"container mx-auto"}>
                <div className={'bg-white min-h-[323px] relative bottom-[54px] flex flex-col tablet-landscape:flex-row'}>
                    <div className={'w-full tablet-landscape:w-8/12 py-[50px] px-[20px] tablet-landscape:mx-[100px] flex flex-col'}>
                        <H2>{ t('home.intro.title') }</H2>
                        <p className={'font-bl-pylon mt-2 mb-[40px] tablet-landscape:mb-[60px] text-base text-bold text-black-text'} dangerouslySetInnerHTML={{ __html: t('home.intro.text')}}></p>
                        <div className={'flex flex-col w-full tablet-landscape:w-auto mx-auto tablet-landscape:mx-0 tablet-landscape:flex-row space-y-4 tablet-landscape:space-y-0 space-x-0 tablet-landscape:space-x-4 mt-[30px]'}>
                            <Link to='/register'>
                                <Button className={"w-full tablet-landscape:min-w-[200px] justify-center text-center"} $color='light-primary'>
                                    { t('home.intro.buttons.button_1') }
                                </Button>
                            </Link>
                            <Link to={`/project/demo`}>
                                <Button className="w-full tablet-landscape:min-w-[200px] justify-center text-center" $color='light-white'>
                                    { t('home.intro.buttons.button_2') }
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div className={'w-full tablet-landscape:w-4/12 bg-primary-light flex flex-col justify-center space-y-5 py-[20px] tablet-landscape:py-0'}>
                        <Statistic number={homeData?.statistics.totalPhotosFinished}/>
                        <p className={"text-white font-medium tablet-landscape:font-semibold font-mabry text-base text-center mx-[40px] !leading-6"}>{t('home.intro.statistics.total_pictures')}</p>
                        <Statistic number={homeData?.statistics.totalActiveParticipants} />
                        <p className={"text-white font-medium tablet-landscape:font-semibold font-mabry text-base text-center mx-[40px] !leading-6"}>{t('home.intro.statistics.total_volunteers')}</p>
                    </div>
                </div>
            </div>

            {/*Hoe werkt dit sectie*/}
            <div className={"container mx-auto mb-[100px] mt-[50px] flex flex-col tablet-landscape:grid tablet-landscape:grid-cols-12 tablet-landscape:gap-5"}>
                <div className={"shadow-primary-left tablet-landscape:shadow-none tablet-landscape:col-start-1 tablet-landscape:col-end-7 relative left-[5%] w-[95%] h-[45%] tablet-landscape:left-0 tablet-landscape:h-full tablet-landscape:w-full relative pb-[56.25%] pt-[25px]"}>
                    <YoutubeContainer src={ i18n.language === 'nl' ? "https://www.youtube.com/embed/TYptiAnZQ7o" :  "https://www.youtube.com/embed/0LLPgkIm2Bk"} />
                </div>
                <div className={'col-start-8 col-end-13 flex flex-col my-auto px-[20px] tablet-landscape:px-0'}>
                    <SUBTITLE className={'mt-[50px] tablet-landscape:mt-0 mb-[10px] w-full'}>
                        { t('home.block_1.subtitle') }
                    </SUBTITLE>
                    <H2 className={'mb-[35px] text-[28px]'}>
                        { t('home.block_1.title') }
                    </H2>
                    <p className={'font-bl-pylon text-base text-black-text mb-[40px] tablet-landscape:mb-[60px]'} dangerouslySetInnerHTML={{ __html: t('home.block_1.text')}}></p>
                    <Button onClick={() => navigateToUrlWithParams('/how-this-works')} $color={'light-white'}
                            className={'text-center mx-auto justify-center w-full tablet-landscape:min-w-[200px]'}
                    >
                        {t('home.block_1.button')}
                    </Button>
                </div>
            </div>

            {/*Onze projecten section*/}
            <div className={'mb-[70px] tablet-landscape:min-h-[671px] flex tablet-landscape:items-center justify-center tablet-landscape:bg-primary-to-white-lr'}>
                <div className={"container mx-[15px] tablet-landscape:mx-auto"}>
                    <H2 className={"text-black tablet-landscape:text-white mb-[50px]"}>{t('home.block_2.title')}</H2>
                    <div className={"w-full h-full tablet-landscape:h-[470px] flex flex-col tablet-landscape:flex-row mb-[25px] tablet-landscape:bg-gray-200"}>
                        <div>
                            <img className={'z-10 relative tablet-landscape:left-0 h-[90%] w-[105%] tablet-landscape:h-full tablet-landscape:w-full object-cover'} src="./assets/images/TTP-Indonesie-collectie.jpeg" alt="banner"/>
                            <div className={"z-1 relative right-[3%] bottom-[47%] h-[50%] w-[95%] tablet-landscape:h-0 tablet-landscape:w-0 bg-primary-light"}></div>
                        </div>
                        <div className={'flex flex-col space-y-[20px] mt-[25px] tablet-landscape:my-auto tablet-landscape:ml-[70px]'}>
                            <h1 className={"font-mabry text-black font-bold text-[28px]"}>{t('home.block_2.project_1.title')}</h1>
                            <p className={'font-bl-pylon text-base text-black-text mb-2 tablet-landscape:max-w-[650px]'} dangerouslySetInnerHTML={{__html: t('home.block_2.project_1.text')}} />
                            {(projects && projects.length > 0) &&
                                <Link to={`/project/${projects[0].id}`} className='tablet-landscape:self-start tablet-landscape:mx-0'>
                                    <Button $color={'light-white'}
                                            className={'text-center justify-center w-full tablet-landscape:w-fit min-w-[200px] !border-black'}>
                                        <p className='font-mabry whitespace-nowrap font-bold text-base'>
                                            { t('general.actions.start') }
                                        </p>
                                    </Button>
                                </Link>
                            }
                        </div>
                    </div>
                    <div className={'flex'}>
                        {/*TODO: Coming soon (perhaps)*/}
                        {/*<button className={"font-montserrat text-base text-white"}>{t('pages.home.projects.more')} <Icon*/}
                        {/*    className="!text-white align-base" icon={faArrowRight}/></button>*/}
                    </div>
                </div>
            </div>

            <ParticipationBlock
                message={t('home.block_3.title')}
                button1={{
                    text:t('home.block_3.buttons.button_1')
                }}
                button2={{
                    text:t('home.block_3.buttons.button_2')
                }} />
            <Footer/>
        </div>

    )
}

const Statistic = ({ number }: { number: number }) => {
    if (number == null) {
        return (
            <div className={'mx-auto h-[41px] flex space-x-1'}>
                <div className={'w-[28px] h-[41px] bg-[#3669D8] grid place-items-center animate-pulse'} />
                <div className={'w-[28px] h-[41px] bg-[#3669D8] grid place-items-center animate-pulse'} />
                <div className={'w-[28px] h-[41px] bg-[#3669D8] grid place-items-center animate-pulse'} />
            </div>
        )
    }

    const stringNumber = (number ?? 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")

    return (
        <div className={'mx-auto h-[41px] flex space-x-1'}>
            {
                stringNumber.split('').map((c, i) => {
                    if (c === '.') {
                        return <div className={'bg-white h-[5px] w-[5px] relative top-[36px] rounded-full'} />
                    }

                    return <div key={i} className={'w-[28px] h-[41px] bg-[#3669D8] grid place-items-center'}>
                        <p className={"font-mabry font-bold text-white text-[30px]"}>{c}</p>
                    </div>
                })
            }
        </div>
    )
}

export default withTranslation()(HomePage)
