import tw from "tailwind-styled-components";

export const H1 = tw.h1`
    font-bold
    text-[40px]
    tablet-landscape:text-5xl
    font-mabry
`

export const H2 = tw.h1`
    font-bold
    text-[28px]
    tablet-landscape:text-3xl
    font-mabry
    text-black
`

export const H3 = tw.h3`
    font-mabry
    font-bold
    text-xl
    text-black
    leading-6
    tablet-landscape:text-[25px]
    tablet-landscape:leading-[30px]
`

export const H4 = tw.h4`
    font-bold
    text-lg
    tablet-landscape:text-xl
    font-mabry
    leading-6
    text-black
`

export const H5 = tw.h5`
    font-bold
    text-lg
    leading-5
    font-mabry
    text-black
`
export const SUBTITLE = tw.h6`
    font-bold
    text-xl
    text-primary-light
    font-mabry
`
