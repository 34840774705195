import {WithTranslation, withTranslation} from "react-i18next";
import NavBar from "../components/NavBar";
import {H2} from "../styled-components/Headings";
import {Button} from "../styled-components/Button";
import React, {useEffect} from "react";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import {Header} from "../components/Header";
import ParticipationBlock from "../components/ParticipationBlock";

function AboutPage({t}: WithTranslation) {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [])

    return (
        <div className='min-h-screen bg-gray-200'>
            <NavBar/>
            <Header title={ t('about.header_title') }/>

            {/*Contentblock sectie*/}
            <div className={"container mx-auto tablet-landscape:min-h-[361px] mb-[67px] flex flex-col tablet-landscape:grid tablet-landscape:grid-cols-12 tablet-landscape:gap-5"}>
                <div className={'tablet-landscape:col-start-1 tablet-landscape:col-end-7 mb-[40px] tablet-landscape:mb-0 flex flex-col my-auto'}>
                    <H2 className={'mb-[25px]'}>{ t('about.block_1.title') }</H2>
                    <p className={'font-bl-pylon text-base text-black-text mb-5'} dangerouslySetInnerHTML={{ __html: t('about.block_1.text')}}></p>
                    <Link className={'w-full mb-5'} to={'/register'}>
                        <Button $color={'light-primary'} className={'text-center justify-center w-full tablet-landscape:w-fit min-w-[200px]'}>
                            { t('about.block_1.button') }
                        </Button>
                    </Link>
                </div>
                <div className={"col-start-7 col-end-13 h-full tablet-landscape:bg-black"}>
                    <img className={"shadow-primary-left w-[calc(100%-20px)] relative left-[20px] tablet-landscape:shadow-none tablet-landscape:w-full tablet-landscape:left-0 h-full object-cover"} src={"./assets/images/TTP-campagnebeeld-origineel.png"}/>
                </div>
            </div>

            <div className={"container mx-auto min-h-[361px] mb-[87px] flex flex-col-reverse tablet-landscape:grid tablet-landscape:grid-cols-12 tablet-landscape:gap-5"}>
                <div className={"col-start-1 col-end-7 tablet-landscape:h-full"}>
                    <img className={"h-full w-full object-cover"} src={"./assets/images/TTP_open-source.jpg"}/>
                </div>
                <div className={'tablet-landscape:col-start-7 tablet-landscape:col-end-13 flex flex-col my-auto'}>
                    <H2 className={'mb-[25px]'}>{ t('about.block_2.title') }</H2>
                    <p className={'font-bl-pylon text-base text-black-text mb-5'} dangerouslySetInnerHTML={{ __html: t('about.block_2.text')}}></p>
                </div>
            </div>

            <div className={"container mx-auto min-h-[361px] mb-[87px] flex flex-col tablet-landscape:grid tablet-landscape:grid-cols-12 tablet-landscape:gap-5"}>
                <div className={'col-start-1 col-end-7 flex flex-col my-auto'}>
                    <H2 className={'mb-[25px]'}>{ t('about.block_3.title') }</H2>
                    <p className={'font-bl-pylon text-base text-black-text mb-[60px] tablet-landscape:mb-0'} dangerouslySetInnerHTML={{__html: t('about.block_3.text')}}></p>
                </div>
                <div className={"col-start-7 col-end-13 h-full"}>
                    <img className={"shadow-primary-right w-[calc(100%-20px)] relative tablet-landscape:shadow-none tablet-landscape:w-full tablet-landscape:left-0 h-full object-cover"} src={"./assets/images/TTP_over-tropenmuseum.jpg"}/>
                </div>
            </div>

            <div className={"container mx-auto min-h-[361px] mb-[67px] flex flex-col-reverse tablet-landscape:grid tablet-landscape:grid-cols-12 tablet-landscape:gap-5"}>
                <div className={"col-start-1 col-end-7 h-full bg-black"}>
                    <img className={"w-full h-full object-cover"} src={"./assets/images/TTP_over-partners.jpg"}/>
                </div>
                <div className={'col-start-7 col-end-13 flex flex-col my-auto'}>
                    {/*<SUBTITLE className={'mb-[10px] w-full'}>Subtitle</SUBTITLE>*/}
                    <H2 className={'mb-[25px]'}>{ t('about.block_4.title') }</H2>
                    <p className={'font-bl-pylon text-base text-black-text mb-[40px]'} dangerouslySetInnerHTML={{__html: t('about.block_4.text')}}></p>
                </div>
            </div>

            <div className={"container mx-auto min-h-[361px] flex flex-col tablet-landscape:grid tablet-landscape:grid-cols-12 tablet-landscape:gap-5"}>
                <div className={'col-start-1 col-end-7 flex flex-col my-auto'}>
                    <H2 className={'mb-[25px]'}>{ t('about.block_5.title') }</H2>
                    <p className={'font-bl-pylon text-base text-black-text mb-[40px] tablet-landscape:mb-0'} dangerouslySetInnerHTML={{__html: t('about.block_5.text')}}></p>
                </div>
                <div className={"col-start-7 col-end-13 h-full"}>
                    <img className={"w-full h-full object-cover"} src={"./assets/images/TTP_voor-pers.jpg"}/>
                </div>
            </div>

            <ParticipationBlock
                message={t('about.block_6.title')}
                button1={{
                    text:t('about.block_6.buttons.button_1')
                }}
                button2={{
                    text:t('about.block_6.buttons.button_2')
                }} />
            <Footer/>
        </div>


    )
}

export default withTranslation()(AboutPage)
