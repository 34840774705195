import Icon from "./Icon";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {withTranslation, WithTranslation} from "react-i18next";
import {CheckObject} from "../types/entities";
import React, {useEffect, useState} from "react";
import {FormControlLabel, Radio, RadioGroup, Tooltip} from "@mui/material";
import {useAnnotation} from "../providers/AnnotationProvider";
import {RadioButton} from "./RadioButton";

interface AnnotationProps extends WithTranslation {
    object: CheckObject
}

function CheckAnnotation({t, object}: AnnotationProps) {
    const annotationProvider = useAnnotation();
    const [isHidden, setIsHidden] = useState(false)

    useEffect(() => {
        getColour()
    }, [object])

    useEffect(() => {
        if (object.base && object.base.id) {
            const element = document.querySelector(".a9s-annotation[data-id=" + CSS.escape(object.base.id) + "]") as HTMLElement;

            if (element) {
                if (isHidden) {
                    element.style.setProperty('display', 'none')
                    element.style.pointerEvents = 'none'
                } else {
                    element.style.setProperty('display', 'block')
                }
            }
        }
    }, [isHidden])

    const setCheckValue = (event: React.MouseEvent<HTMLInputElement>) => {
        console.log(event.target);
        const newLabelsArr = Object.assign([], annotationProvider.checkLabels)

        newLabelsArr.forEach(label => {
            label.objects.forEach(o => {
                if (o.id === object.id) {
                    console.log((event.target as HTMLInputElement).value);
                    console.log((event.target as HTMLInputElement).value === 'Goed');
                    o.checkValue = (event.target as HTMLInputElement).value === 'Goed'
                }
            })
        })

        annotationProvider.setCheckLabels(newLabelsArr);
    }

    const getColour = (): string => {
        if (object.colour == null) {
            return '';
        }

        return `bg-${object.colour.toLowerCase()}-300`
    }

    return (
        <div className={`h-13 flex items-center rounded border-l-[6px] border-gray-500 px-4 cursor-pointer bg-gray-100`}>
            <div className='flex items-center space-x-4 flex-1'>
               <div className={`w-[15px] h-[15px] rounded-full border-2 border-white ${getColour()}`}/>
                <h5 className={'text-sm font-bold flex-1'}>Kader</h5>
                <div className={'flex space-x-4'}>
                    <RadioButton
                        name={`rdfsfdsdfs`}
                        activeElement={<img src='/assets/images/RadioSuccess.svg' />}
                        value={'Goed'}
                        checked={object.checkValue === true}
                        onClick={setCheckValue}
                        label={<div>Goed</div>}
                    />
                    <RadioButton
                        name={`sdasdadsasad`}
                        activeElement={<img src='/assets/images/RadioDanger.svg' />}
                        value={'Afkeuren'}
                        checked={object.checkValue === false}
                        onClick={setCheckValue}
                        label={<div>Afkeuren</div>}
                    />
                </div>
                <Tooltip title={t('general.tooltips.show_hide_annotation')}  enterDelay={500}>
                    <span>
                        {isHidden ?
                            <Icon icon={faEyeSlash} onClick={() => setIsHidden(!isHidden)}/> :
                            <Icon icon={faEye} onClick={() =>  setIsHidden(!isHidden)}/>
                        }
                    </span>
                </Tooltip>
            </div>
        </div>
    )
}

export default withTranslation()(CheckAnnotation)