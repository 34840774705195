import Viewer from "../../components/Viewer";
import SceneProvider from "../../providers/SceneProvider";
import ObjectCheckContainer from "../../components/Scene/ObjectCheckContainer";

export default function ObjectCheckPage() {

    return (
        <div className='h-screen tablet:overflow-y-auto overflow-y-hidden w-full flex'>
            <SceneProvider isChecking>
                <Viewer isChecking />
                <ObjectCheckContainer />
            </SceneProvider>
        </div>
    )
}