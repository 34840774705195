import {useAnnotation} from "../../providers/AnnotationProvider";
import SceneHeader from "./SceneHeader";
import AnnotableLabel from "../Annotate/AnnotableLabel";
import SceneBottomBar from "./SceneBottomBar";
import React, {useEffect, useRef, useState} from "react";
import Api from "../../Api";
import {AxiosError} from "axios";
import {useScene} from "../../providers/SceneProvider";
import {useTranslation, withTranslation, WithTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import SanitizeHTML from "../SanitizeHTML";
import i18n from "i18next";
import {Button} from "../../styled-components/Button";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import SceneErrorPopup from "./SceneErrorPopup";
import {isMobile, mobileModel, mobileVendor} from "react-device-detect";
import {TabletWidth} from "../../constants/ScreenWidth";
import {a, config, useSpring} from "@react-spring/web";
import {useDrag} from "@use-gesture/react";
import Icon from "../Icon";
import {faAnglesUp, faAnglesDown} from "@fortawesome/free-solid-svg-icons";
import {PrefixButton} from "../Buttons";
import Annotation from "../Annotate/Annotation";
import {i18nStringFromObject} from "../../plugins/i18n";

function ObjectAnnotateContainer({t}: WithTranslation) {
    const annotationProvider = useAnnotation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [missingLabels, setMissingLabels] = useState([]);
    const scene = useScene();
    const colours = ['GREEN', 'YELLOW', 'ORANGE', 'BLUE', 'PINK', 'PURPLE', 'GREEN', 'YELLOW', 'ORANGE', 'BLUE', 'PINK', 'PURPLE',
        'GREEN', 'YELLOW', 'ORANGE', 'BLUE', 'PINK', 'PURPLE', 'GREEN', 'YELLOW', 'ORANGE', 'BLUE', 'PINK', 'PURPLE']
    const [showPostError, setShowPostError] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const height = 340;

    useEffect(() => {
        localStorage.setItem("notes", '');
        scene.setSkipOption(null)

        if (scene.batch && scene.batch.id && scene.image?.id) {
            fetchPhotoLabels()
        }
    }, [scene.batch, scene.image])

    // If there are missing labels, open the drawer
    useEffect(() => {
        if (missingLabels.length > 0) {
            open()
        }
    }, [missingLabels]);

    const fetchPhotoLabels = () => {
        Api.get(`${scene.project.isDemo ? '/demo' : ''}/batchphotos/${scene.image.id}/labels`,
            ({data}) => {
                const labels = data.items.map((l, i) => {
                    l.annotations = []
                    l.colour = colours[i]
                    return l
                })
                annotationProvider.setLabels(labels)
            },
            null,
            null,
            (error: AxiosError) => {
                console.log(error)
            })
    }

    const canContinue = (): boolean => {
        if (annotationProvider.labels) {
            return annotationProvider.labels.every(l => l.annotations !== null && l.annotations.length > 0)
        }
    }

    const submit = (skip = false) => {
        const values = {};

        if (skip) {
            values['skip'] = {
                annotations: [{
                    type: 'skip',
                    content: scene.skipOption
                }]
            }
        }

        if (scene.explicit) {
            values['explicit'] = {
                annotations: [{
                    type: 'explicit',
                }]
            }
        }

        if (scene.isChecked && scene.remark !== "") {
            values['remark'] = {
                annotations: [{
                    type: 'remark',
                    content: scene.remark
                }]
            }
        }

       if (Object.entries(values).length) {
           if (scene.project.isDemo) {
               scene.next(parseInt((searchParams.get('p') ?? '1')) + 1)
               return
           }
            Api.post('/attach-annotations-to-photo',
                {
                    photoId: scene.image.id,
                    labels: values,
                    device: mobileVendor + ' ' + mobileModel
                },
                () => {
                    if (skip) {
                        scene.next(null)
                    }
                },
                null,
                null,
                (error: AxiosError) => {
                    if (skip) {
                        setShowPostError(true);
                    }
                })
        }

        if (!skip) {
            setMissingLabels([])

            annotationProvider.labels?.forEach(l => {
                if (l.annotations == null || l.annotations.length === 0) {
                    setMissingLabels(missingLabels => [...missingLabels, l.id])
                }
            })

            if (canContinue()) {
                if (scene.project.isDemo) {
                    scene.next(parseInt((searchParams.get('p') ?? '1')) + 1)
                    setIsDisabled(true)
                    return
                } else {
                    for (const label of annotationProvider.labels) {
                        values[label.id] = {
                            annotations: label.annotations.map(a => {
                                return {
                                    content: a.content,
                                    type: a.type
                                }
                            }),
                        }
                    }

                    Api.post('/attach-annotations-to-photo',
                    {
                        photoId: scene.image.id,
                        labels: values
                    },
                    () => {
                        scene.next(null)
                    },
                    null,
                    null,
                    (error: AxiosError) => {
                        setShowPostError(true);
                    })
                    setIsDisabled(true)
                }
            }
        }
    }

    const TutorialAnnotate = () => {
        const {i18n} = useTranslation();
        const scene = useScene();

        return (
            <div className={"outro"}>
                <div className="h-full tablet:min-w-[400px] my-auto space-y-4 flex flex-col justify-center">
                    <div className="h-full my-auto space-y-4 flex flex-col justify-center">
                        <SanitizeHTML>
                               { i18nStringFromObject(scene.batch, 'batchIntroAnnotate')}
                        </SanitizeHTML>
                    </div>
                </div>
            </div>
        )
    }
    const SkipRemark = () => {
        const [skipOption, setSkipOption] = useState(null)

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSkipOption((event.target as HTMLInputElement).value)
        }

        const send = () => {
            if (skipOption !== null) {
                scene.setSkipOption(skipOption)
            }
        }

        useEffect(() => {
            if (scene.skipOption !== null) {
                submit(true)
            }
        }, [scene.skipOption])

        return (
            <div className={"outro w-full"}>
                <div className={`h-full tablet:min-w-[400px] w-full my-auto space-y-4 flex flex-col justify-center`}>
                    <div className="h-full my-auto space-y-4 flex flex-col justify-center">
                        <h1>{t("annotate.popups.skip.title")}</h1>
                        <p>{t("annotate.popups.skip.text")}</p>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                onChange={handleChange}
                                value={skipOption}
                            >
                                <FormControlLabel value="dont-know" control={<Radio size={"small"}/>} label={<p>{t("annotate.popups.skip.i_dont_know")}</p>} />
                                <FormControlLabel value="unclear-scan" control={<Radio size={"small"}/>} label={<p>{t("annotate.popups.skip.unclear_scan")}</p>}/>
                            </RadioGroup>
                        </FormControl>
                        <div className={"flex flex-col tablet:flex-row tablet:justify-center w-full gap-2 pt-3"}>
                            <Button disabled={!skipOption} onClick={() => send()}
                                    $color={'light-primary'}>{t("annotate.popups.skip.actions.skip")}</Button>
                            <Button onClick={scene.toggleSkipRemark} $color={'light-white'}>{t("general.actions.cancel")}</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const Remark = () => {
        const t = i18n.t;
        const input = useRef(null)

        const addRemark = () => {
            if (input && input.current) {
                scene.setRemark(input.current.value)
            }
            scene.setRemarkSubmitted(true)
        }

        return (
            <div className={"outro w-full"}>
                {scene.remarkSubmitted ? (
                    <div className={`h-full tablet:min-w-[400px] my-auto space-y-4 flex flex-col justify-center`}>
                        <div className="h-full my-auto space-y-4 flex flex-col justify-center">
                            <h1>{t("annotate.popups.remark.after_sent.title")}</h1>
                            <p>{t("annotate.popups.remark.after_sent.text")}</p>
                        </div>
                    </div>
                ) : (
                    <div className={`tablet:min-w-[400px] my-auto space-y-4 flex flex-col justify-center`}>
                        <h1>{t("annotate.popups.remark.title")}</h1>
                        <p dangerouslySetInnerHTML={{__html: t("annotate.popups.remark.text")}} />
                        <label className={`text-xs font-light space-x-1 flex-1 flex items-center`}>
                            <input id="explicit" type="checkbox"
                                   className="h-[16px] w-[16px] border-[1px] border-gray-warm rounded-none"
                                   onChange={() => scene.setExplicit(!scene.explicit)} checked={scene.explicit}
                            />
                            <span>{t("annotate.popups.remark.options.mark_as_explicit_content")}</span>
                        </label>
                        <label className={`text-xs font-light space-x-1 flex-1 flex items-center`}>
                            <input id="other-remark" type="checkbox"
                                   className="h-[16px] w-[16px] border-[1px] border-gray-warm rounded-none"
                                   onChange={() => scene.setIsChecked(!scene.isChecked)} checked={scene.isChecked}/>
                            <span>{t("annotate.popups.remark.options.add_remark")}</span>
                        </label>
                        <div className={`w-full ${scene.isChecked ? 'block' : 'hidden'}`}>
                            <h2 className={"font-mabry text-[14px] font-normal"}>{t("annotate.popups.remark.add_remark_explanation")}</h2>
                            <textarea id='remark'
                                      ref={input}
                                      className="w-full !mt-0 p-2 overflow-auto outline-0 resize-none text-[14px] font-mabry font-normal border-[1px] border-gray-500 min-h-[185px]"
                                      name="remark"/>
                            <h2 className={"font-mabry text-[14px] font-normal"}>{t("annotate.popups.remark.add_remark_extra_info")}</h2>
                        </div>
                        <div className={"flex relative tablet:flex-row flex-col tablet:justify-center w-full tablet:w-auto gap-2 pt-3"}>
                            <Button onClick={() => {
                                addRemark()
                            }}
                                    $color={'light-primary'}>{t("annotate.popups.remark.actions.add")}</Button>
                            <Button onClick={scene.toggleRemark}
                                    $color={'light-white'}>{t("general.actions.cancel")}</Button>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    /*Bottom drawer functions*/
    const [{ y }, api] = useSpring(() => ({ y: height}))

    function open() {
        scene.setOverlay(true)
        api.start({ y: 0, immediate: false, config: config.stiff })
    }
    function close({velocity = 0}){
        scene.setOverlay(false)
        api.start({ y: height, immediate: false, config: { ...config.stiff, velocity } })
    }

    const bind = useDrag(
        ({ last, velocity: [, vy], direction: [, dy], movement: [, my], cancel, canceled }) => {
            if (my < -70) cancel()
            if (last) {
                my > height * 0.5 || (vy > 0.5 && dy > 0) ? close({velocity: vy}) : open()
            } else api.start({ y: my, immediate: false })
        },
        { from: () => [0, y.get()], filterTaps: true, bounds: { top: 0 }, rubberband: true },
    )

    const display = y.to((py) => (py < height && 'block'))

    return (
        <>
            { (isMobile || window.innerWidth <= TabletWidth) ?
                <>
                    {(scene.overlay && !scene.isSubStep) && <div className={`fixed bottom-0 left-0 right-0 bg-black h-screen w-screen opacity-50 z-[78] transition-all duration-500 ease-in-out`}></div>}
                    <SceneHeader remark={<Remark/>} tutorialElement={<TutorialAnnotate/>} type='object-annotate' />
                    <a.div className={`${scene.isSubStep && 'invisible'} shadow-drawer flex flex-col h-[500px] fixed bottom-0 w-screen rounded-t-[20px] touch-none bg-white z-[79]`} style={{ display, y}}>

                        {/*Slider indicator*/}
                        <div {...bind()} onClick={() => open()} className={"h-5 rounded-t-[12px] flex justify-center"}>
                            <div className={"w-[100px] h-[1px] bg-[#333] opacity-[0.15] rounded mt-2"}/>
                        </div>

                        <div className={"w-fit text-center mx-auto border-[1px] border-[#eee]"}>
                            <PrefixButton onClick={() => scene.overlay ? close({velocity: 0}) : open() }  prefix={scene.overlay ? <Icon icon={faAnglesDown} /> : <Icon icon={faAnglesUp} />}>
                                {t("annotate.bottom_bar.subjects")}
                            </PrefixButton>
                        </div>

                        {/*Daadwerkelijke content */}
                        <div className={`real max-h-[340px] gap-[5px] flex flex-col`}>
                            <div className='overflow-y-scroll flex-1 min-h-min px-6 tablet:px-9 py-2 space-y-2.5' id='label-tray'>
                                { annotationProvider.labels?.map((label, key) => {
                                    return <AnnotableLabel key={key} label={label} isMissing={missingLabels.includes(label.id)}/>
                                })}
                            </div>
                        </div>
                    </a.div>

                    <div className={"bottom-0 absolute z-[80] flex items-center w-full"}>
                        { (annotationProvider.activeAnnotation && scene.isSubStep) &&
                            <div id={"selectedAnnotation"} className={"fixed z-[200] left-1/2 -translate-x-1/2 bottom-[115px] mx-auto w-[90vw]"}>
                                <Annotation annotation={annotationProvider.activeAnnotation} />
                            </div>
                        }
                        { scene.isSubStep ?
                            <div className={"p-4 bg-white w-[90vw] flex fixed left-1/2 -translate-x-1/2 bottom-[20px] justify-between"}>
                                <div className={"flex gap-2 items-center"}>
                                    { annotationProvider.labels?.filter(label => label.id === annotationProvider.currentLabel.id).map((label, key) => {
                                        return <AnnotableLabel key={key} label={label} isSubStep={scene.isSubStep}/>
                                    })}
                                </div>
                                <Button $color={'white'} onClick={() => scene.setIsSubStep(false)}>
                                    {t("annotate.bottom_bar.done")}
                                </Button>
                            </div>
                            :
                            <div className={"bottom-0 fixed z-[999999999] w-full"}>
                                <SceneBottomBar canContinue={!isDisabled} skipRemark={<SkipRemark />} onSubmit={() => submit()}/>
                            </div>
                            }
                    </div>
                </>
                :
                <>
                    <div className={`${scene.expanded ? 'w-[5%]' : 'bg-gray-200 w-5/12 h-full flex flex-col'}`}>
                        <SceneHeader remark={<Remark/>} tutorialElement={<TutorialAnnotate/>} type='object-annotate' />
                        <div className={scene.expanded ? 'hidden' : 'flex-1 flex overflow-y-auto'}>
                            <div className='flex-1 min-h-min px-9 py-2 space-y-2.5' id='label-tray'>
                                {annotationProvider.labels?.map((label, key) => {
                                    return <AnnotableLabel key={key} label={label} isMissing={missingLabels.includes(label.id)}/>
                                })}
                            </div>
                        </div>
                        <SceneBottomBar canContinue={!isDisabled} skipRemark={<SkipRemark />} onSubmit={() => submit()}/>
                    </div>
                    { showPostError && <SceneErrorPopup onClose={() => setShowPostError(false)} />}
                </>
            }
        </>
    )
}

export default withTranslation()(ObjectAnnotateContainer)