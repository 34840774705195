import Viewer from "../../components/Viewer";
import SceneProvider from "../../providers/SceneProvider";
import SceneValidateContainer from "../../components/Scene/SceneValidateContainer";

export default function SceneValidatePage() {

    return (
        <div className='h-screen tablet:overflow-y-auto overflow-y-hidden w-full flex'>
            <SceneProvider>
                <Viewer isChecking/>
                <SceneValidateContainer />
            </SceneProvider>
        </div>
    )
}