import {useTranslation, WithTranslation, withTranslation} from "react-i18next";
import NavBar from "../components/NavBar";
import {H1} from "../styled-components/Headings";
import * as yup from 'yup';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Input from "../components/Input";
import {Button} from "../styled-components/Button";
import Api from "../Api";
import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AxiosError} from "axios";


export const ForgotPasswordPage = withTranslation()(({t}: WithTranslation) => {
    const [searchParams] = useSearchParams();
    const [reset, setReset] = useState<{uuid: string, token: string}>(null)

    useEffect(() => {
        if (reset === null && searchParams.get('p') && searchParams.get('t')) {
            setReset({uuid: searchParams.get('p'), token: searchParams.get('t')});
        }
    },[reset, searchParams])

    return (
        <div className='min-h-screen bg-gray-300'>
            <NavBar />
            <div className='h-[270px] w-full bg-gray-500'>
                <div className='container mx-auto h-full relative'>
                    <H1 className='uppercase bg-white inline-block p-2 absolute bottom-16 text-black'>{t('forgot_password.header_title')}</H1>
                </div>
            </div>
            <div className='transform -translate-y-12'>
                <div className='container mx-auto space-y-4'>
                    <div className='min-h-[512px] flex w-full  bg-white p-12 tablet:p-24'>
                        { !reset && <PasswordResetRequestForm /> }
                        { reset && <ChangePasswordForm {...reset} /> }
                    </div>
                </div>
            </div>
        </div>
    )
})

const PasswordResetRequestForm = () => {
    const { t } = useTranslation();
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [newEmail, setEmail] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const forgotPasswordSchema = yup.object({
        email: yup.string().email().required(t('forgot_password.form.email_required_error'))
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(forgotPasswordSchema)
    })

    const onSubmit = (email: {email: string}) => {
        setSubmitting(true);
        Api.post('/resetPassword',
            {
                email: email
            },
            () => {
                setSuccess(true)
            },
            null,
            null,
            (error: AxiosError) => {
                setError(true)
            })

    }

    const handleEmail = event => {
        setEmail(event.target.value)
    }

    const getError = (key: string): null | string => {
        if (errors[key]) {
            return errors[key].message as unknown as string
        }

        return null
    }

    return (
        <>
            { !success && <form onSubmit={handleSubmit(onSubmit)} className='w-full' noValidate>
                <div className='mb-3 flex flex-col'>
                    <label className='font-light mb-1'>{ t('forgot_password.form.email_label') }</label>
                    <Input type='email' name={'email'} value={newEmail} defaultValue={newEmail && newEmail} onInput={handleEmail} onChange={handleEmail} register={ newEmail && register('email', {value: newEmail})} error={getError('email')}/>
                </div>

                <Button type='submit' disabled={submitting} $color='light-primary' className='px-10'>{t('forgot_password.form.submit_password_change_request')}</Button>
            </form> }
            { success && <div>
                <p>{ t('forgot_password.form.after_submit_password_change_request_message') }</p>
            </div> }
            { error && <div>
                <p>{ t('forgot_password.form.after_submit_password_change_request_error') }</p>
            </div> }
        </>
    )
}

const ChangePasswordForm = ({ uuid, token }: { uuid: string, token: string }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false)
    const changePasswordSchema = yup.object({
        password: yup.string().required(t('forgot_password.form.password_required_error')).min(8, t('forgot_password_form.password_minimum_length_error'))
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(changePasswordSchema)
    })

    const onSubmit = (values) => {
        Api.post('/changePassword', {...values, id: uuid, token: token},
            () => {
                setSuccess(true)
            },
            null,
            null,
            () => {

            })
    }

    return (
        <>
            { !success && <form onSubmit={handleSubmit(onSubmit)} className='w-full' noValidate>
                <div className='mb-3 flex flex-col'>
                    <label className='font-light mb-1'>{t('forgot_password.form.password_label')}</label>
                    <Input type='password' name={'password'} register={register('password')} error={errors.password?.message && String(errors.password?.message)}/>
                </div>

                <Button type='submit' $color='light-primary' className='px-20'>{t('forgot_password.form.submit_password_change')}</Button>
            </form> }
            { success && <div>
                <p>{ t('forgot_password.form.after_password_change_message') }</p>
                <Button className='mt-2' $color={'light-primary'} onClick={() => navigate('/login')}>Inloggen</Button>
            </div> }
        </>
    )
}