import Spinner from "../components/Spinner";
import ErrorPage from "../pages/ErrorPage";
import {ReactNode} from "react";

interface AwaitFetchProps {
    httpStatus: number,
    children: JSX.Element | ReactNode
}

export default function AwaitFetch({httpStatus, children}: AwaitFetchProps): JSX.Element {
    if (httpStatus === null) {
        return (
            <div className='min-h-[inherit] w-full h-full flex items-center justify-center'>
                <Spinner className='h-24 w-24' />
            </div>
        )
    } else if (httpStatus === 200) {
        return children as JSX.Element
    } else {
        return <ErrorPage status={httpStatus} />
    }
}