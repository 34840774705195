import {useEffect, useState} from "react";

class GlobalState {
    static values: any = [];
    static subscribers: any  = [];

    static get(subscribeKey: string) {
        return this.values[subscribeKey] ? this.values[subscribeKey] : null;
    };

    static set(subscribeKey: string, value: any) {
        if (value === false || value !== undefined) {
            this.values[subscribeKey] = value
            if (this.subscribers[subscribeKey]) {
                this.subscribers[subscribeKey].forEach((method: Function) => method(this.get(subscribeKey)))
            }
        } else {
            this.values[subscribeKey] = undefined
        }
    };

    static remove(subscribeKey: string) {
        if (this.isAvailable(subscribeKey)) {
            this.values[subscribeKey] = undefined
        }

        if (this.subscribers[subscribeKey]) {
            this.subscribers[subscribeKey].forEach((method: Function) => method(null))
        }
    };

    static isAvailable(subscribeKey: string) {
        return !!this.values[subscribeKey];
    }


    static subscribe(subscribeKey: string, method: Function) {
        if (!this.subscribers[subscribeKey]) {
            this.subscribers[subscribeKey] = [];
        }

        this.subscribers[subscribeKey].push(method);
    }

    static unsubscribe(subscribeKey: string, method: Function) {
        if (!this.subscribers[subscribeKey]) {
            return;
        }

        const index = this.subscribers[subscribeKey].indexOf(method);
        if (index > -1) {
            this.subscribers[subscribeKey].splice(index, 1);
        }
    }
}

export function useGlobalState(subscribeKey: string, defaultGlobalValue: any) {
    const [state, setState] = useState(GlobalState.get(subscribeKey));

    if (defaultGlobalValue && !GlobalState.isAvailable(subscribeKey)) {
        GlobalState.set(subscribeKey, defaultGlobalValue)
    }

    function onExternalStateChange(newStateIncomming: any) {
        setState(newStateIncomming);
    }

    useEffect(() => {
        GlobalState.subscribe(subscribeKey, onExternalStateChange);
        return () => {
            GlobalState.unsubscribe(subscribeKey, onExternalStateChange);
        };
    }, []);

    function setStateInSubscribers(newStateOutgoing: any) {
        GlobalState.set(subscribeKey, newStateOutgoing);
    }

    return [state, setStateInSubscribers];
}

export default GlobalState;