import {H1} from "../styled-components/Headings";
import React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";

export const Header = (props) => {

    const {width} = useWindowDimensions()

    return (
        <div className={`${width < 376 ? 'h-[100vw]' : 'h-[376px]'} w-full bg-gray-500 mb-[50px] tablet-landscape:mb-[100px]`}>
            <div className='h-full relative' style={{backgroundImage: "url(./assets/images/TTP-header-website.jpeg)"}}>
                <div className={"container mx-auto h-full relative"}>
                    <H1 className='uppercase break-words bg-white p-2 absolute w-fit top-[90px] mr-[15px] tablet-landscape:top-[250px] text-black'>{props.title}</H1>
                </div>
            </div>
        </div>
    )
}