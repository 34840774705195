import Viewer from "../../components/Viewer";
import SceneRecognitionContainer from "../../components/Scene/SceneAnnotateContainer";
import SceneProvider from "../../providers/SceneProvider";

export default function SceneAnnotatePage() {
    return (
        <div className='h-screen tablet:overflow-y-auto overflow-y-hidden w-full flex'>
            <SceneProvider>
                <Viewer />
                <SceneRecognitionContainer />
            </SceneProvider>
        </div>
    )
}