import React, {Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState} from "react";
import {Annotation, Batch, CheckObject, Label} from "../types/entities";
import {counter} from "@fortawesome/fontawesome-svg-core";

export interface AnnotationContextType {
    isDrawing: string | null
    setIsDrawing: Dispatch<SetStateAction<string | null>>
    currentLabel: Label | null
    setCurrentLabel: Dispatch<SetStateAction<Label | null>>
    annotorious: any
    setAnnotorious: Dispatch<SetStateAction<any>>
    activeAnnotation: Annotation,
    setActiveAnnotation: Dispatch<SetStateAction<Annotation>>,
    tool: 'rect' | 'polygon'
    setTool: Dispatch<SetStateAction<'rect' | 'polygon'>>
    batches: Batch[],
    labels: Label[],
    setLabels: Dispatch<SetStateAction<Label[]>>,
    checkLabels: Label[],
    setCheckLabels: Dispatch<SetStateAction<Label[]>>,
    aiLabels: Label[],
    setAiLabels: Dispatch<SetStateAction<Label[]>>,
    addAnnotationToLabel: (a: any) => void
    addNotFoundAnnotationToLabel: (l: string) => void
    removeAllNotFoundAnnotationsOfLabel: (l: string) => void
    selectAnnotation: (annotation: any) => void;
    updateAnnotation: (annotation: any) => void;
    deleteAnnotation: (annotation: any) => void;
    getColour: (previousColor: string) => string;
}

export const AnnotationContext = React.createContext<AnnotationContextType>(null!);

export const useAnnotation = () => useContext(AnnotationContext);

export default function AnnotationProvider({ children }: { children: ReactNode }) {
    const [isDrawing, setIsDrawing] = useState<string | null>(null);
    const [currentLabel, setCurrentLabel] = useState<Label | null>(null);
    const [tool, setTool] = useState<'rect' | 'polygon'>('rect')
    const [annotorious, setAnnotorious] = useState(null)
    const [activeAnnotation, setActiveAnnotation] = useState<Annotation>(null)
    const [labels, setLabels] = useState<Label[]>(null)
    const [checkLabels, setCheckLabels] = useState<Label[]>(null)
    const [aiLabels, setAiLabels] = useState<Label[]>(null)
    const [batches, setBatches]= useState<Batch[]>(null)

    // For check objects
    // const [activeCheckObject, setActiveCheckObject] = useState<CheckObject>(null)

    useEffect(() => {
        if (annotorious) {
            annotorious.setDrawingEnabled(isDrawing)

            annotorious.disableSelect = isDrawing;

            if (isDrawing) {
                annotorious.cancelSelected();
            }
        }
    }, [annotorious, isDrawing])

    const addAnnotationToLabel = (annotationBase: any) => {
        const labelsCopy = Object.assign([], labels)

        const output = annotationBase.target.selector.value.match(/[^"]+[a-z0-9]+[0-9]/g)
        const annotation: Annotation = {
            id: createId(),
            type: tool,
            base: annotationBase,
            content: formatCoordinates(tool, output[0])
        } 
        
        if (labelsCopy[labelsCopy.findIndex(l => l.id === isDrawing)].annotations === undefined) {
            labelsCopy[labelsCopy.findIndex(l => l.id === isDrawing)].annotations = [];
        }

        labelsCopy[labelsCopy.findIndex(l => l.id === isDrawing)].annotations.push(annotation)
        setLabels(labelsCopy);
    }

    const addNotFoundAnnotationToLabel = (labelId: string) => {
        const labelsCopy = Object.assign([], labels)
        const annotation: Annotation = {
            id: createId(),
            type: 'notFound',
        }

        if (labelsCopy[labelsCopy.findIndex(l => l.id === labelId)].annotations === undefined) {
            labelsCopy[labelsCopy.findIndex(l => l.id === labelId)].annotations = [];
        }

        labelsCopy[labelsCopy.findIndex(l => l.id === labelId)].annotations.push(annotation)
        setLabels(labelsCopy);
    }

    const removeAllNotFoundAnnotationsOfLabel = (labelId: string) => {
        const labelsCopy = Object.assign([], labels)

        if (labelsCopy[labelsCopy.findIndex(l => l.id === labelId)].annotations != null) {
            labelsCopy[labelsCopy.findIndex(l => l.id === labelId)].annotations = labelsCopy[labelsCopy.findIndex(l => l.id === labelId)].annotations.filter(a => a.type !== 'notFound')
        }

        setLabels(labelsCopy);
    }

    const deleteAnnotation = (annotation: Annotation): void => {
        if (annotation.base && annotation.base.id) {
            annotorious.removeAnnotation(annotation.base.id)
        }

        const labelsCopy = Object.assign([], labels)
        const labelIndex = labelsCopy.findIndex(l => l.annotations.findIndex(a => a.id === annotation.id) !== -1)

        if (labelsCopy[labelIndex].annotations.length) {
            const annotationIndex = labelsCopy[labelIndex].annotations.findIndex(a => a.id === annotation.id)
            labelsCopy[labelIndex].annotations.splice(annotationIndex, 1);
        } else {
            labelsCopy[labelIndex].annotations = []
        }

        setLabels(labelsCopy)
        setActiveAnnotation(null)
    }

    const selectAnnotation = (annotation: Annotation) => {
        for (const label of labels) {
            if (label.annotations) {
                for (const anno of label.annotations) {
                    if (anno.base && anno.base.id === annotation.id) {
                        setActiveAnnotation(anno)
                    }
                }
            }
        }
    }

    const updateAnnotation = (annotation: any) => {
        const labelsCopy = Object.assign([], labels);
        
        labelsCopy.forEach((l, li) => {
            l.annotations.forEach((a, ai) => {
                if (a.base.id === annotation.id) {
                    const output = annotation.target.selector.value.match(/[^"]+[a-z0-9]+[0-9]/g)
                    a.content = formatCoordinates(a.type, output[0])
                }
            })
        })

        setLabels(labelsCopy);
        setActiveAnnotation(null);
    }

    const createId = (): number => {
        let ids: number[] = [];

        labels.forEach((label) => {
            ids = [...ids, ...(label.annotations ? label.annotations.map(a => a.id) : [])]
        })

        const highestId = Math.max(...ids)
        return isFinite(highestId) ? highestId + 1 : 1;
    }

    // Check functions
    useEffect(() => {
        if (annotorious && checkLabels) {
            checkLabels.forEach(cl => {
                cl.objects.forEach((object, i) => {
                    const existingAnnotation = document.querySelector(`[data-id="#${object.id}"]`);
                    if (existingAnnotation == null) {
                        const prevColor = i > 0 ? cl.objects[i - 1].colour : null;
                        object.colour = getColour(prevColor)
                        const base = objectToAnnotation(object, object.colour);
                        object.base = base;
                        annotorious.addAnnotation(base);
                    }
                })
            })
        }
    }, [annotorious, checkLabels])

    const getColour = (previousColor: string | null = null): string => {
        if (previousColor == null) {
            previousColor = 'PURPLE'
        }

        const colors = ['GREEN', 'YELLOW', 'ORANGE', 'BLUE', 'PINK', 'PURPLE'];

        let index = colors.findIndex(c => c === previousColor);

        if ((colors.length - 1) === index) {
            index = -1;
        }

        return colors[index + 1];
    }

    const annotatedValue = { isDrawing, setIsDrawing, currentLabel, setCurrentLabel, annotorious, setAnnotorious, selectAnnotation,activeAnnotation, setActiveAnnotation, tool, setTool, batches, labels, setLabels, checkLabels, setCheckLabels, aiLabels, setAiLabels, setBatches, addAnnotationToLabel, addNotFoundAnnotationToLabel, removeAllNotFoundAnnotationsOfLabel, updateAnnotation, deleteAnnotation, getColour}

    return <AnnotationContext.Provider value={annotatedValue}>{children}</AnnotationContext.Provider>;
}

function formatCoordinates(type: 'rect' | 'polygon', output: string): string[] {
    if (type === 'rect') {
        output = output.replace('xywh=pixel:', '');
        return output.split(" ")
    }

    return output.split(" ")
}

function objectToAnnotation(object: CheckObject, color: string) {
    let type = '';
    if (object.type === 'Polygon') {
        type = 'SvgSelector'
    } else {
        type = 'FragmentSelector'
    }



    return {
        "@context": "http://www.w3.org/ns/anno.jsonld",
        id: `#${object.id}`,
        type: "Annotation",
        body: {
            value: color,
            purpose: 'highlighting',
        },
        target: {
            "selector": {
                "type": type,
                "conformsTo": "http://www.w3.org/TR/media-frags/",
                "value": object.value
            }
        }
    }
}

// just for tailwind
const tailwindColours = ['bg-green-300', 'bg-yellow-300', 'bg-orange-300', 'bg-blue-300', 'bg-pink-300', 'bg-purple-300']
const colours = [
    'GREEN', 'YELLOW', 'ORANGE', 'BLUE', 'PINK', 'PURPLE',
    'GREEN', 'YELLOW', 'ORANGE', 'BLUE', 'PINK', 'PURPLE', 'GREEN', 'YELLOW', 'ORANGE', 'BLUE', 'PINK', 'PURPLE']