import {Button} from "../../styled-components/Button";
import {SuffixButton} from "../Buttons";
import Icon from "../Icon";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {useScene} from "../../providers/SceneProvider";
import React from "react";
import {Tooltip} from "@mui/material";
import SkipRemark from "../SkipRemark";
import {isMobile} from "react-device-detect";
import {TabletWidth} from "../../constants/ScreenWidth";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {i18nStringFromObject} from "../../plugins/i18n";

interface SceneBottomBarProps {
    onSubmit: () => void
    canContinue?: boolean
    skipRemark?: JSX.Element
}

function SceneBottomBar({onSubmit, canContinue, skipRemark}: SceneBottomBarProps) {
    const scene = useScene()
    const {i18n, t} = useTranslation()
    const {width} = useWindowDimensions()

    return (
        <>
            <div className={scene.expanded ? 'hidden' : 'bg-white border-t border-gray-500 w-full flex flex-col px-3 tablet:px-9 pb-4'}>
                {scene.showSkipRemark && <SkipRemark handleClose={scene.toggleSkipRemark}
                >
                    {skipRemark}
                </SkipRemark>}
                <div className='py-4'>
                    <div id="labelShower"
                         className={(scene.batch.type === 'Object' || scene.batch.phase === "Fase 2") ? 'hidden' : ''}>
                        {!scene.isSceneAnnotateContainer ? <></> : <div> {
                            !scene.selectedLabel || (scene.selectedLabel && scene.selectedLabel.length === 0)?
                                <div className="flex items-center center justify-center">
                                    <h1 className="py-1 font-mabry text-[15px] font-normal">{t('annotate.bottom_bar.no_label_selected')}</h1>
                                </div>
                                :
                                <div className={`flex items-center center justify-center ${isMobile || window.innerWidth <= TabletWidth ? 'space-x-0' : 'space-x-2'}`}>
                                    <h1 className="font-mabry text-[15px] font-normal">{t('annotate.bottom_bar.your_choice')} &nbsp;</h1>
                                    {scene.selectedLabel.map((label, key) => {
                                        return <h1 key={key}
                                                   className={`px-2 py-1 ${isMobile || window.innerWidth <= TabletWidth ? 'text-primary-light' : 'bg-primary-light text-white'} font-mabry font-bold`}>{ i18nStringFromObject(label, 'title') }</h1>

                                    })}
                                </div>
                        }</div>}
                    </div>
                </div>
                <div className='flex items-center h-[30px] w-full tablet:w-auto space-x-2 tablet:justify-between'>
                    <div className='tablet:flex-1 flex w-[50vw] tablet:w-auto space-x-2'>
                        <Tooltip title={t('general.tooltips.skip')} enterDelay={500}>
                            <Button $color='light-white' $center className={"w-full tablet:w-auto"}
                                    onClick={scene.toggleSkipRemark}>{scene.isCheckContainer ? t('general.actions.i_dont_know') : t('general.actions.skip') }</Button>
                        </Tooltip>
                    </div>
                    <div className={"w-[50vw] tablet:w-auto"}>
                        <Tooltip title={t('general.tooltips.continue')} enterDelay={500}>
                            <SuffixButton className={"w-full"} $color='light-primary' suffix={<Icon icon={faArrowRight}/>} disabled={!canContinue}
                                          onClick={onSubmit}>
                                <span>{t('general.actions.continue')}</span>
                            </SuffixButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </>

    )
}

export default SceneBottomBar