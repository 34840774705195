import NavBar from "../components/NavBar";
import {H1, H2, H3} from "../styled-components/Headings";
import AwaitFetch from "../middleware/AwaitFetch";
import {Batch} from "../types/entities";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Api from "../Api";
import {useParams} from "react-router";
import Donut from "../components/Donut";
import {Button} from '../styled-components/Button'
import {Link} from "react-router-dom";
import Stop from "../components/Stop";

export const BatchEndPage = () => {
    const { id } = useParams()
    const { t, i18n } = useTranslation()
    const [batch, setBatch] = useState<Batch>(null)
    const [httpStatus, setHttpStatus] = useState(null)

    useEffect(() => {
        Api.get(`/batches/${id}`,
            ({ data }) => {
                setBatch(data)
                setHttpStatus(200)
            })
    }, [])

    return ( <AwaitFetch httpStatus={httpStatus} >
        { batch &&
            <div className='min-h-screen bg-gray-300'>
                <NavBar />
                <div className='h-[270px] w-full bg-gray-500'>
                    <div className='container mx-auto h-full relative'>
                        <H1 className='uppercase bg-white inline-block p-2 absolute bottom-16 text-primary-light'>
                            {t('end_of_batch.title')}
                        </H1>
                    </div>
                </div>

                <div className='transform -translate-y-12'>
                    <div className='container mx-auto space-y-4'>
                        <div className='min-h-[512px] mx-auto flex flex-col tablet:flex-row'>
                            { batch.project.isDemo ?
                                <div className={"bg-white w-full h-auto flex items-center justify-center"}>
                                    <H2>
                                        {t('end_of_batch.demo_text')}
                                    </H2>
                                </div>
                                :
                                <>
                                    <div className='w-full tablet:w-5/12 bg-primary-light p-8 md:p-12 lg:p-20 space-y-6'>
                                        <H3 className='uppercase text-white'>{ t('end_of_batch.subtitle') }</H3>
                                        <p className='text-white'>
                                            {t('end_of_batch.text')}
                                        </p>
                                    </div>
                                    <div className='w-full tablet:w-7/12 bg-white p-8 md:p-12 lg:p-20 space-y-8'>
                                        <div className='w-full min-h-72 bg-primary-light p-8 space-y-4'>
                                            <H2 className='text-white uppercase'>{t("end_of_batch.title")}</H2>
                                            <div className='flex space-x-12 items-center'>
                                                <div className='w-1/2'>
                                                    <Donut percentage={Math.round((batch.statistics.userPhotoCount ?? 0) / (batch.statistics.photoCount ?? 0) * 100)}>
                                                        <div className={'flex flex-col items-center'}>
                                                            <p className={"font-mabry text-[20px] font-bold text-white"}>
                                                                {Math.round((batch.statistics.userPhotoCount ?? 0) / (batch.statistics.photoCount ?? 0) * 100)}%
                                                            </p>
                                                            <p className={"font-mabry text-[12px] font-light text-white"}>
                                                                {t("end_of_batch.statistics.finished")}
                                                            </p>
                                                        </div>
                                                    </Donut>
                                                </div>

                                                <div className='w-1/2 text-white space-y-3'>
                                                    <div>
                                                        <p>{t("end_of_batch.statistics.total_amount_of_photos_in_collection")}</p>
                                                        <H2>{ (batch.statistics.photoCount ?? 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }</H2>
                                                    </div>
                                                    <div>
                                                        <p>{t("end_of_batch.statistics.total_amount_finished")}</p>
                                                        <H2>{ (batch.statistics.userPhotoCount ?? 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }</H2>
                                                    </div>
                                                    <div>
                                                        <p>{t("end_of_batch.statistics.total_amount_active_participants")}</p>
                                                        <H2>{ (batch.statistics.activeParticipants ?? 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }</H2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'flex justify-center space-x-4'}>
                                            <Link to={`/project/${batch.project.id}`}>
                                                <Button $color={'light-primary'}>
                                                    {t("end_of_batch.actions.redirect_to_project_page")}
                                                </Button>
                                            </Link>
                                            <Link to={'/profile'}>
                                                <Button $color={'light-white'}>
                                                    {t("end_of_batch.actions.redirect_to_profile_page")}
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div> }
    </AwaitFetch>
    )
}