import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";

export default function YoutubeContainer({ src }: { src: string }) {
    const { t } = useTranslation();
    const cookieConsents = localStorage.getItem("cookieConsents");
    const [showYoutube, setShowYoutube] = useState(false)

    useEffect(() => {
        if (cookieConsents) {
            const parsedConsents = JSON.parse(cookieConsents)
            if ('youtube' in parsedConsents) {
                setShowYoutube(!!parsedConsents.youtube)
            }
        }
    }, [cookieConsents])

    if (showYoutube) {
        return <iframe className={"absolute top-0 left-0 w-full h-full"} src={src}
                       title="Tag The Picture - Samen brengen we het verleden in beeld"
                       frameBorder="0"
                       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                       allowFullScreen/>
    }


    return (<div className={"absolute top-0 left-0 w-full h-full text-center justify-center flex "}>
            <div className={"my-auto"}>
                <Trans i18nKey='general.cookie_statement.video_not_available.text' components={{
                    url: <a className={"text-blue-300 underline"} href={src} target="_blank" />
                }} />
            </div>
        </div>);
}