import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import ReactGA from "react-ga";


const parsedValues = JSON.parse(localStorage.getItem('cookieConsents'));

// disable console logs
console.log(process.env.REACT_APP_ENV)
if (process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "prod") {
    console.log('running as prod')
    console.log = () => {};
    console.error = () => {};
    console.dir = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <App />
      <div id='popup'></div>
  </React.StrictMode>,
  document.getElementById('root')
);

// const SendAnalytics = ()=> {
//     if (parsedValues?.analytics === true) {
//         ReactGA.send({
//             hitType: "pageview",
//             page: window.location.pathname,
//         });
//     }
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
