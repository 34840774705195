import Viewer from "../../components/Viewer";
import SceneProvider from "../../providers/SceneProvider";
import ObjectValidateContainer from "../../components/Scene/ObjectValidateContainer";

export default function ObjectValidatePage() {

    return (
        <div className='h-screen tablet:overflow-y-auto overflow-y-hidden w-full flex'>
            <SceneProvider>
                <Viewer isChecking />
                <ObjectValidateContainer />
            </SceneProvider>
        </div>
    )
}