import React, {useCallback, useEffect, useRef, useState} from "react";
import SceneHeader from "./SceneHeader";
import SceneBottomBar from "./SceneBottomBar";
import LabelSkeleton from "../LabelSkeleton";
import AwaitFetch from "../../middleware/AwaitFetch";
import Api from "../../Api";
import {AxiosError} from "axios";
import {useScene} from "../../providers/SceneProvider";
import {useTranslation, WithTranslation, withTranslation} from "react-i18next";
import {debounce, FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import SanitizeHTML from "../SanitizeHTML";
import {Button} from "../../styled-components/Button";
import {useSearchParams} from "react-router-dom";
import SceneErrorPopup from "./SceneErrorPopup";
import {setSelectionRange} from "@testing-library/user-event/dist/utils";
import {isMobile, mobileVendor, mobileModel} from "react-device-detect";
import {TabletWidth} from "../../constants/ScreenWidth";
import {a} from "@react-spring/web";
import {PrefixButton} from "../Buttons";
import Icon from "../Icon";
import {faAnglesDown, faAnglesUp} from "@fortawesome/free-solid-svg-icons";
import AnnotableLabel from "../Annotate/AnnotableLabel";
import Annotation from "../Annotate/Annotation";
import BottomDrawer from "../BottomDrawer";
import i18n from "i18next";
import {i18nStringFromObject} from "../../plugins/i18n";

function SceneAnnotateContainer({t, i18n}: WithTranslation) {
    const scene = useScene();
    const [searchParams, setSearchParams] = useSearchParams();
    const [httpStatus, setHttpStatus] = useState(null)
    const [searchError, setSearchError] = useState(null)
    const [showPostError, setShowPostError] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        console.log(scene.selectedLabel)
        scene.setSkipOption(null)
    }, [scene.selectedLabel])

    useEffect(() => {
        localStorage.setItem("notes", '');
        fetchLabels(null)
    }, [])

    useEffect(() => {
        filterLabels(scene.labelFilter);
    }, [scene.labelFilter])

    const filterLabels = useCallback(
        debounce((query) => fetchLabels(query), 500)
        , [])

    const getLabelUrl = (filterQuery) => {
        const url = `${scene.project.isDemo ? '/demo' : ''}/batches/${scene.batch.id}/labels`
        const params = {
            'filter[parent]': 'NULL'
        }

        if (filterQuery !== null && filterQuery !== "") {
            params[`filter[title][LIKE]`] = `${filterQuery}`
            params[`filter[lang][eq]`] = i18n.language.toUpperCase()
        }

        return `${url}?${(new URLSearchParams(params)).toString()}`;
    }

    const fetchLabels = (filterQuery?: string) => {
        setHttpStatus(null)
        Api.get(getLabelUrl(filterQuery),
            ({data}) => {
                scene.setLabels(data.items)
                setHttpStatus(200)
            },
            null,
            null,
            (error: AxiosError) => {
                setHttpStatus(error.response.status)
            })
    }

    const submit = (skip = false) => {

        const values = {};

        if (skip) {
            values['skip'] = {
                annotations: [{
                    type: 'skip',
                    content: scene.skipOption
                }]
            }
        }

        if (scene.explicit) {
            values['explicit'] = {
                annotations: [{
                    type: 'explicit',
                }]
            }
        }

        if (scene.isChecked && scene.remark !== "") {
            values['remark'] = {
                annotations: [{
                    type: 'remark',
                    content: scene.remark
                }]
            }
        }


        if (scene.project.isDemo) {
            scene.next(parseInt((searchParams.get('p') ?? '1')) + 1)
            return
        } else if (Object.entries(values).length) {
            Api.post('/attach-annotations-to-photo',
                {
                    photoId: scene.image.id,
                    labels: values
                },
                () => {
                    if (skip) {
                        scene.next(null)
                    }
                },
                null,
                null,
                (error: AxiosError) => {
                    if (skip) {
                        setShowPostError(true);
                    }
                })
        }

        if (!skip) {
            Api.post(`/attach-labels-to-photo`, {
                    photoId: scene.image.id,
                    labels: scene.selectedLabel.map(l => l.id),
                    device: mobileVendor + ' ' + mobileModel
                },
                () => {
                    scene.next(null)
                },
                null,
                null,
                (error: AxiosError) => {
                    setShowPostError(true);
                }
            )
            scene.setSelectedLabel(null)
        }
    }

    const TutorialRecognize = () => {
        const { i18n } = useTranslation();
        const scene = useScene();

        return (
            <div className={"outro"}>
                <div className="h-full tablet:min-w-[400px] my-auto space-y-4 flex flex-col justify-center">
                    <div className="h-full my-auto space-y-4 flex flex-col justify-center">
                        <SanitizeHTML>
                            { i18nStringFromObject(scene.batch, 'batchIntroAnnotate')}
                        </SanitizeHTML>
                    </div>
                </div>
            </div>
        )
    }

    const SkipRemark = () => {
        const [skipOption, setSkipOption] = useState(null)

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSkipOption((event.target as HTMLInputElement).value)
        }

        const send = () => {
            if (skipOption !== null) {
                scene.setSkipOption(skipOption)
            }
        }

        useEffect(() => {
            if (scene.skipOption !== null) {
                submit(true)
            }
        }, [skipOption])

        return (
            <div className={"outro w-full"}>
                <div className={`h-full tablet:min-w-[400px] w-full my-auto space-y-4 flex flex-col justify-center`}>
                    <div className="h-full my-auto space-y-4 flex flex-col justify-center">
                        <h1>{t("annotate.popups.skip.title")}</h1>
                        <p>{t("annotate.popups.skip.text")}</p>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                onChange={handleChange}
                                value={skipOption}
                            >
                                <FormControlLabel value="dont-know" control={<Radio size={"small"}/>} label={<p>{t("annotate.popups.skip.i_dont_know")}</p>} />
                                <FormControlLabel value="unclear-scan" control={<Radio size={"small"}/>} label={<p>{t("annotate.popups.skip.unclear_scan")}</p>}/>
                            </RadioGroup>
                        </FormControl>
                        <div className={"flex flex-col tablet:flex-row tablet:justify-center w-full gap-2 pt-3"}>
                            <Button disabled={!skipOption} onClick={() => send()}
                                    $color={'light-primary'}>{t("annotate.popups.skip.actions.skip")}</Button>
                            <Button onClick={scene.toggleSkipRemark} $color={'light-white'}>{t("general.actions.cancel")}</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const Remark = () => {
        const t = i18n.t;
        const input = useRef(null)

        const addRemark = () => {
            if (input && input.current) {
                scene.setRemark(input.current.value)
            }
            scene.setRemarkSubmitted(true)
        }

        return (
            <div className={"outro w-full"}>
                { scene.remarkSubmitted ? (
                    <div className={`h-full tablet:min-w-[400px] my-auto space-y-4 flex flex-col justify-center`}>
                        <div className="h-full my-auto space-y-4 flex flex-col justify-center">
                            <h1>{t("annotate.popups.remark.after_sent.title")}</h1>
                            <p>{t("annotate.popups.remark.after_sent.text")}</p>
                        </div>
                    </div>
                ) : (
                    <div className={`tablet:min-w-[400px] my-auto space-y-4 flex flex-col justify-center`}>
                        <h1>{t("annotate.popups.remark.title")}</h1>
                        <p dangerouslySetInnerHTML={{__html: t("annotate.popups.remark.text")}} />
                        <label className={`text-xs font-light space-x-1 flex-1 flex items-center`}>
                            <input id="explicit" type="checkbox"
                                   className="h-[16px] w-[16px] border-[1px] border-gray-warm rounded-none"
                                   onChange={() => scene.setExplicit(!scene.explicit)} checked={scene.explicit}
                            />
                            <span>{t("annotate.popups.remark.options.mark_as_explicit_content")}</span>
                        </label>
                        <label className={`text-xs font-light space-x-1 flex-1 flex items-center`}>
                            <input id="other-remark" type="checkbox"
                                   className="h-[16px] w-[16px] border-[1px] border-gray-warm rounded-none"
                                   onChange={() => scene.setIsChecked(!scene.isChecked)} checked={scene.isChecked}/>
                            <span>{t("annotate.popups.remark.options.add_remark")}</span>
                        </label>
                        <div className={`w-full ${scene.isChecked ? 'block' : 'hidden'}`}>
                            <h2 className={"font-mabry text-[14px] font-normal"}>{t("annotate.popups.remark.add_remark_explanation")}</h2>
                            <textarea id='remark'
                                      ref={input}
                                      className="w-full !mt-0 p-2 overflow-auto outline-0 resize-none text-[14px] font-mabry font-normal border-[1px] border-gray-500 min-h-[185px]"
                                      name="remark"/>
                            <h2 className={"font-mabry text-[14px] font-normal"}>{t("annotate.popups.remark.add_remark_extra_info")}</h2>
                        </div>
                        <div className={"flex relative tablet:flex-row flex-col tablet:justify-center w-full tablet:w-auto gap-2 pt-3"}>
                            <Button onClick={() => {
                                addRemark()
                            }}
                                    $color={'light-primary'}>{t("annotate.popups.remark.actions.add")}</Button>
                            <Button onClick={scene.toggleRemark}
                                    $color={'light-white'}>{t("general.actions.cancel")}</Button>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <>
            { (isMobile || window.innerWidth <= TabletWidth) ?
                <>
                    {(scene.overlay && !scene.isSubStep) && <div className={`fixed bottom-0 left-0 right-0 bg-black h-screen w-screen opacity-50 z-[78] transition-all duration-500 ease-in-out`}></div>}
                    <SceneHeader remark={<Remark/>} tutorialElement={<TutorialRecognize />} type='scene-annotate'/>
                    <BottomDrawer search searchError={searchError} isSceneAnnotatePage annotableLabel={
                            <AwaitFetch httpStatus={httpStatus}>
                                <div className="flex flex-col w-full space-y-1" id='label-tray'>
                                    {scene.labels.length === 0 && <span
                                        className='text-center text-sm opacity-50 mt-2'>{t('annotate.labels_container.no_labels_found')}</span>}
                                    {scene.labels?.map((label, key) => {
                                        return <LabelSkeleton
                                            isDemo={scene.project.isDemo}
                                            alreadyExpanded={scene.labelFilter !== null && scene.labelFilter !== ''} key={key}
                                            label={label}/>
                                    })}
                                </div>
                            </AwaitFetch>
                    }/>
                    <div className={"bottom-0 fixed z-[999999999] w-full"}>
                        <SceneBottomBar skipRemark={<SkipRemark />} onSubmit={() => submit()} canContinue={scene.selectedLabel && scene.selectedLabel.length !== 0}/>
                    </div>

                </>
            :
                <>
                    <div className={scene.expanded ? 'w-[5%]' : 'bg-gray-200 w-5/12 h-full flex flex-col'}>
                        <SceneHeader remark={<Remark />} tutorialElement={<TutorialRecognize/>} type='scene-annotate' search searchError={searchError}/>
                        <div className={scene.expanded ? 'hidden' : 'flex-1 flex overflow-y-auto px-9 px-2 my-2'}>
                            <AwaitFetch httpStatus={httpStatus}>
                                <div className="flex min-h-min flex-col w-full space-y-1" id='label-tray'>
                                    {scene.labels.length === 0 && <span
                                        className='text-center text-sm opacity-50 mt-2'>{t('annotate.labels_container.no_labels_found')}</span>}
                                    {scene.labels?.map((label, key) => {
                                        return <LabelSkeleton
                                            isDemo={scene.project.isDemo}
                                            alreadyExpanded={scene.labelFilter !== null && scene.labelFilter !== ''} key={key}
                                            label={label}/>
                                    })}
                                </div>
                            </AwaitFetch>
                        </div>
                        <SceneBottomBar skipRemark={<SkipRemark />} onSubmit={() => submit()} canContinue={scene.selectedLabel && scene.selectedLabel.length !== 0}/>
                    </div>
                    { showPostError && <SceneErrorPopup onClose={() => setShowPostError(false)} />}
                </>
            }
        </>
    )
}

export default withTranslation()(SceneAnnotateContainer)
