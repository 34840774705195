import {Button} from "../../styled-components/Button";
import {H5} from "../../styled-components/Headings";
import {Comment as CommentObject} from '../../types/entities';
import {useAuth} from "../../providers/AuthProvider";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import Icon from "../Icon";
import {useEffect, useState} from "react";
import {CommentInput} from "./CommentInput";
import Api from "../../Api";
import {HelperFunctions} from "../../utils/HelperFunctions";
import {TabletWidth} from "../../constants/ScreenWidth";
import {faReply} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

export const Comment = ({ comment, onDelete }: { comment: CommentObject, onDelete: (c: CommentObject) => void }) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const [goComment, setGoComment] = useState(false)
    const [comments, setComments] = useState(comment.comments)

    const deleteComment = () => {
        Api.delete(`/comments/${comment.id}`,
            ({data}) => {
                console.log(data);
                onDelete(data)
            },
            null,
            null,
            () => {

            })
    }

    const addComment = (comment: CommentObject) => {
        const commentsCopy = Object.assign([], comments)
        commentsCopy.unshift(comment);
        setComments(commentsCopy);
        setGoComment(false);
    }

    const deleteChildComment = (comment: CommentObject) => {
        let commentsCopy = Object.assign([], comments)
        commentsCopy.splice(commentsCopy.findIndex(c => c.id === comment.id), 1)
        setComments(commentsCopy);
    }
    const dateFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    }
    const lastEditedFormatted = HelperFunctions.getDateFormat(comment.lastEdited, dateFormatOptions)
    return (
        <div className='flex flex-col'>
            <div className='flex flex-col border-b border-gray-700 pb-8 mb-8 space-y-8'>
                <div className='flex'>
                    <div className='w-16 h-16 mr-4 tablet-landscape:mr-12'>
                        <img className='w-full h-full object-cover' src={comment.user.profilePicture ? comment.user.profilePicture : '/assets/images/TTP-avatar.png'} />
                    </div>
                    <div className='flex-1 flex flex-col'>
                        <div className='flex justify-between mb-4'>
                            <div className='flex flex-col'>
                                <H5>{ comment.user.firstname } { comment.user.middleName } { comment.user.surname }</H5>
                                <small className='text-2xs italic font-normal'>{`${lastEditedFormatted.day} ${lastEditedFormatted.month} ${lastEditedFormatted.year} ${lastEditedFormatted.hour}:${lastEditedFormatted.minute}`}</small>
                            </div>
                            <div className='flex space-x-4 items-center'>
                                { (user && comment.user.id === user.id && comment.deletedAt == null) && <Icon className={'cursor-pointer'} icon={faTrash} onClick={deleteComment}/> }
                                    <Button className={window.innerWidth <= TabletWidth ? 'px-3' : 'px-10'} $color='light-white' $small onClick={() => setGoComment(true)}>
                                        {window.innerWidth <= TabletWidth ? <Icon icon={faReply} /> : t('project.comments.comment.respond') }
                                    </Button>
                            </div>
                        </div>
                        <p className={`!font-normal whitespace-pre-wrap text-[14px] tablet-landscape:text-sm ${comment.deletedAt && 'italic text-black/70'}`}>
                            { comment.text }
                        </p>
                    </div>
                </div>
                { goComment && <CommentInput parent={comment.id} type='comment' onComment={addComment}/> }
            </div>


            <div className='pl-8'>
                { comments.map((child) => {
                    return <Comment key={child.id} comment={child} onDelete={deleteChildComment}/>
                }) }
            </div>
        </div>
    )
}