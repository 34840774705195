import Viewer from "../../components/Viewer";
import SceneProvider from "../../providers/SceneProvider";
import ObjectNextLevelContainer from "../../components/Scene/ObjectNextLevelContainer";

export default function ObjectNextLevelPage() {
    return (
        <div className='h-screen tablet:overflow-y-auto overflow-y-hidden w-full flex'>
            <SceneProvider isNextLevelChecking>
                <Viewer isChecking/>
                <ObjectNextLevelContainer />
            </SceneProvider>
        </div>
    )
}