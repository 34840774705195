import { useState } from "react";
import Icon from "./Icon";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {SUBTITLE} from "../styled-components/Headings";

function ErrorPopup({ message, isOpen, onClose }) {
    const [isVisible, setIsVisible] = useState(isOpen);

    const handleClose = () => {
        setIsVisible(false);
        onClose();
    };

    return (
        <>
            { isVisible && <div className="popup-overlay">
                <div className="popup-container">
                    <SUBTITLE>ERROR</SUBTITLE>
                    <p className={"text-[16px]"}>{message}</p>
                    <button className="absolute right-[28px] top-[20px]" onClick={handleClose}>
                        <Icon className="text-black hover:text-red-500 text-xs" icon={faXmark} />
                    </button>
                </div>
            </div> }
        </>
    )
}

export default ErrorPopup