import {useTranslation, withTranslation} from "react-i18next";
import NavBar from "../components/NavBar";
import {H1, H2, H3, H4} from "../styled-components/Headings";
import React, {useEffect, useState} from "react";
import Api from "../Api";
import {useAuth} from "../providers/AuthProvider";
import Footer from "../components/Footer";
import AwaitFetch from "../middleware/AwaitFetch";
import {Badge as BadgeInterface, Batch} from "../types/entities";
import BatchSkeleton from "../components/Skeletons/BatchSkeleton";
import PersonalBatchList from "../components/PersonalBatchList";
import {i18nStringFromObject} from "../plugins/i18n";

function ProfilePage() {
    const { t } = useTranslation();
    const auth = useAuth()
    const [httpStatus, setHttpStatus] = useState(null)
    const [batches, setBatches] = useState<Batch[]>(null)

    useEffect(() => {
        Api.get('/users/me',
            ({data}) => {
                auth.setUser(data)
                setHttpStatus(200)
            },
            null,
            null,
            () => {
                setHttpStatus(500)
            })

        Api.get('users/me/batches',
            ({data}) => {
                setBatches(data);
            },
            null,
            null,
            null)
    }, [])

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [])

    return (
        <div className='min-h-screen'>
            <AwaitFetch httpStatus={httpStatus}>
                <NavBar />

                {/*Header*/}
                <div className='bg-primary-light'>
                    <div className='container mx-auto h-[175px] tablet:h-56 flex'>
                        <div className='bg-gray-500 my-[24px] w-[98px] tablet:w-auto tablet:w-48 tablet:my-0'>
                            <img className='object-cover w-full h-full' src={auth.user.profilePicture ? auth.user.profilePicture : '/assets/images/TTP-avatar.png'} alt="" />
                        </div>
                        <div className={"flex flex-col justify-between"}>
                            <div className='pt-6 pl-3 tablet:p-8'>
                                <H2 className="p-3 bg-white uppercase text-primary-light">{ auth.user.firstname } { auth.user.middleName } { auth.user.surname }</H2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'py-12 tablet:py-24 space-y-24'}>
                    <div className={"flex flex-col-reverse tablet:flex-col"}>
                        <div className={'space-y-7 tablet:container tablet:mx-auto tablet:mb-0'}>
                            <H2 className={'uppercase container mx-auto tablet:px-0 tablet:container-none tablet:mx-0'}>{ t('profile.my_contributions.title') }</H2>
                            <div className={'flex flex-col tablet:flex-row tablet:space-x-4 space-y-3 tablet:space-y-0'}>
                                <div className={'bg-gray-100 flex-1 px-4 py-5 flex items-end space-x-2.5'}>
                                    <H4>{auth.user.statistics.batchCount}</H4>
                                    <H4>{ t('profile.my_contributions.batch_participations') }</H4>
                                </div>
                                <div className={'bg-gray-100 flex-1 px-4 py-5 flex items-end space-x-2.5'}>
                                    <H4>{auth.user.statistics.photoCount}</H4>
                                    <H4>{ t('profile.my_contributions.photos_rated') }</H4>
                                </div>
                                <div className={'bg-gray-100 flex-1 px-4 py-5 flex items-end space-x-2.5'}>
                                    <H4>{auth.user.statistics.totalPoints}</H4>
                                    <H4>{ t('profile.my_contributions.points_earned') }</H4>
                                </div>
                            </div>
                        </div>

                        <div className={'space-y-7 mb-16 tablet:mt-16 container mx-auto'}>
                            <H2 className={'uppercase'}>{ t('profile.my_batches.title') }</H2>
                            <div className='space-y-8'>
                                { batches === null && <BatchSkeleton/>}
                                { batches && batches.length === 0 && <span className={'text-sm text-black/50'}>{ t('profile.my_batches.empty_placeholder') }</span>}
                                { batches && batches.length > 0 && <PersonalBatchList batches={batches} /> }
                            </div>
                        </div>
                    </div>

                    { auth.user.badges && <div className={'space-y-7'}>
                        <H2 className={'container mx-auto uppercase'}>{ t('profile.my_badges.title') }</H2>
                        <div className={'overflow-x-auto tablet:container tablet:mx-auto flex tablet:flex-wrap items-end'}>
                            { auth.user.badges.map(b => {
                                return <Badge badge={b}/>
                            }) }
                        </div>
                    </div> }
                </div>

                <Footer/>
            </AwaitFetch>
        </div>
        )
}

const Badge = ({ badge }: {badge: BadgeInterface}) => {
    const { t } = useTranslation();

    return (
        <div className={'relative left-[1rem] tablet:left-0 flex flex-col items-center space-y-2.5 mr-8 mb-6'}>
            <div className={`${badge.current ? 'min-w-32 min-h-32 max-w-32 max-h-32' : 'min-w-24 min-h-24 max-w-24 max-h-24'}`}>
                <img className={'w-full h-full object-cover'} src={badge.image ?? './assets/images/badge-disabled.png'} alt={ i18nStringFromObject(badge, 'title') }/>
            </div>
            <div className={'flex flex-col items-center'}>
                <span className={'font-mabry font-bold text-lg'}>{ i18nStringFromObject(badge, 'title') }</span>
                <span className={'font-light text-xs'}>{ badge.pointsNeeded } { t('general.entities.point.plural') }</span>
            </div>
        </div>
    )
}

export default withTranslation()(ProfilePage)

