import NavBar from "../components/NavBar";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../providers/AuthProvider";
import Api from "../Api";
import {H1, H2, H3} from "../styled-components/Headings";
import {useTranslation} from "react-i18next";
import AwaitFetch from "../middleware/AwaitFetch";
import {AxiosError} from "axios";
import BatchList from "../components/BatchList";

export const JoinGroup = (props) => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { t } = useTranslation();
    const [params, setParams] = useState<{[key: string]: string} | null>(null);
    const [group, setGroup] = useState(null)
    const [httpStatus, setHttpStatus] = useState(null)

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const paramsObj = Object.fromEntries(urlSearchParams.entries());
        setParams(paramsObj);
    }, [])

    useEffect(() => {
        if (params) {
            if ('g' in params) {
                if (user == null) {
                    navigate(`/login?return=${window.location.pathname}${window.location.search}`)
                } else {
                    doJoin();
                }
            } else {
                navigate('/');
            }
        }
    }, [params])

    const doJoin = () => {
        Api.post('/joinGroup',
            { token: params.g },
            ({data}) => {
                setGroup(data)
                setHttpStatus(200)
            },
            null,
            null,
            (error: AxiosError) => {
                setHttpStatus(error.response.status)
            }
        )
    }

    return ( <AwaitFetch httpStatus={httpStatus} >
        { group && <div className='min-h-screen bg-gray-300'>
                <NavBar />
                <div className='h-[270px] w-full bg-gray-500'>
                    <div className='container mx-auto h-full relative'>
                        <H1 className='uppercase bg-white inline-block p-2 absolute bottom-16 text-primary-light'>{t('pages.join.title')}</H1>
                    </div>
                </div>

                <div className='transform -translate-y-12'>
                    <div className='container mx-auto space-y-4'>
                        <div className='min-h-[512px] flex'>
                            <div className='w-5/12 bg-primary-light p-8 md:p-12 lg:p-20 space-y-6'>
                                <H3 className='uppercase text-white'>{t('pages.join.subtitle', { groupName: group.groupName })}</H3>
                                <p className='text-white'>
                                    Welkom! We hebben je toegevoegd aan deze privégroep. Hiermee krijg je toegang tot extra batches.
                                </p>
                            </div>
                            <div className='w-7/12 bg-white p-8 md:p-12 lg:p-20'>
                                <H2>{ t('pages.join.batch_title') }</H2>
                                <div className='mt-6 space-y-8'>
                                    {group.batches && group.batches.length > 0 && <BatchList batches={group.batches}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> }
    </AwaitFetch>
    )
}