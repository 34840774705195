import i18n from "i18next";

export class HelperFunctions {

    static getDateFormat(date, options) {
        let dateString = date;
        if(date && date.length > 0) {
            const result = date.split(" ")
            //Fixes crash on Safari. This fix places a 'T' between each date part, so '2020-10-06 08:41:59' becomes '2020-10-06T08:41:59'
            if(result.length === 2) {
                dateString = result.join("T");
            }
        }

        const dateObj = new Date(dateString);
        const dateTimeFormat = new Intl.DateTimeFormat(i18n.language, options);
        const f_date = (m_ca, m_it) => Object({...m_ca, [m_it.type]: m_it.value});
        return dateTimeFormat.formatToParts(dateObj).reduce(f_date, {});
    }
}