import {withTranslation, WithTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Api from "../Api";
import styled from "styled-components";
import tw from "tailwind-styled-components";
import {Button} from "../styled-components/Button";
import {useGlobalState} from "../utils/GlobalState";
import {i18nStringFromObject} from "../plugins/i18n";

function Footer({t, i18n}: WithTranslation) {
    const navigate = useNavigate();
    const [projects, setProjects] = useState([])
    const [showPopup, setShowPopup] = useGlobalState('showPopup', false)

    useEffect(() => {
        Api.get('/projectsSummary',
            ({ data }) => {
                setProjects(data.items)
            })
    }, [])


    return (
        <div className={"bg-black-footer py-[50px]"}>
            <div className={'container mx-auto flex flex-col tablet-landscape:pt-14 tablet-landscape:grid tablet-landscape:grid-cols-12 gap-5'}>
                <div className='h-auto w-[282px]' onClick={() => navigate(`/home`)}>
                    <img src={'/assets/images/logo-footer.svg'} />
                </div>
                <ul className={'tablet-landscape:col-start-7 tablet-landscape:col-end-9 text-white mt-[60px] tablet-landscape:mt-0'}>
                    <FooterTitle className={'mb-[15px] footer-title'}>{ t('navbar_and_footer.footer.redirect_to') }</FooterTitle>
                    <div className={'space-y-4 font-mabry'}>
                        <FooterBullet><Link to='/home'>{ t('navbar_and_footer.links.home') }</Link></FooterBullet>
                        { projects.length === 1 ? projects.map((project, key) => {
                            return  <FooterBullet key={key}>
                                        <Link to={`/project/${project.id}`}>
                                            { i18nStringFromObject(project, 'title') }
                                        </Link>
                                    </FooterBullet>
                        }) : <FooterBullet><Link to='/'>{ t('navbar_and_footer.links.tagging') }</Link></FooterBullet>}

                        <FooterBullet><Link to='/how-this-works'>{ t('navbar_and_footer.links.how_it_works') }</Link></FooterBullet>
                        <FooterBullet><Link to='/about'>{ t('navbar_and_footer.links.about') }</Link></FooterBullet>
                    </div>
                </ul>

                <ul className={'tablet-landscape:col-start-9 tablet-landscape:col-end-11 text-white mt-[60px] tablet-landscape:mt-0'}>
                    <FooterTitle className={'mb-[15px] footer-title'}>{ t('navbar_and_footer.footer.privacy') }</FooterTitle>
                    <div className={'space-y-4'}>
                        <FooterBullet><Link to='/terms'>{ t('navbar_and_footer.links.terms_of_use') }</Link></FooterBullet>
                        <FooterBullet><Link to='/colofon'>{ t('navbar_and_footer.links.colophon') }</Link></FooterBullet>
                        <FooterBullet><Link to='/contact'>{ t('navbar_and_footer.links.contact') }</Link></FooterBullet>
                        <FooterBullet className={"cursor-pointer"} onClick={() => setShowPopup(true)}>{ t('navbar_and_footer.links.cookies') }</FooterBullet>
                    </div>
                </ul>

                <ul className={'tablet-landscape:col-start-11 tablet-landscape:col-end-13 flex flex-row tablet-landscape:flex-col justify-between tablet-landscape:justify-start text-white mt-[60px] tablet-landscape:mt-0'}>
                    <FooterTitle className={'mb-[15px] footer-title'}>{ t('navbar_and_footer.footer.follow_us') }</FooterTitle>
                    <div className={"flex flex-row space-x-4"}>
                            <a className="min-h-[25px] min-w-[25px]" href="https://www.linkedin.com/showcase/wereldmuseum-amsterdam/" target={"_blank"}>
                                <img src="../assets/icons/linkedin-light-icon.svg" alt="Linkedin button"/>
                            </a>
                            <a className="min-h-[25px] min-w-[25px]" href="https://twitter.com/WM_Amsterdam" target={"_blank"}>
                                <img src="/assets/icons/twitter-light-icon.svg" alt="Twitter button"/>
                            </a>
                            <a className="min-h-[25px] min-w-[25px]" href="https://www.facebook.com/wereldmuseum.amsterdam/" target={"_blank"}>
                                <img src="/assets/icons/facebook-light-icon.svg" alt="Facebook button"/>
                            </a>
                            <a className="min-h-[25px] min-w-[25px]" href="https://www.instagram.com/wereldmuseum.amsterdam/" target={"_blank"}>
                                <img src="/assets/icons/instagram-light-icon.svg" alt="Instagram button"/>
                            </a>
                            <a className="min-h-[25px] min-w-[25px]" href="https://www.youtube.com/user/Tropenmuseum" target={"_blank"}>
                                <img src="/assets/icons/youtube-light-icon.svg" alt="Youtube button"/>
                            </a>
                    </div>
                </ul>

                <div className={'flex tablet-landscape:mt-[35px] text-white space-x-4 col-span-full'}>
                    <p className="font-bl-pylon text-sm">Copyright &copy; {new Date().getFullYear()} Wereldmuseum Amsterdam</p>
                </div>
            </div>
        </div>
    )
}

const FooterBullet = tw.li`
    @apply font-bl-pylon font-medium underline text-[sm leading-[26px]
    tablet-landscape:font-normal tablet-landscape:leading-4 tablet-landscape:no-underline
`;

const FooterTitle = tw.li`
    @apply font-mabry font-bold text-xl leading-6
    tablet-landscape:leading-5
`;

export default withTranslation()(Footer)