import {useNavigate} from "react-router-dom";
import {useAuth} from "../providers/AuthProvider";
import {useEffect, useState} from "react";
import Spinner from "../components/Spinner";
import AppStorage, {StorageKey} from "../AppStorage";

export default function RequireAuth({ children }: { children: JSX.Element }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const auth = useAuth();

    useEffect(() => {
        if (AppStorage.get(StorageKey.API_ACCESS_TOKEN) && AppStorage.get(StorageKey.USER_ID)) {
            if (!auth.user) {
                auth.validateLogin();
            } else {
                setLoading(false)
            }
        } else {
            setLoading(false)
        }
    }, [auth.user])


    const navigateToLogin = () => {
        const params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
        if ('return' in params) {
            navigate(`/login?return=${params.return}`)
        } else {
            navigate(`/login?return=${window.location.pathname}${window.location.search}`)
        }
    }


    if (!loading) {
        if (!auth.user) {
            navigateToLogin()
            return <></>
        }

        return children
    } else {
        return <div className='w-screen h-screen flex items-center justify-center'>
            <Spinner className='w-24 h-24' />
        </div>
    }
}