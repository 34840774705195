interface CheckboxProps {
    className?: string | object
    checked?: boolean
}

export default function Checkbox({className, checked}: CheckboxProps) {

    return (
        <div className={`bg-white border border-gray-500 rounded-full h-4.5 w-4.5 flex items-center justify-center ${className}`}>
            {checked ? <div className='bg-primary-light rounded-full w-2.5 h-2.5' /> : null }
        </div>
    )
}