import {Batch} from "../types/entities";
import {useAuth} from "../providers/AuthProvider";
import {useTranslation} from "react-i18next";
import {Tag} from "../styled-components/Tag";
import {H4, H5, SUBTITLE} from "../styled-components/Headings";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "../styled-components/Button";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import Icon from "./Icon";
import Dropdown from "./Dropdown";
import {TabletWidth} from "../constants/ScreenWidth";
import useWindowDimensions from "../hooks/useWindowDimensions";
import React, {useEffect, useState} from "react";
import Popup from "./Popup";
import {isMobile, isTablet} from "react-device-detect";
import {i18nStringFromObject} from "../plugins/i18n";

const BatchList = ({batches, isDemo}: { batches: Batch[], isDemo?: boolean }) => {
    const {i18n, t} = useTranslation()
    const {width} = useWindowDimensions()

    const shouldShowBatchPrivatePublicState = (): boolean => {
        return batches.some(b => b.isPrivate);
    }

    return <>{batches.sort((a, b) => {
            if (a.status === "Running" && b.status !== "Running") {
                return -1;
            } else if (a.status !== "Running" && b.status === "Running") {
                return 1;
            }

            return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
        }).map((batch, key) => {
        return <div key={key} className={"flex flex-col"}>
            <div className="flex min-h-[8rem] border border-gray-500">
                <div className='bg-gray-500 w-3/4 tablet:w-auto min-w-auto tablet:min-w-[180px] relative'>
                    <img className='absolute top-0 left-0 w-full h-full object-cover' src={batch.thumbnail ? batch.thumbnail : `../assets/images/batch-placeholder.png`} alt=""/>
                    <Tag className='px-2.5 relative z-[2]'>{t(`project.batches.status.${batch.status?.toLowerCase()}`)}</Tag>
                </div>
                <div className='flex flex-col w-full'>
                    <div className='flex-1 flex flex-col items-start justify-center pt-6 px-3 tablet:px-6'>
                        <div className='flex items-center space-x-5'>
                            <div className={"flex space-x-3"}>
                                <H4>{ i18nStringFromObject(batch, 'title') }</H4>
                            </div>
                            { shouldShowBatchPrivatePublicState() && <>
                                <div className='h-3/5 w-[1px] bg-black'/>
                                <H5>{ batch.isPrivate ? t('project.batches.access.private') : t('project.batches.access.public')}</H5>
                            </>}
                        </div>
                        <p className={"font-mabry !font-normal text-[14px] !tablet-landscape:text-xs"}>{ batch.imageCount } {t('general.entities.photo.plural')}</p>
                        {width > TabletWidth && <StartBatchButton batch={batch} isDemo={isDemo} />}
                    </div>
                    <div className='flex flex-col'>
                        { batch.progress !== null && <p className='self-end pr-1.5 pb-1.5'>{ batch.progress }% { t('project.statistics.finished') }</p> }
                        <div className={`w-full h-4 ${batch.status === 'Upcoming' ? 'bg-gray-500/20' : 'bg-gray-500'}`}>
                            <div className='bg-primary-light h-full' style={{width: `${batch.progress}%`}} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={"relative bottom-3"}>
                {window.innerWidth <= TabletWidth && <StartBatchButton batch={batch} isDemo={isDemo} />}
            </div>
        </div>

    })}</>
}

const StartBatchButton = ({ batch, isDemo }: { batch: Batch, isDemo: boolean,  }) => {
    const { user } = useAuth();
    const { t } = useTranslation();
    const [displayWarningPopup, setDisplayWarningPopup] = useState(false);
    const [url, setUrl] = useState('');
    const navigate = useNavigate()
    const auth = useAuth()

    useEffect(() => {
        if (displayWarningPopup) {
            document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        } else {
            document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        }
    }, [displayWarningPopup]);

    const startDisabled = (): boolean => {
        if (isDemo) {
            return false;
        } else {
            if (batch.status !== 'Running') {
                return true;
            }

            if (auth.user) {
                if (user?.roles?.length) {
                    if (batch.canStart.length > 0) {
                        return false;
                    }
                } else {
                    if (batch.canStart.includes('annotate')) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    if ((!isDemo && user && user.roles && user.roles.length)) {
        return (
            <>
                <div className='w-auto mt-5 mb-2'>
                    <Dropdown>
                        <Button $color={'light-primary'} disabled={startDisabled()} $small className=' px-6 w-full !pr-5'>
                            <div className={"mx-auto items-center space-x-4"}>
                                <span>{batch.participated ? t('general.actions.continue') : t('general.actions.start')}</span>
                                <Icon icon={faChevronDown} />
                            </div>
                        </Button>
                        { batch.canStart.length > 0 &&
                            <div className={'bg-white flex flex-col'}>
                                <>
                                    <Link
                                        to={`/${batch.type.toLowerCase()}/${batch.phase === 'Fase 1' ? 'annotate' : 'validate'}/${batch.id}`}
                                        className={`text-center text-xs flex items-center px-4 py-3 ${!batch.canStart.includes('annotate') && 'pointer-events-none text-gray-400'}`}>
                                        {t("project.batches.steps.step_1")}
                                    </Link>
                                </>
                                { user.roles.includes('controleur') &&
                                    <>
                                        <Link
                                            to={`/${batch.type.toLowerCase()}/check/${batch.id}`}
                                            className={`text-center text-xs flex items-center px-4 py-3 ${!batch.canStart.includes('check') && 'pointer-events-none text-gray-400'}`}>
                                            {t("project.batches.steps.step_2")}
                                        </Link>
                                    </>
                                }
                                { user.roles.includes('next_level_controleur') &&
                                    <>
                                        <Link
                                            to={`/${batch.type.toLowerCase()}/next-level-check/${batch.id}`}
                                            className={`text-center text-xs flex items-center px-4 py-3 ${!batch.canStart.includes('next-level-check') && 'pointer-events-none text-gray-400'}`}>
                                            {t("project.batches.steps.step_3")}
                                        </Link>
                                    </>
                                }
                            </div>
                        }
                    </Dropdown>
                </div>
            </>
        )
    }

    return (
        <>
            <>
                { isDemo ?
                    <div className='mt-5 mb-2'>
                        <Link to={`/demo/${batch.type.toLowerCase()}/${batch.phase === 'Fase 1' ? 'annotate' : 'validate'}/${batch.id}`}>
                            <Button disabled={startDisabled()} $color={'light-primary'} $small className='px-6 mx-auto w-full justify-center'>{
                                batch.participated ? t('general.actions.continue') : t('general.actions.start')
                            }</Button>
                        </Link>
                    </div>
                    :
                    (auth.user &&
                    <div className='mt-5 mb-2'>
                        <Link to={`/${batch.type.toLowerCase()}/${batch.phase === 'Fase 1' ? 'annotate' : 'validate'}/${batch.id}`}>
                            <Button disabled={startDisabled()} $color={'light-primary'} $small className='px-6 mx-auto w-full justify-center'>{
                                batch.participated ? t('general.actions.continue') : t('general.actions.start')
                            }</Button>
                        </Link>
                    </div>
                    )
                }
            </>
        </>
    )
}

export default BatchList