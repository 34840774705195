import {WithTranslation, withTranslation} from "react-i18next";
import {Button} from "../styled-components/Button";
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../providers/AuthProvider";
import Dropdown from "./Dropdown";
import Icon from "./Icon";
import {faBars, faChevronDown, faChevronRight, faPlus, faChevronLeft, faXmark, faUser} from "@fortawesome/free-solid-svg-icons";
import LanguageSwitcher from "./LanguageSwitcher";
import React, {useEffect, useState} from "react";
import Api from "../Api";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Collapse from "./Collapse";
import {LaptopWidth} from "../constants/ScreenWidth";
import {i18nStringFromObject} from "../plugins/i18n";

function NavBar({t, i18n}: WithTranslation) {
    const auth = useAuth();
    const navigate = useNavigate();
    const [projects, setProjects] = useState([])
    const [openSideMenu, setOpenSideMenu] = useState(false)
    const [openExtraMenu, setOpenExtraMenu] = useState(false)
    const [showNav, setShowNav] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const {width} = useWindowDimensions()

    useEffect(() => {
        Api.get('/projectsSummary',
            ({ data }) => {
                setProjects(data.items)
            })
    }, [])

    const controlNavbar = () => {
        if (typeof window !== 'undefined') {
            if (window.scrollY > lastScrollY) {
                setShowNav(false);
            } else {
                setShowNav(true);
            }

            setLastScrollY(window.scrollY);
        }
    };

    useEffect(() => {
        if (openSideMenu) {
            document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        } else {
            document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        }
    }, [openSideMenu]);


    useEffect(() => {

        window.addEventListener('scroll', controlNavbar);

        return () => {
            window.removeEventListener('scroll', controlNavbar);
        }
    }, []);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlNavbar);

            // cleanup function
            return () => {
                window.removeEventListener('scroll', controlNavbar);
            };
        }
    }, [lastScrollY]);

    const navigateToUrlWithParams = (path: string) => {
        const params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
        navigate(`${path}${params.return ? `?return=${params.return}` : ''}`)
    }

    const desktopMenu = () => {
        return <div>
            <div className='h-[94px] w-full'/>
            <div
                className={`z-[99] h-[94px] bg-white shadow-lg transition-all w-full flex items-center fixed top-0`}>
                <div className='container mx-auto flex justify-between items-center'>
                    <div className='h-12 cursor-pointer' onClick={() => navigate(`/home`)}>
                        <img className='h-full' src={'/assets/images/TTP_Header.svg'}/>
                    </div>
                    <div className='flex flex-1'>
                        <div className='flex flex-1 justify-center space-x-1 lg:space-x-4'>
                            <Dropdown hoverable>
                                <Button $text="black">{ t('navbar_and_footer.links.tagging') }</Button>
                                <ul className={`space-y-4 p-4 bg-white text-black`}>
                                    {projects.map((project, key) => {
                                        return <li key={key}>
                                            <Link to={`/project/${project.id}`}
                                                  className='whitespace-nowrap font-bold text-sm'>
                                                { i18nStringFromObject(project, 'title') }
                                            </Link>
                                        </li>
                                    })}
                                </ul>
                            </Dropdown>
                            <Button onClick={() => navigateToUrlWithParams(`/how-this-works`)}
                                    $text="black">{ t('navbar_and_footer.links.how_it_works') }</Button>
                            <Button onClick={() => navigateToUrlWithParams(`/about`)}
                                    $text="black">{ t('navbar_and_footer.links.about') }</Button>

                        </div>

                        <div className='flex space-x-4'>
                            <div>
                                <LanguageSwitcher type="light"/>
                            </div>
                            {!auth.user ? <span onClick={() => navigateToUrlWithParams('/login')}>
                            <Button $color="light-white">{t('general.actions.login')}</Button>
                        </span> : null}
                            {!auth.user ? <span onClick={() => navigateToUrlWithParams('/register')}>
                            <Button $color='light-primary'>{t('general.actions.create_account')}</Button>
                        </span> : null}
                            {auth.user ? <Dropdown>
                                <Button $text="black" $color='light-white'
                                        className='pl-4 pr-3'>
                                    <div className='w-6 h-6 mr-4'>
                                        <img className='object-cover w-full h-full' src={auth.user.profilePicture ? auth.user.profilePicture : '/assets/images/TTP-avatar.png'}
                                             alt=""/>
                                    </div>
                                    <span
                                        className={`text-[15px] mr-12 text-black`}>{auth.user.firstname} {auth.user.middleName} {auth.user.surname}</span>
                                    <Icon icon={faChevronDown} className="text-black"/>
                                </Button>
                                <ul className="bg-white text-black">
                                    <li>
                                        <Link className='block pl-7 py-4 font-bold text-[15px]'
                                              to='/profile'>{ t('navbar_and_footer.profile_dropdown.profile') }</Link>
                                    </li>
                                    <li>
                                        <Link className='block pl-7 py-4 font-bold text-[15px]'
                                              to='/profile/settings'>{ t('navbar_and_footer.profile_dropdown.settings') }</Link>
                                    </li>
                                    <li>
                                        <Link className='block pl-7 py-4 font-bold text-[15px]' onClick={auth.logout}
                                              to='/login'>{ t('navbar_and_footer.profile_dropdown.log_out') }</Link>
                                    </li>
                                </ul>
                            </Dropdown> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const mobileTabletMenu = () => {

        return (
            <>
                <div className={'h-[94px] w-full'} />
                <div className={`z-[99] h-[94px] transition-all duration-500 ease-in-out ${!showNav && window.scrollY > 94 ? 'opacity-0' : 'opacity-100'} bg-white shadow-lg w-full flex items-center fixed top-0`}>
                    <div className='container mx-auto flex justify-between items-center'>
                        <div className='h-12' onClick={() => navigate(`/home`)}>
                            <img className='h-full' src={'/assets/images/TTP_Header.svg'} />
                        </div>
                        <div className={"flex flex-row space-x-4"}>
                            { auth.user &&
                                <a href="/profile">
                                    <Icon className={`h-6 w-6 text-white`} icon={faUser}/>
                                </a>
                            }
                            <div onClick={() => setOpenSideMenu(!openSideMenu)}><Icon className={`h-6 w-6 text-black`} icon={faBars}/></div>
                        </div>
                    </div>

                    {/*Uitgeklapt menu*/}
                    <div className={`h-screen w-full bg-gray-200 shadow-lg absolute transition-all duration-500 ease-in-out top-0 z-[99] ${openSideMenu ? 'right-0' : 'right-[-100%]'}`}>
                        <div className={"h-[94px] flex flex-row justify-between container mx-auto items-center"}>
                            <div className='h-12' onClick={() => navigate(`/home`)}>
                                <img className='h-full' src={'/assets/images/TTP_Header.svg'} />
                            </div>
                            <div className={'flex items-center'}>
                                <div>
                                    <LanguageSwitcher type='light'/>
                                </div>
                                <div onClick={() => setOpenSideMenu(!openSideMenu)}>
                                    <Icon className={`h-6 w-6 text-black`} icon={faXmark}/>
                                </div>
                            </div>
                        </div>
                        <div className={"flex flex-col h-full"} style={{padding: "env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"}}>
                            <div className={"flex flex-col"}>
                                <Collapse navClassName={"container mx-auto"} icon={width < 1024 ? faChevronDown : faPlus} color={`text-black`} className={`py-3 bg-white border-gray-500 border-b w-full`}>
                                    <div className={`font-mabry font-semibold text-[15px] text-black`}>{ t('navbar_and_footer.links.tagging') }</div>
                                    <div className="space-y-2">
                                        { projects.map((project, key) => {
                                            return (
                                                <div key={key}>
                                                    <a href={`/project/${project.id}`} className={`whitespace-nowrap font-mabry font-medium text-[15px] w-fit text-black py-1`} >
                                                        { i18nStringFromObject(project, 'title') }
                                                    </a>
                                                </div>
                                            )
                                        }) }
                                    </div>
                                </Collapse>
                                <a href={"/how-this-works"} className={`py-6 bg-white border-gray-500 border-b`}>
                                    <div className={"container mx-auto select-none flex cursor-pointer justify-between items-center"}>
                                        <div className={`font-mabry font-semibold text-[15px] text-black`}>{ t('navbar_and_footer.links.how_it_works') }</div>
                                        <Icon className={`text-black`} icon={faChevronRight} />
                                    </div>
                                </a>
                                <a href={"/about"} className={`py-6 bg-white border-gray-500 border-b`}>
                                    <div className={"container mx-auto select-none flex cursor-pointer justify-between items-center"}>
                                        <div className={`font-mabry font-semibold text-[15px] text-black`}>{ t('navbar_and_footer.links.about') }</div>
                                        <Icon className={`text-black`} icon={faChevronRight} />
                                    </div>
                                </a>
                            </div>
                            <div className={`container mx-auto text-black flex flex-col mt-[37px] space-y-5`}>
                                <a href={"/terms"} className={"font-mabry font-regular text-[15px] w-fit underline"}>{ t('navbar_and_footer.links.terms_of_use') }</a>
                                <a href={"/colofon"} className={"font-mabry font-regular text-[15px] w-fit underline"}>{ t('navbar_and_footer.links.colophon') }</a>
                                <a href={"/contact"} className={"font-mabry font-regular text-[15px] w-fit underline"}>{ t('navbar_and_footer.links.contact') }</a>
                            </div>
                            <div onClick={() => { auth.user ? setOpenExtraMenu(!openExtraMenu) : navigate("/login") }} className={`w-full fixed bottom-0 h-[75px] text-black bg-white`}>
                                <div className={"flex flex-row justify-between items-center h-full container mx-auto"}>
                                    { auth.user ?
                                        <div onClick={() => setOpenExtraMenu(!openExtraMenu)} className={"flex space-x-4"}>
                                            <img className='object-cover w-[38px] h-[34px]' src={auth.user.profilePicture ? auth.user.profilePicture : '/assets/images/TTP-avatar.png'} alt=""/>
                                            <div className={"flex flex-col"}>
                                                <p className={"text-[15px] font-mabry font-bold"}>{auth.user.firstname}</p>
                                                <p className={"font-mabry text-[12px]"}>{ t('navbar_and_footer.profile_dropdown.account') }</p>
                                            </div>
                                        </div>
                                        :
                                        <div className={"font-mabry font-semibold text-[15px]"}>{t('general.actions.login')}</div>
                                    }
                                    <Icon className={`text-black mr-[0.35rem]`} icon={faChevronRight} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Extra menu */}
                    <div style={{padding: "env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"}} className={`h-screen w-full bg-gray-200 shadow-lg absolute transition-all duration-500 ease-in-out top-0 z-[99] ${openExtraMenu ? 'right-0' : 'right-[-100%]'}`}>
                        <div className={"h-[94px] flex flex-row justify-between container mx-auto items-center"}>
                            <div className='h-12 flex flex-row space-x-2 items-center justify-center' onClick={() => setOpenExtraMenu(!openExtraMenu)}>
                                <Icon className={`h-4 w-4 text-black`} icon={faChevronLeft} /><p className={`underline text-black`}>{ t('general.actions.back') }</p>
                            </div>
                            <div onClick={() => { setOpenSideMenu(!openSideMenu); setOpenExtraMenu(!openExtraMenu); }}>
                                <Icon className={`h-6 w-6 text-black`} icon={faXmark}/>
                            </div>
                        </div>
                        <div className={"flex flex-col"}>
                            <a href={"/profile"} className={`py-6 bg-white border-gray-500 border-b`}>
                                <div className={"container mx-auto select-none flex cursor-pointer justify-between items-center"}>
                                    <div className={`font-mabry font-semibold text-[15px] text-black`}>{ t('navbar_and_footer.profile_dropdown.profile') }</div>
                                    <Icon className={`text-black`} icon={faChevronRight} />
                                </div>
                            </a>
                            <a href={"/profile/settings"} className={`py-6 bg-white border-gray-500 border-b`}>
                                <div className={"container mx-auto select-none flex cursor-pointer justify-between items-center"}>
                                    <div className={`font-mabry font-semibold text-[15px] text-black`}>{ t('navbar_and_footer.profile_dropdown.settings') }</div>
                                    <Icon className={`text-black`} icon={faChevronRight} />
                                </div>
                            </a>
                        </div>
                        <div onClick={() => { auth.logout(); navigate("/login") }} className={`fixed bottom-0 h-[75px] w-full border-b text-black bg-white border-gray-500`}>
                            <div className={"container mx-auto flex flex-row h-full items-center justify-between"}>
                                <div className={"font-mabry font-semibold text-[15px]"}>{ t('navbar_and_footer.profile_dropdown.log_out') }</div>
                                <Icon className={`text-black mr-[0.35rem]`} icon={faChevronRight} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {  window.innerWidth <= LaptopWidth ?
                mobileTabletMenu() :
                desktopMenu()
            }
        </>
    )
}

export default withTranslation()(NavBar)