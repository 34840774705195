import {WithTranslation, withTranslation} from "react-i18next";
import {H1, H2, H3, H5} from "../styled-components/Headings";
import Collapse from "../components/Collapse";
import "../css/Progressbar.css";
import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Api from "../Api";
import {AxiosError} from "axios";
import AwaitFetch from "../middleware/AwaitFetch";
import {Batch, Comment, Paragraph, Project} from "../types/entities";
import SanitizeHTML from "../components/SanitizeHTML";
import {Tag} from "../styled-components/Tag";
import BatchSkeleton from "../components/Skeletons/BatchSkeleton";
import {CommentInput} from "../components/Comment/CommentInput";
import {CommentTree} from "../components/Comment/CommentTree";
import Footer from "../components/Footer";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import Donut from "../components/Donut";
import BatchList from "../components/BatchList";
import {SuffixButton} from "../components/Buttons";
import {faArrowRight, faChevronDown, faPlus} from "@fortawesome/free-solid-svg-icons";
import Icon from "../components/Icon";
import NavBar from "../components/NavBar";
import {Button} from "../styled-components/Button";
import ParticipationBlock from "../components/ParticipationBlock";
import {i18nStringFromObject} from "../plugins/i18n";

function ProjectPage({t, i18n}: WithTranslation) {
    const { id } = useParams()
    const [httpStatus, setHttpStatus] = useState(null)
    const [project, setProject] = useState<Project>(null)
    const [batches, setBatches] = useState<Batch[]>([])
    const [paragraphs, setParagraphs] = useState<Paragraph[]>([])
    const [comments, setComments] = useState<Comment[]>([])
    const [commentsPageSize] = useState<number>(3);
    const [commentPageNumber, setCommentsPageNumber] = useState<number>(1);
    const [showCommentsShowMoreButton, setShowCommentsShowMoreButton] = useState<boolean>(true);
    const [demoProjectExists, setDemoProjectExists] = useState(false)
    const [invalidLanguage, setInvalidLanguage] = useState<Boolean>(false);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [])

    useEffect(() => {
        setInvalidLanguage(false)
        if (project && project.languages) {
            if (!project.languages.includes(i18n.language)) {
                setInvalidLanguage(true);
            }
        }
    }, [project, i18n.language])

    useEffect(() => {
        setHttpStatus(null);
        Api.get(`/projects/${id}`,
            ({data}) => {
                setProject(data)
                setHttpStatus(200)
            },
            null,
            null,
            (error: AxiosError) => {
                setHttpStatus(error.response.status)
            })

        Api.get(`/projects/${id}/batches`,
            ({data}) => {
                setBatches(data.items)
            },
        )

        Api.get(`/projects/${id}/paragraphs`,
            ({data}) => {
                setParagraphs(data.items)
            },
        )

        Api.get('/projects/demo',
            () => {
                setDemoProjectExists(true);
            })

        getNextComments();
    },[id])

    const getNextComments = () => {
        Api.get(`/projects/${id}/comments?page[size]=${commentsPageSize}&page[number]=${commentPageNumber}`,
            ({data}) => {
                const newComments = [...comments, ...data.items];
                if (commentPageNumber > 0 ) {
                    setComments(newComments)
                } else {
                    setComments(data.items)
                }

                if (newComments.length >= data.count || data.items === null || data.items.length === 0) {
                    setShowCommentsShowMoreButton(false)
                }

                setCommentsPageNumber(commentPageNumber + 1);
            })
    }

    const addComment = (comment: Comment) => {
        const commentsCopy = Object.assign([], comments)
        commentsCopy.unshift(comment);
        setComments(commentsCopy);
    }

    const deleteComment = (comment: Comment) => {
        let commentsCopy = Object.assign([], comments)
        const commentIndex = commentsCopy.findIndex(c => c.id === comment.id);

        commentsCopy[commentIndex] = comment;

        setComments(commentsCopy);
    }

    const CommentList = () => {
        return (
            <div className={"container mx-auto tablet-landscape:no-container tablet-landscape:mx-0 min-h-full"}>
                <H2 className='uppercase mb-8'>{ t('project.comments.title') }</H2>
                <div className='space-y-10'>
                    <CommentInput parent={project.id} type={'project'} onComment={addComment}/>
                    <CommentTree comments={comments} onDelete={deleteComment}/>
                </div>
                { showCommentsShowMoreButton && <div className={'flex justify-center'}>
                    <Button $color='light-white' onClick={() => getNextComments()}>{ t('general.actions.show_more') }</Button>
                </div> }
            </div>
        )
    }

    const MobileProjectPage = () => {
        return (
            <div className='flex tablet-landscape:hidden flex-col bg-gray-200'>
                <div className={"container relative space-y-3 bottom-8 mx-auto"}>
                    <div className='w-full min-h-72 bg-primary-light flex flex-col p-8 space-y-4'>
                        <H2 className='text-white uppercase'>{ t('project.statistics.title') }</H2>
                        <div className='flex flex-col space-y-5'>
                            <div className='w-3/4 mx-auto'>
                                <Donut percentage={Math.round(project.statistics.totalPhotosFinished / project.statistics.totalPhotoCount * 100)}>
                                    <div className={'flex flex-col items-center'}>
                                        <p className={"font-mabry text-[20px] font-bold text-white"}>
                                            {Math.round(project.statistics.totalPhotosFinished / project.statistics.totalPhotoCount * 100)}%
                                        </p>
                                        <p className={"font-mabry text-[12px] font-light text-white"}>
                                            { t('project.statistics.finished') }
                                        </p>
                                    </div>
                                </Donut>
                            </div>

                            <div className='text-white space-y-3'>
                                <div className={"flex flex-col"}>
                                    <p className={'font-mabry text-sm text-white'}>{ t('project.statistics.total_amount_of_photos_in_collection') }</p>
                                    <H2 className={'text-white'}>{ project.statistics.totalPhotoCount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }</H2>
                                </div>
                                <div className={"flex flex-col"}>
                                    <p className={'font-mabry text-sm text-white'}>{ t('project.statistics.total_amount_finished') }</p>
                                    <H2 className={'text-white'}>{ project.statistics.totalPhotosFinished.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }</H2>
                                </div>
                                <div className={"flex flex-col"}>
                                    <p className={'font-mabry text-sm text-white'}>{ t('project.statistics.total_amount_active_participants') }</p>
                                    <H2 className={'text-white'}>{ project.statistics.totalActiveParticipants.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }</H2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"flex flex-row" }>
                        <p className={"font-semibold"}>{ t('project.share.title') }</p>
                        <div className={"space-x-2 ml-2"}>
                            <LinkedinShareButton
                                url={`${window.location.href}`}
                                // url={`https://www.google.com`}
                            >
                                <img src="/assets/icons/linkedin-dark-icon.svg" alt="Facebook share button"/>
                            </LinkedinShareButton>
                            <TwitterShareButton
                                url={`${window.location.href}`}
                            >
                                <img src="/assets/icons/twitter-dark-icon.svg" alt="Facebook share button"/>
                            </TwitterShareButton>
                            <FacebookShareButton
                                url={`${window.location.href}`}
                            >
                                <img src="/assets/icons/facebook-dark-icon.svg" alt="Facebook share button"/>
                            </FacebookShareButton>
                        </div>
                    </div>
                </div>

                <div className='py-[50px] space-y-[25px] container mx-auto'>
                    <H2>{t('project.about.title')?.toUpperCase()}</H2>
                    <SanitizeHTML>{ i18nStringFromObject(project, 'about') }</SanitizeHTML>
                </div>

                <div className='mb-[120px] space-y-1'>
                    {paragraphs.map((paragraph, key) => {
                        return <Collapse icon={window.screen.width < 1024 ? faChevronDown : faPlus} key={key} className='container mx-auto bg-white border-b border-gray-500'>
                            <H5>{ i18nStringFromObject(paragraph, 'title') }</H5>
                            <SanitizeHTML>{ i18nStringFromObject(paragraph, 'text') }</SanitizeHTML>
                        </Collapse>
                    })}
                </div>

                <div className={"container mx-auto mb-[120px]"}>
                    <div className={'flex flex-col space-y-[40px] tablet-landscape:items-center'}>
                        <div className={"space-y-[25px]"}>
                            <H3>{t('project.how_does_it_work.title')?.toUpperCase()}</H3>
                            <SanitizeHTML>{ i18nStringFromObject(project, 'howto') }</SanitizeHTML>
                        </div>

                        { !project.isDemo && demoProjectExists && <Link to={'/project/demo'}>
                            <Button className={"w-full justify-center tablet:w-auto "} $color='light-primary' >
                                Oefenen
                            </Button>
                        </Link>}
                    </div>
                </div>

                <div className={"container mx-auto mb-[120px]"}>
                    <H2 className='uppercase mb-8'>{ t('project.batches.title') }</H2>
                    <div className='space-y-[70px] tablet-landscape:space-y-1.5'>
                        {batches.length === 0 && <BatchSkeleton/>}
                        {batches && batches.length > 0 && <BatchList batches={batches} isDemo={project.isDemo}/>}
                    </div>
                </div>

                {!project.isDemo && <CommentList />}
                {/*<ParticipationBlock message={''} button1={{text:''}} button2={{text:''}} />*/}
            </div>
        )
    }

    const DesktopProjectPage = () => {
        return (
            <div className='hidden tablet-landscape:flex container mx-auto  justify-between'>
                <div className='w-6/12 pt-16 space-y-12'>
                    <div className='space-y-4'>
                        <H2>{t('project.about.title')?.toUpperCase()}</H2>
                        <SanitizeHTML>{ i18nStringFromObject(project, 'about') }</SanitizeHTML>
                        <div className='mt-5'>
                            {paragraphs.map((paragraph, key) => {
                                return <Collapse icon={window.screen.width < 1024 ? faChevronDown : faPlus} key={key} className='first:border-t last:border-b border-y border-gray-500'>
                                    <H5>{ i18nStringFromObject(paragraph, 'title') }</H5>
                                    <SanitizeHTML>{ i18nStringFromObject(paragraph, 'title') }</SanitizeHTML>
                                </Collapse>
                            })}
                        </div>
                    </div>
                    <div>
                        <H2 className='uppercase mb-8'>{ t('project.batches.title') }</H2>
                        <div className='space-y-1.5'>
                            {batches.length === 0 && <BatchSkeleton/>}
                            {batches && batches.length > 0 && <BatchList batches={batches} isDemo={project.isDemo}/>}
                        </div>
                    </div>

                    {!project.isDemo && <CommentList />}

                </div>
                <div className='w-5/12 transform mt-[70px] tablet-landscape:-mt-[56px] -translate-y-12 space-y-12'>
                    <div>
                        <div className='w-full min-h-72 bg-primary-light p-8 space-y-4'>
                            <H2 className='text-white uppercase'>{ t('project.statistics.title') }</H2>
                            <div className='flex flex-col tablet-landscape:flex-row tablet-landscape:space-x-12 tablet-landscape:items-center'>
                                <div className='w-1/2 mx-auto tablet-landscape:mx-0'>
                                    <Donut percentage={Math.round(project.statistics.totalPhotosFinished / project.statistics.totalPhotoCount * 100)}>
                                        <div className={'flex flex-col items-center'}>
                                            <p className={"font-mabry text-[20px] font-bold text-white"}>
                                                {Math.round(project.statistics.totalPhotosFinished / project.statistics.totalPhotoCount * 100)}%
                                            </p>
                                            <p className={"font-mabry text-[12px] font-light text-white"}>
                                                { t('project.statistics.finished') }
                                            </p>
                                        </div>
                                    </Donut>
                                </div>
                                <div className='tablet-landscape:w-1/2 text-white space-y-3'>
                                    <div>
                                        <p className={'font-mabry text-sm text-white'}>{ t('project.statistics.total_amount_of_photos_in_collection') }</p>
                                        <H2 className={'text-white'}>{ project.statistics.totalPhotoCount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }</H2>
                                    </div>
                                    <div>
                                        <p className={'font-mabry text-sm text-white'}>{ t('project.statistics.total_amount_finished') }</p>
                                        <H2 className={'text-white'}>{ project.statistics.totalPhotosFinished.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }</H2>
                                    </div>
                                    <div>
                                        <p className={'font-mabry text-sm text-white'}>{ t('project.statistics.total_amount_active_participants') }</p>
                                        <H2 className={'text-white'}>{ project.statistics.totalActiveParticipants.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }</H2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"flex flex-row" }>
                        <p className={"font-semibold"}>{ t('project.share.title') }</p>
                        <div className={"space-x-2 ml-2"}>
                            <LinkedinShareButton
                                url={`${window.location.href}`}
                                // url={`https://www.google.com`}
                            >
                                <img src="/assets/icons/linkedin-dark-icon.svg" alt="Facebook share button"/>
                            </LinkedinShareButton>
                            <TwitterShareButton
                                url={`${window.location.href}`}
                            >
                                <img src="/assets/icons/twitter-dark-icon.svg" alt="Facebook share button"/>
                            </TwitterShareButton>
                            <FacebookShareButton
                                url={`${window.location.href}`}
                            >
                                <img src="/assets/icons/facebook-dark-icon.svg" alt="Facebook share button"/>
                            </FacebookShareButton>
                        </div>
                    </div>
                    <div className={'flex flex-col space-y-4'}>
                        <div className={'space-y-4'}>
                            <H3>{t('project.how_does_it_work.title')}</H3>
                            <SanitizeHTML>{ i18nStringFromObject(project, 'howto')  }</SanitizeHTML>
                        </div>

                        { !project.isDemo && demoProjectExists && <Link to={'/project/demo'}>
                            <SuffixButton $color='light-primary' suffix={<Icon icon={faArrowRight} />}>
                                { t('project.how_does_it_work.practice') }
                            </SuffixButton>
                        </Link>}
                    </div>
                </div>
            </div>
        )
    }

    return <AwaitFetch httpStatus={httpStatus}>
        {project && <div className='min-h-full tablet-landscape:pb-16 mb-10'>
            <NavBar/>
            { invalidLanguage && <div className='flex flex-col justify-center items-center m-32 space-y-8'>
                <H1>{ t('project.project_not_available_in_language.title') }</H1>
                <H5>{ t('project.project_not_available_in_language.subtitle') }</H5>
                <div className={'flex space-x-3'}>
                    { project.languages.map(l => {
                        return <Button key={l} $color='light-white' onClick={() => i18n.changeLanguage(l)}>{ l }</Button>
                    }) }
                </div>
            </div>
            }
            { !invalidLanguage && <>
                <div className='bg-gray-500 h-96 relative'>
                    <img className='absolute top-0 left-0 w-[calc(100%-1px)] h-full object-cover' src={project.banner} alt="project banner image"/>
                    <div className="container mx-auto">
                        <div className='absolute top-8 tablet-landscape:top-60 space-y-2'>
                            <Tag className='text-base py-1 px-2 inline-flex mr-auto'>{t('project.status.active')}</Tag>
                            <H1 className='uppercase break-words text-black bg-white p-3 mr-[15px]'>{ i18nStringFromObject(project, 'title') }</H1>
                        </div>
                    </div>
                </div>
                <MobileProjectPage/>
                <DesktopProjectPage />
            </>}
        </div>}

        <Footer />
    </AwaitFetch>
}

export default withTranslation()(ProjectPage)