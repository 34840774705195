import React, {useState, ReactNode, useContext, useEffect, Dispatch} from "react";
import {User} from "../types/entities";
import AppStorage, {StorageKey} from "../AppStorage";
import Api from "../Api";
import {AxiosError} from "axios";
import {useMatch, useNavigate} from "react-router-dom";

export interface AuthContextType {
    user: any;
    login: (user: User) => void;
    logout: () => void;
    validateLogin: () => void;
    loading: boolean
    isHomepage: boolean
    setUser: Dispatch<React.SetStateAction<User>>
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export const useAuth = () => useContext(AuthContext);

export default function AuthProvider({ children }: { children: ReactNode }) {
    const [user, setUser] = useState<User>(null);
    const [loading, setLoading] = useState(true)
    const [isHomepage, setIsHomepage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (AppStorage.get(StorageKey.API_ACCESS_TOKEN) && AppStorage.get(StorageKey.USER_ID)) {
            if (!user) {
                validateLogin();
            } else {
                setLoading(false)
            }
        } else {
            setUser(null)
            setLoading(false)
        }
    }, [])

    const login = (user: User) => {
        AppStorage.set(StorageKey.API_ACCESS_TOKEN, user.accessToken)
        AppStorage.set(StorageKey.USER_ID, user.id)
        localStorage.removeItem('pop_status');
        setUser(user)
        setLoading(false)
    }

    const logout = () => {
        AppStorage.remove(StorageKey.API_ACCESS_TOKEN)
        AppStorage.remove(StorageKey.USER_ID)
        localStorage.removeItem('pop_status');
        setUser(null)
        clearPopStates();
    }

    const clearPopStates = () => {
        const types = [
            'scene-annotate',
            'object-annotate',
            'scene-check',
            'object-check',
            'object-next-level',
            'scene-next-level',
            'scene-validate',
            'object-validate'
        ];

        types.forEach(type => {
            localStorage.removeItem(`pop_status_${type}`);
        })
    }

    const validateLogin = (redirectLogin = false) => {
        setLoading(true)
        Api.post('/validate-login',
            {},
            ({data}: {data: User}) => {
                login(data)
                setLoading(false)
            },
            null,
            null,
            (error: AxiosError) => {
                logout();
                setLoading(false)
            },
        )
    }

    const navigateToLogin = () => {
        const params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
        if ('return' in params) {
            navigate(`/login?return=${params.return}`)
        } else {
            navigate(`/login?return=${window.location.pathname}${window.location.search}`)
        }
    }

    const value = { user, login, logout, validateLogin, loading, isHomepage, setIsHomepage, setUser };

    return <AuthContext.Provider value={value}>{ children }</AuthContext.Provider>;
}