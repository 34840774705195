import React, {InputHTMLAttributes, TextareaHTMLAttributes} from "react";
import {UseFormRegisterReturn} from "react-hook-form/dist/types/form";

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    suffixElement?: Element | JSX.Element
    prefixElement?: Element | JSX.Element
    inputClassName?: string | object
    register?: UseFormRegisterReturn,
    error?: string,
    onChange?: (e) => void;
    onKeyUp?: (e) => void,
    name?: string,
    onInput?: (e) => void;
}

export default function Textarea({prefixElement, suffixElement, inputClassName, className, name, onInput, register, error, onChange, onKeyUp,...props}:  TextareaProps) {
    return (
        <>
            <div className={`border border-gray-500 flex items-center h-10 px-3 space-x-2 ${error ? 'border-red-500' : 'border-gray-500'} ${className}`}>
                {prefixElement ? <div className='h-full flex items-center'>{prefixElement}</div> : null}
                <textarea rows={1} {...register} name={name} className={`outline-none h-full max-w-full flex-1 placeholder:font-light resize-none ${inputClassName}`} {...props} onChange={onChange} onInput={onInput} onKeyUp={onKeyUp}></textarea>
                {suffixElement ? <div className='h-full flex items-center'>{suffixElement}</div>  : null}
            </div>
            <small className='text-red-500 font-light text-xs'>{error}</small>
        </>

    )
}