import React, {ChangeEvent, useRef} from "react";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";

interface RadioButtonProps {
    className?: string
    activeElement: JSX.Element
    name: string
    value: string
    checked: null | boolean
    onClick: (e: React.MouseEvent<HTMLInputElement>) => void
    label?: string | JSX.Element
}


export const RadioButton = ({name, className, activeElement, checked, onClick, value, label }: RadioButtonProps) => {
    const id = generateUniqueID()
    const radioRef = useRef<HTMLInputElement>(null)

    return (
        <label htmlFor={id} className={`cursor-pointer flex space-x-1 select-none ${className}`}>
            { checked ? activeElement : <img src='/assets/images/Radiobutton.svg'/> }
            <input id={id} className={`hidden`} type="radio" ref={radioRef} value={value} name={name} checked={checked} onClick={onClick}/>
            { label }
        </label>
    )
}