import {styled, Tooltip, tooltipClasses, TooltipProps} from "@mui/material";

interface HtmlTooltipProps extends TooltipProps {
    tooltipClasses: string;
}

export const HtmlTooltipLight = styled(({ className, tooltipClasses, ...props }: HtmlTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className, tooltip: tooltipClasses }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#FFFFFF',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFFFFF',
        color: '#333333',
        maxWidth: 465,
        fontSize: theme.typography.pxToRem(12),
        boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)'
    },
}));