import React from "react";
import Checkbox from "./Checkbox";

interface LabelProps {
    children?: JSX.Element | JSX.Element[];
    selected?: boolean
    onClick?: any
}

const Label = React.forwardRef<HTMLDivElement, LabelProps>(({children, selected, ...props}: LabelProps, ref) => {

    return (
        <div ref={ref}
             className={`cursor-pointer h-[52px] flex flex-row items-center justify-between w-full px-4 ${selected ? 'bg-primary-light' : 'bg-black/5'}`}
             onClick={props.onClick}>
            <Checkbox className='mr-3' checked={selected}/>
            <div
                className={`select-none font-bold flex text-[15px] ${selected ? 'text-white' : 'text-black'}`}>
                <div>{children[0]}</div>
            </div>
            <div className={"ml-auto"}>{children[1]}</div>
        </div>
    )
})

export default Label