import './App.css';
import './css/Annotation.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import TagManager from 'react-gtm-module';
import ObjectAnnotatePage from './pages/Scenes/ObjectAnnotatePage';
import SceneCheckPage from "./pages/Scenes/SceneCheckPage";
import SceneAnnotatePage from "./pages/Scenes/SceneAnnotatePage";
import SceneValidatePage from "./pages/Scenes/SceneValidatePage";
import './plugins/i18n'
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import RegisterPage from "./pages/RegisterPage";
import ProfilePage from "./pages/ProfilePage";
import ProfileSettingsPage from "./pages/ProfileSettingsPage";
import ProjectPage from "./pages/ProjectPage";
import InstructionPage from "./pages/InstructionPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import RequireAuth from "./middleware/RequireAuth";
import AuthProvider from "./providers/AuthProvider";
import AnnotationProvider from "./providers/AnnotationProvider";
import {JoinGroup} from "./pages/JoinGroup";
import {ForgotPasswordPage} from "./pages/ForgotPasswordPage";
import {Helmet} from 'react-helmet';
import CookiePopup from "./components/CookiePopup";
import ObjectCheckPage from "./pages/Scenes/ObjectCheckPage";
import ObjectValidatePage from "./pages/Scenes/ObjectValidatePage";
import SceneNextLevelPage from "./pages/Scenes/SceneNextLevelPage";
import ObjectNextLevelPage from "./pages/Scenes/ObjectNextLevelPage";
import ColofonPage from "./pages/Colofon";
import {BatchEndPage} from "./pages/BatchEndPage";
import TermsPage from "./pages/TermsPage";
import DemoSceneAnnotatePage from "./pages/Scenes/Demo's/DemoSceneAnnotatePage";
import DemoObjectAnnotatePage from "./pages/Scenes/Demo's/DemoObjectAnnotatePage";
import Spinner from "./components/Spinner";
import {Suspense, useEffect} from 'react';
import LanguageProvider from "./providers/LanguageProvider";

function App() {

    const cookieConsents = localStorage.getItem("cookieConsents");

    function deleteCookies(cookieNames) {
        if (!Array.isArray(cookieNames)) {
            throw new Error('Cookie names must be provided as an array.');
        }

        const expirationDate = new Date('Thu, 01 Jan 1970 00:00:00 UTC').toUTCString();

        cookieNames.forEach(cookieName => {
            document.cookie = `${cookieName}=; expires=${expirationDate}; path=/;`;
        });
    }

    useEffect(() => {
        if (cookieConsents) {
            const parsedConsents = JSON.parse(cookieConsents)
            if (parsedConsents.analytics === true) {
                TagManager.initialize({gtmId: 'G-63S06EHQ6E'});
            } else {
                deleteCookies(["_ga", "_ga_63S06EHQ6E"])
            }
        }
    }, [cookieConsents])

    return (
        <div className={`App min-h-screen`}>
            <Helmet>
                <title>Wereldmuseum - Tag the picture</title>
            </Helmet>
            <Suspense fallback={<div className={'flex justify-center items-center w-screen h-screen'}><Spinner/></div>}>
                <BrowserRouter>
                    <AuthProvider>
                        <LanguageProvider>
                            <Routes>
                                <Route path="/" element={<Navigate to='/home' replace/>}/>
                                <Route path="/login" element={<LoginPage/>}/>
                                <Route path="/register" element={<RegisterPage/>}/>
                                <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
                                <Route path="/home" element={<HomePage/>}/>
                                <Route path="/how-this-works" element={<InstructionPage/>}/>
                                <Route path="/about" element={<AboutPage/>}/>
                                <Route path="/contact" element={<ContactPage/>}/>
                                <Route path="/colofon" element={<ColofonPage/>}/>
                                <Route path="/terms" element={<TermsPage/>}/>
                                <Route path="/profile" element={
                                    <RequireAuth>
                                        <ProfilePage/>
                                    </RequireAuth>
                                }/>
                                <Route path="/profile/settings" element={
                                    <RequireAuth>
                                        <ProfileSettingsPage/>
                                    </RequireAuth>
                                }/>
                                <Route path="/project/:id" element={<ProjectPage/>}/>
                                <Route path="/batch/end/:id" element={
                                    <RequireAuth>
                                        <BatchEndPage/>
                                    </RequireAuth>
                                }/>
                                <Route path="/object/annotate/:batchId" element={
                                    <RequireAuth>
                                        <AnnotationProvider>
                                            <ObjectAnnotatePage/>
                                        </AnnotationProvider>
                                    </RequireAuth>
                                }/>
                                <Route path="/object/check/:batchId" element={
                                    <RequireAuth>
                                        <AnnotationProvider>
                                            <ObjectCheckPage/>
                                        </AnnotationProvider>
                                    </RequireAuth>
                                }/>
                                <Route path="/object/validate/:batchId" element={
                                    <RequireAuth>
                                        <AnnotationProvider>
                                            <ObjectValidatePage/>
                                        </AnnotationProvider>
                                    </RequireAuth>
                                }/>
                                <Route path="/object/next-level-check/:batchId" element={
                                    <RequireAuth>
                                        <AnnotationProvider>
                                            <ObjectNextLevelPage/>
                                        </AnnotationProvider>
                                    </RequireAuth>
                                }/>
                                <Route path="/scene/annotate/:batchId" element={
                                    <RequireAuth>
                                        <AnnotationProvider>
                                            <SceneAnnotatePage/>
                                        </AnnotationProvider>
                                    </RequireAuth>
                                }/>
                                <Route path="/scene/check/:batchId" element={
                                    <RequireAuth>
                                        <AnnotationProvider>
                                            <SceneCheckPage/>
                                        </AnnotationProvider>
                                    </RequireAuth>
                                }/>
                                <Route path="/scene/validate/:batchId" element={
                                    <RequireAuth>
                                        <AnnotationProvider>
                                            <SceneValidatePage/>
                                        </AnnotationProvider>
                                    </RequireAuth>
                                }/>
                                <Route path="/scene/next-level-check/:batchId" element={
                                    <RequireAuth>
                                        <AnnotationProvider>
                                            <SceneNextLevelPage/>
                                        </AnnotationProvider>
                                    </RequireAuth>
                                }/>
                                <Route path="/join" element={
                                    <RequireAuth>
                                        <JoinGroup/>
                                    </RequireAuth>
                                }/>
                                <Route path={"/demo/object/annotate/:batchId"} element={
                                    <AnnotationProvider>
                                        <DemoObjectAnnotatePage/>
                                    </AnnotationProvider>
                                }/>
                                <Route path={"/demo/scene/annotate/:batchId"} element={
                                    <AnnotationProvider>
                                        <DemoSceneAnnotatePage/>
                                    </AnnotationProvider>
                                }/>
                            </Routes>
                        </LanguageProvider>
                    </AuthProvider>
                </BrowserRouter>
                <CookiePopup/>
            </Suspense>
        </div>
    );
}

export default App;
