import React, {useEffect, useRef, useState} from "react";
//@ts-ignore
import OpenSeadragon from "openseadragon";
import '../css/Annotation.css';
import '@recogito/annotorious/dist/annotorious.min.css';
import Annotorious from "@recogito/annotorious-openseadragon";
import BetterPolygon from '@recogito/annotorious-better-polygon';
import Toolbar from '@recogito/annotorious-toolbar';
import {useAnnotation} from "../providers/AnnotationProvider";
import {
    faAnglesLeft,
    faAnglesRight,
    faCircleHalfStroke,
    faMagnifyingGlassMinus,
    faMagnifyingGlassPlus,
    faRotateLeft,
    faRotateRight,
    faSun, faTimes,
    faUpRightAndDownLeftFromCenter
} from "@fortawesome/free-solid-svg-icons";
import Icon from "../components/Icon";
import 'openseadragon-filtering';
import Dropdown from "./Dropdown";
import {PrimarySlider} from "../styled-components/PrimarySlider";
import {useScene} from "../providers/SceneProvider";
import {useTranslation} from "react-i18next";
import {Tooltip} from "@mui/material";
import {isMobile} from "react-device-detect";
import {TabletWidth} from "../constants/ScreenWidth";
import {Button} from "../styled-components/Button";
const PolyBool = require('polybooljs');

export default function Viewer({ isChecking }: { isChecking?: boolean }) {
    const { t } = useTranslation()
    const annotationProvider = useAnnotation();
    const scene = useScene();
    const stateRef = useRef();
    const toolbarRef = useRef(null);
    const [viewerInit, setViewerInit] = useState(false);
    const [viewer, setViewer] = useState(null);
    const [contrast, setContrast] = useState(1);
    const [brightness, setBrightness] = useState(1);
    const [hideTools, setHideTools] = useState((!(isMobile || window.innerWidth <= TabletWidth)))
    const viewerController = useRef(null)
    const viewerControls = useRef(null)

    useEffect(() => {
        if (viewerInit) {
            const canvas = document.querySelector('canvas');
            canvas.style.setProperty('filter', "brightness(" + brightness + ") contrast(" + contrast + ")")
        }
    }, [contrast, brightness, viewerInit])

    useEffect(() => {
        if (viewerInit) {
            const sceneHeader = document.getElementById('sceneHeader');
            const sceneHeaderHeight = sceneHeader.offsetHeight;

            //setting offset based on sceneheader height
            const navigator = document.querySelector('.navigator') as HTMLElement;
            if (viewerController.current && viewerController.current && navigator && isMobile && (window.innerWidth <= TabletWidth)) {
                navigator.style.setProperty('top', `${sceneHeaderHeight}px`);
                viewerController.current.style.setProperty('top', `${sceneHeaderHeight + 20}px`);
                viewerControls.current.style.setProperty('top', `${sceneHeaderHeight + 55}px`);
            }
        }
    }, [viewerInit, scene.isSubStep]);

    const getContrastValue = (event) => {
        // show the user input value to console
        setContrast(event.target.value)
    };

    const getBrightnessValue = (event) => {
        // show the user input value to console
        setBrightness(event.target.value)
    };

    useEffect(() => {
            if (stateRef.current && scene.image) {
                const viewer = OpenSeadragon({
                    element: stateRef.current,
                    prefixUrl: '',
                    sequenceMode: true,
                    minZoomLevel: 0.6,
                    showNavigator: true,
                    zoomInButton: "ZoomIn",
                    zoomOutButton: "ZoomOut",
                    homeButton: "homeButton",
                    rotateLeftButton: "RotateLeftButton",
                    rotateRightButton: "RotateRightButton",
                    navigatorHeight: "120px",
                    navigatorWidth: "145px",
                    showRotationControl: true,
                    showSequenceControl: false,
                    showFullPageControl: false,
                    gestureSettingsMouse: {
                        clickToZoom: false,
                        dragToPan: false
                    },
                    gestureSettingsTouch: {
                        clickToZoom: false,
                        dragToPan: false,
                    },
                    tileSources: {
                        type: "image",
                        url: scene.image.url
                    },
                })

                //fit image in canvas
                viewer.addHandler('open', function (event) {
                    var tiledImage = viewer.world.getItemAt(0);
                    var bounds = tiledImage.getBounds();
                    viewer.viewport.fitBounds(bounds, true);
                })

                var formatter = function (annotation) {
                    var highlightBody = annotation.bodies.find(function (b) {
                        return b.purpose === 'highlighting';
                    });

                    if (highlightBody)
                        return highlightBody.value;
                };

                //configuration annotorious tool
                var config = {
                    disableEditor: true,
                    formatter: formatter,
                    drawOnSingleClick: true,
                    disableSelect: false,
                    readOnly: !!isChecking,
                };

                var annotorious = Annotorious(viewer, config)

                if (annotationProvider) {
                    annotationProvider.setAnnotorious(annotorious)
                }

                BetterPolygon(annotorious)
                Toolbar(annotorious, toolbarRef.current)

                document.body.addEventListener('keyup', function (evt) {
                    if (evt.which === 46) {
                        var selected = annotorious.getSelected();
                        annotorious.removeAnnotation(selected);
                    }
                });

                setViewerInit(true)
                setViewer(viewer)

                // Cleanup: destroy current instance
                return () => annotorious.destroy();
            }
    }, [scene.image, stateRef])

    /****
     * ANNOTATION HANDLING
     */

    useEffect(() => {
        if (annotationProvider.annotorious && annotationProvider.labels, annotationProvider.isDrawing) {
            annotationProvider.annotorious.off('createSelection', null)
            annotationProvider.annotorious.off('createAnnotation', null)

            annotationProvider.annotorious.once('createSelection', async (selection) => {
                const drawingForLabel = annotationProvider.labels.find(l => l.id === annotationProvider.isDrawing)

                // console.log(drawingForLabel);
                selection.body = {
                  value: annotationProvider.getColour(drawingForLabel.annotations.map(a => a.base.body.value).pop()),
                  purpose: 'highlighting'
                };

                await annotationProvider.annotorious.updateSelected(selection);
                annotationProvider.annotorious.saveSelected();

                if (scene.isSubStep) {
                    annotationProvider.setTool(null)
                    annotationProvider.annotorious.setDrawingEnabled(false);
                    annotationProvider.setIsDrawing(null);
                } else {
                    annotationProvider.annotorious.setDrawingEnabled(true);
                }
            });

            annotationProvider.annotorious.once('createAnnotation', (annotation) => {
                const redrawnAnnotation = redrawAnnotation(scene.image, annotation);
                annotationProvider.annotorious.removeAnnotation(annotation.id)
                annotationProvider.annotorious.addAnnotation(redrawnAnnotation);
                annotationProvider.addAnnotationToLabel(redrawnAnnotation)
            })
        }
    }, [annotationProvider.annotorious, annotationProvider.labels, annotationProvider.isDrawing])

    useEffect(() => {
        if (annotationProvider.annotorious) {
            if (!annotationProvider.isDrawing) {
                annotationProvider.annotorious.off('createSelection', null)
                annotationProvider.annotorious.off('createAnnotation', null)
            } else {
                annotationProvider.annotorious.off('updateAnnotation', null)
                annotationProvider.annotorious.off('selectAnnotation', null)
                annotationProvider.annotorious.off('clickAnnotation', null)

                annotationProvider.annotorious.on('updateAnnotation', (annotation) => {
                    annotationProvider.updateAnnotation(annotation)
                })

                annotationProvider.annotorious.on('clickAnnotation', (anno) => {
                    console.log('clicked');
                })

                annotationProvider.annotorious.on('selectAnnotation', (annotation) => {
                    console.log('select');
                    annotationProvider.selectAnnotation(annotation)
                })
            }
        }
    }, [annotationProvider.annotorious, annotationProvider.isDrawing])

    useEffect(() => {
        function handleOutsideClick(event: MouseEvent) {
            if (isMobile || window.innerWidth <= TabletWidth) {
                const selectedAnnotation = document.getElementById("selectedAnnotation") as HTMLElement
                if (selectedAnnotation || !selectedAnnotation.contains(event.target as Node)) {
                    annotationProvider.setActiveAnnotation(null)
                }
            }

            const container = stateRef.current as unknown as HTMLElement
                if (container && !container.contains(event.target as Node)) {
                    if (annotationProvider.annotorious) {
                        if (annotationProvider.annotorious.getSelected()) {
                            if (!scene.isSubStep) {
                                annotationProvider.annotorious.updateSelected(annotationProvider.annotorious.getSelected(), true);
                                annotationProvider.setIsDrawing(null)
                            }
                        } else if(annotationProvider.isDrawing) {
                            annotationProvider.annotorious.cancelSelected()
                        }
                    }
                }

        }

        document.addEventListener('mousedown', handleOutsideClick)

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [stateRef, annotationProvider.annotorious, annotationProvider.isDrawing])


    return (
        <div className={`fixed tablet:static bg-gray-200 border-r border-gray-500 flex flex-col ${(window.innerWidth <= TabletWidth || isMobile) && '!w-full'}  ${scene.expanded ? 'w-[95%]  h-full' : 'w-7/12 h-full'} ${annotationProvider.isDrawing ? 'cursor-crosshair' : '' }`}>
            <div id='container' ref={stateRef} className={'flex flex-1 items-center justify-center object-fit'}>
                <div className='hidden' ref={toolbarRef} />
                <div ref={viewerController} className={"block tablet:hidden absolute left-0 z-[100] "}>
                    <Button $icon onClick={() => setHideTools(!hideTools)} className={"w-[35px] h-[35px]"} $color='white'>
                        <Icon className={"scale-[0.75]"} icon={hideTools ? faAnglesLeft : faAnglesRight} />
                    </Button>
                </div>

                <div ref={viewerControls} className={`${hideTools ? '' : 'hidden'} absolute flex top-[140px] tablet:top-[28px] left-0 tablet:left-[28px] z-[200] space-x-1 tablet:w-[500px]`}>
                    <Tooltip title={t('general.tooltips.center_image')} enterDelay={500}>
                        <div className="z-[99] flex justify-center items-center w-[35px] tablet:w-[40px] h-[35px] tablet:h-[40px] bg-white border-solid border-[1px] border-[#DDDDDD] active:border-primary" id="homeButton">
                            <Icon className="w-[15px] h-[15px] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2" icon={faUpRightAndDownLeftFromCenter}></Icon>
                        </div>
                    </Tooltip>
                    <Tooltip title={t('general.tooltips.zoom_in')} enterDelay={500}>
                        <span className="z-[99] flex justify-center items-center w-[35px] tablet:w-[40px] h-[35px] tablet:h-[40px] bg-white border-solid border-[1px] border-[#DDDDDD] active:border-primary" id="ZoomIn">
                            <Icon className="w-[15px] h-[15px] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2" icon={faMagnifyingGlassPlus}></Icon>
                        </span>
                    </Tooltip>
                    <Tooltip title={t('general.tooltips.zoom_out')} enterDelay={500}>
                        <span className="z-[99] flex justify-center items-center w-[35px] tablet:w-[40px] h-[35px] tablet:h-[40px] bg-white border-solid border-[1px] border-[#DDDDDD] active:border-primary" id="ZoomOut">
                            <Icon className="w-[15px] h-[15px] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2" icon={faMagnifyingGlassMinus}></Icon>
                        </span>
                    </Tooltip>
                    <Tooltip title={t('general.tooltips.rotate_left')} enterDelay={500}>
                        <span className="z-[99] flex justify-center items-center w-[35px] tablet:w-[40px] h-[35px] tablet:h-[40px] bg-white border-solid border-[1px] border-[#DDDDDD] active:border-primary" id="RotateLeftButton">
                            <Icon className="w-[15px] h-[15px] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2" icon={faRotateLeft}></Icon>
                        </span>
                    </Tooltip>
                    <Tooltip title={t('general.tooltips.rotate_right')} enterDelay={500}>
                        <span className="z-[99] flex justify-center items-center w-[35px] tablet:w-[40px] h-[35px] tablet:h-[40px] bg-white border-solid border-[1px] border-[#DDDDDD] active:border-primary" id="RotateRightButton">
                            <Icon className="w-[15px] h-[15px] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2" icon={faRotateRight}></Icon>
                        </span>
                    </Tooltip>
                    <Tooltip title={t('general.tooltips.adjust_brightness')} enterDelay={500}>
                        <span>
                            <Dropdown>
                                <div className="z-[99] flex justify-center items-center w-[35px] tablet:w-[40px] h-[35px] tablet:h-[40px] bg-white border-solid border-[1px] border-[#DDDDDD] active:border-primary" id="Brightness">
                                    <Icon className="w-[16px] h-[16px]" icon={faSun}></Icon>
                                </div>
                                <div className="w-[170px] h-[80px] mt-[3px] bg-white flex flex-col justify-center items-center">
                                    <h1 className="font-mabry text-[12px] font-semibold w-[140px] text-left">{t('general.tooltips.adjust_brightness')}</h1>
                                    <PrimarySlider id="brightness-slider" defaultValue={1} min={0} max={2} step={0.1} onChange={getBrightnessValue} />
                                </div>
                            </Dropdown>
                        </span>
                    </Tooltip>
                    <Tooltip title={t('general.tooltips.adjust_contrast')} enterDelay={500}>
                        <span>
                            <Dropdown>
                                <div className="z-[99] flex justify-center items-center w-[35px] tablet:w-[40px] h-[35px] tablet:h-[40px] flex items-center justify-center bg-white border-solid border-[1px] border-[#DDDDDD] active:border-primary" id="Contrast">
                                    <Icon className="w-[16px] h-[16px] " icon={faCircleHalfStroke}></Icon>
                                </div>
                                <div className="w-[170px] h-[80px] mt-[3px] bg-white flex flex-col justify-center items-center">
                                    <h1 className="font-mabry text-[12px] font-semibold w-[140px] text-left">{t('general.tooltips.adjust_contrast')}</h1>
                                    <PrimarySlider id="contrast-slider" defaultValue={1} min={0} max={2} step={0.1} onChange={getContrastValue} />
                                </div>
                            </Dropdown>
                        </span>
                    </Tooltip>
                </div>

                {/*{ annotationProvider.isDrawing && <div className='absolute bottom-3 right-3 z-10'>*/}
                {/*    <Button $color='light-red' onClick={() => annotationProvider.setIsDrawing(null)}>Klaar</Button>*/}
                {/*</div> }*/}

            </div>
            { scene.image.photoCreditLine && <div className={'bg-white w-full border-t-black flex items-center px-6 py-2.5'}>
                <span className={'font-light text-xs italic'}>{ scene.image.photoCreditLine }</span>
            </div> }
        </div>
    )
}

const redrawAnnotation = (image, annotation) => {
    const { target: { selector: { type }} } = annotation;

    switch (type) {
        case 'SvgSelector':
            return redrawPolygon(image, annotation);
        case 'FragmentSelector':
            return redrawRectangle(image, annotation);
    }
}

const redrawPolygon = (image, annotation) => {
    const { target: { selector: { value }} } = annotation;

    const matches = value.match(/ points="([^"]*)"/)
    if (matches.length && matches[1]) {
        const points = matches[1].split(' ').map(p1 => p1.split(',').map(p2 => parseFloat(p2)))

        const res = PolyBool.intersect({regions: [points]}, {
            regions: [
                [[0,0],[image.width, 0],[image.width, image.height],[0, image.height]]
            ]
        })

        annotation.target.selector.value = `<svg><polygon points="${res.regions.join(' ')}" /></svg>`

        return annotation
    }

    return annotation;
}

const redrawRectangle = (image, annotation) => {
    const { target: { selector: { value }} } = annotation;

    const valueArr = value.replace('xywh=pixel:', '').split(',').map(p => parseFloat(p))

    const res = PolyBool.intersect(
        {
            regions: [[
                [valueArr[0], valueArr[1] + valueArr[3]], // bottom-left x, y + h
                [valueArr[0], valueArr[1]], // top-left x,y
                [valueArr[0] +  valueArr[2], valueArr[1]], // top-right x + w, y
                [valueArr[0] + valueArr[2], valueArr[1] + valueArr[3]], // bottom-right x + w, y + h
            ]]
        },
        {
            regions: [[
                [0, image.height], // bottom-left
                [0,0], // top-left
                [image.width, 0], //top-right
                [image.width, image.height], // bottom-right
            ]]
        }
    )


    const xywh = [
        res.regions[0][2][0],
        res.regions[0][2][1],
        res.regions[0][0][0] - res.regions[0][2][0],
        res.regions[0][0][1] - res.regions[0][2][1],
    ]

    annotation.target.selector.value = 'xywh=pixel:' + (xywh.join(','));

    return annotation
}