import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {MouseEventHandler} from "react";

interface IconProps {
    icon: IconProp
    onClick?: MouseEventHandler<SVGSVGElement>,
    className?: string | object
}

export default function Icon({icon, ...props}: IconProps) {

    return (
        <FontAwesomeIcon className={`h-4 w-4 text-[16px] ${props.className}`} icon={icon} onClick={props.onClick} />
    )
}