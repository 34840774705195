import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Icon from './Icon';

const Popup = props => {

    return (
        <div className="fixed z-[999999999] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-screen h-screen visible outro">
            <div className="border-[1px] border-[#DDD] px-[30px] py-[30px] w-[90vw] max-w-[600px] max-h-[90vh] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white opacity-100 z-[999999]">
                <button className="absolute right-[28px] top-[20px]" onClick={props.handleClose}>
                    <Icon className="text-black text-xs" icon={faXmark} />
                </button>
                {props.children}
            </div>
            <div className="absolute w-screen h-screen bg-black opacity-50 z-[999]"></div>
        </div>
    )
}

export default Popup