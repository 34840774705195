import Viewer from "../../components/Viewer";
import SceneCheckContainer from "../../components/Scene/SceneCheckContainer";
import SceneProvider from "../../providers/SceneProvider";

export default function SceneCheckPage() {

    return (
        <div className='h-screen tablet:overflow-y-auto overflow-y-hidden w-full flex'>
            <SceneProvider isChecking>
                <Viewer isChecking/>
                <SceneCheckContainer />
            </SceneProvider>
        </div>
    )
}