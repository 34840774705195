import React, {useState} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {Label} from "../types/entities";
import {Fade} from "@mui/material";
import {faChevronDown, faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {useScene} from "../providers/SceneProvider";
import CheckAnnotation from "./CheckAnnotation";
import {HtmlTooltipLight} from "./HtmlTooltipLight";
import Icon from "./Icon";
import {i18nStringFromObject} from "../plugins/i18n";
import {Button} from "../styled-components/Button";

interface CheckableObjectLabelProps extends WithTranslation {
    label: Label,
    isMissing: boolean
}

function CheckableObjectLabel({ t, i18n, label, isMissing }: CheckableObjectLabelProps) {
    const scene = useScene();
    const [dropdownOpen, setDropdownOpen] = useState(true)

    return (
        <div className={`outline-none' ${isMissing ? 'outline-red-500 outline' : ''} ${scene.expanded ? 'hidden' : 'bg-white rounded shadow px-4'}`}>
            <div className='min-h-13 flex items-center space-x-4 select-none'>
                <div className='flex items-center flex-1 h-full pl-4 -ml-4 cursor-pointer'>{ i18nStringFromObject(label, 'title') }</div>
                <HtmlTooltipLight TransitionComponent={Fade} enterDelay={0} enterTouchDelay={0} title={ i18nStringFromObject(label, 'description') } tooltipClasses={'bg-red-500'} leaveTouchDelay={1500}
                                  className={`!-mt-[4vw] mini-mobile:!-mt-[2.5vw] tablet:!-mt-0`} arrow>
                    <Button className='cursor-default'><Icon icon={faCircleInfo}/></Button>
                </HtmlTooltipLight>
                <Icon className={`cursor-pointer ${label.annotations && label.annotations.filter(a => a.type !== 'notFound').length ? 'text-black' : 'text-gray-300'}`} icon={faChevronDown} onClick={() => setDropdownOpen(!dropdownOpen)} />
            </div>
            {dropdownOpen && <div className='h-[1px] bg-gray-500 w-full' />}
            {dropdownOpen &&
                <div className='py-4 space-y-1' id="Annotations">
                    {label?.objects.map((object, key) => { return <CheckAnnotation key={key} object={object} /> })}
                </div>
            }
        </div>
    )
}

export default withTranslation()(CheckableObjectLabel)