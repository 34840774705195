import React, {Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState} from "react";
import {Batch, Label, NextLevelComment} from "../types/entities";
import Api from "../Api";
import {AxiosError} from "axios";
import AwaitFetch from "../middleware/AwaitFetch";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import ReactDOM from "react-dom";
import {useAnnotation} from "./AnnotationProvider";

export interface SceneContextType {
    viewer: any
    setViewer: (viewer) => void
    batch: {
        id: string,
        type: 'Scene' | 'Object',
        maxLabels: number,
        phase: string,
        status: string,
        batchIntroCheck: object
        batchIntroNextLevelCheck: object
        batchIntroAnnotate: object
        batchIntroReview: object
        batchIntroCheckNL: string,
        batchIntroCheckEN: string,
        batchIntroNextLevelCheckNL: string,
        batchIntroNextLevelCheckEN: string,
        batchIntroAnnotateNL: string,
        batchIntroAnnotateEN: string,
        batchIntroReviewNL: string,
        batchIntroReviewEN: string,
    },
    project: {
        id: string
        isDemo: boolean
    }
    expanded: any
    setExpanded: (expanded) => void
    dropdown: any
    setDropdown: (dropdown) => void
    showTutorial: any
    setShowTutorial: (showTutorial) => void
    showRemark: any
    setShowRemark: (showRemark) => void
    showSkipRemark: any
    setShowSkipRemark: (showSkipRemark) => void
    showStop: any
    setShowStop: (showStop) => void
    showDownload: any
    setShowDownload: (showDownload) => void
    isChecked: any
    setIsChecked: (isChecked) => void
    isCheckContainer: any
    setIsCheckContainer: (isCheckContainer) => void
    isSceneAnnotateContainer: any
    setSceneAnnotateContainer: (isSceneAnnotateContainer) => void
    typeLabels: any
    setTypeLabels: (typeLabels) => void
    overlay: any
    setOverlay: (overlay) => void
    explicit: any
    setExplicit: (explicit) => void
    skipOption: any
    setSkipOption: Dispatch<SetStateAction<string>>
    labels: Label[]
    batches: Batch[]
    setLabels: Dispatch<SetStateAction<Label[]>>
    labelFilter: string
    setLabelFilter: Dispatch<SetStateAction<string>>
    selectedLabel: Label[]
    setSelectedLabel: Dispatch<SetStateAction<Label[]>>
    image: { url: string, id: string, photoSource: string, photoNumber: string, photoCreditLine: string }
    setImage: Dispatch<SetStateAction<{ url: string, id: string }>>
    next: (nextPhotoIndex: number | null) => void
    toggleTutorial: () => void
    toggleRemark: () => void
    toggleSkipRemark: () => void
    toggleStop: () => void
    stop: () => void

    // Normal remark
    remark: string
    setRemark: (remark) => void
    remarkSubmitted: boolean
    setRemarkSubmitted: (remarkSubmitted) => void

    // Skip remark
    skipRemark: string
    setSkipRemark: (skipRemark) => void
    skipRemarkSubmitted: boolean
    setSkipRemarkSubmitted: (remarkSubmitted) => void

    nextLevelComment: NextLevelComment

    isSubStep: boolean
    setIsSubStep: (isSubStep) => void
}

export const SceneContext = React.createContext<SceneContextType>(null!);

export const useScene = () => useContext(SceneContext);

export default function SceneProvider({ isChecking, isNextLevelChecking, children}: { isChecking?: boolean, isNextLevelChecking?: boolean, children: ReactNode }) {
    const {batchId} = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const [batch, setBatch] = useState(null)
    const [dropdown, setDropdown] = useState(false)
    const [project, setProject] = useState(null)
    const [viewer, setViewer] = useState<any>(null);
    const [expanded, setExpanded] = useState<any>(false);
    const [labels, setLabels] = useState<Label[]>([])
    const [batches, setBatches] = useState<Batch[]>([])
    const [labelFilter, setLabelFilter] = useState<string>(null)
    const [image, setImage] = useState<{ url: string, id: string, photoSource: string, photoNumber: string, photoCreditLine: string, width: number, height: number }>(null)
    const [selectedLabel, setSelectedLabel] = useState<Label[]>(null)
    const [showTutorial, setShowTutorial] = useState(false);
    const [showDownload, setShowDownload] = useState(false);
    const [showStop, setShowStop] = useState(false);
    const [isCheckContainer, setIsCheckContainer] = useState(false);
    const [isSceneAnnotateContainer, setSceneAnnotateContainer] = useState(false);
    const [typeLabels, setTypeLabels] = useState(null);
    const [overlay, setOverlay] = useState(false);


    // For remark section
    const [showRemark, setShowRemark] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [explicit, setExplicit] = useState<boolean>(false);
    const [skipOption, setSkipOption] = useState<string>('')
    const [remark, setRemark] = useState<string>('');
    const [remarkSubmitted, setRemarkSubmitted] = useState<boolean>(false);

    // For skip remark section
    const [showSkipRemark, setShowSkipRemark] = useState<boolean>(false);
    const [skipRemark, setSkipRemark] = useState<string>('');
    const [skipRemarkSubmitted, setSkipRemarkSubmitted] = useState<boolean>(false);

    // For object check
    const annotationProvider = useAnnotation();

    // For next level check
    const [nextLevelComment, setNextLevelComment] = useState<NextLevelComment>(null)

    // For the mobile version, when you select a shape, then you enter the substep
    const [isSubStep, setIsSubStep] = useState(false);
    const [httpStatus, setHttpStatus] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.href.indexOf("scene/check") > -1 ||
            window.location.href.indexOf("scene/next-level-check") > -1 ||
            window.location.href.indexOf("object/check") > -1 ||
            window.location.href.indexOf("object/next-level-check") > -1) {
            setIsCheckContainer(true)
        } else {
            setIsCheckContainer(false)
        }

        if (window.location.href.indexOf("scene/annotate") > -1) {
            setSceneAnnotateContainer(true)
        } else {
            setSceneAnnotateContainer(false)
        }
        setBatch(batchId)
        fetchBatch()
    }, [])

    const next = (nextPhotoIndex: number | null = null) => {
        setIsSubStep(false)
        setHttpStatus(null);
        setImage(null);
        setSelectedLabel(null)
        setLabelFilter(null);
        setViewer(null);
        setRemarkSubmitted(false);
        setRemark(null)
        setSkipRemarkSubmitted(false);
        setShowSkipRemark(false);
        setSkipRemark(null)
        setIsChecked(false)
        setExplicit(false)
        setSkipOption(null)
        setOverlay(false)

        if (window.location.href.indexOf("scene/check") > -1 ||
            window.location.href.indexOf("scene/next-level-check") > -1 ||
            window.location.href.indexOf("object/check") > -1 ||
            window.location.href.indexOf("object/next-level-check") > -1) {
            setIsCheckContainer(true)
        } else {
            setIsCheckContainer(false)
        }

        fetchScene(nextPhotoIndex);

        if (nextPhotoIndex) {
            navigate({ pathname: window.location.pathname, search: `?p=${nextPhotoIndex}`}, { replace: true })
        }
    }

    const toggleTutorial = () => {
        setShowTutorial(!showTutorial)
    }

    const toggleRemark = () => {
        setShowRemark(!showRemark)
        setRemarkSubmitted(false)
    }

    const toggleSkipRemark = () => {
        setShowSkipRemark(!showSkipRemark)
    }

    const toggleStop = () => {
        setShowStop(!showStop)
    }

    const fetchBatch = () => {
        Api.get(`/batches/${batchId}`,
            ({data}) => {
                setProject({
                    id: data.project.id,
                    isDemo: data.project.isDemo
                })
                setBatch({
                    id: batchId,
                    type: data.type,
                    maxLabels: data.maxLabels,
                    phase: data.phase,
                    batchIntroCheck: data.batchIntroCheck,
                    batchIntroNextLevelCheck: data.batchIntroNextLevelCheck,
                    batchIntroAnnotate: data.batchIntroAnnotate,
                    batchIntroReview: data.batchIntroReview,
                    batchIntroCheckNL: data.batchIntroCheckNL,
                    batchIntroCheckEN: data.batchIntroCheckEN,
                    batchIntroNextLevelCheckNL: data.batchIntroNextLevelCheckNL,
                    batchIntroNextLevelCheckEN: data.batchIntroNextLevelCheckEN,
                    batchIntroAnnotateNL: data.batchIntroAnnotateNL,
                    batchIntroAnnotateEN: data.batchIntroAnnotateEN,
                    batchIntroReviewNL: data.batchIntroReviewNL,
                    batchIntroReviewEN: data.batchIntroReviewEN
                })
            },
            null,
            null,
            (error: AxiosError) => {
                setHttpStatus(error.response.status)
            })
    }

    useEffect(() => {
        if (project) {
            if (searchParams.get('p')) {
                fetchScene(parseInt(searchParams.get('p')))
            } else {
                fetchScene()
            }
        }
    }, [project])

    const stop = () => {
        if (project.isDemo) {
            navigate(`/project/demo`);
            ReactDOM.unmountComponentAtNode(document.getElementById('popup'));
        } else {
            Api.post(`/batchphotos/${image.id}/stop-scene${isChecking ? '?c=1' : ''}${isNextLevelChecking ? '?nlc=1' : ''}`, {},
                () => {
                    navigate(`/project/${project.id}`);
                    ReactDOM.unmountComponentAtNode(document.getElementById('popup'));
                },
                null,
                null,
                () => {
                    navigate(`/project/${project.id}`);
                    ReactDOM.unmountComponentAtNode(document.getElementById('popup'));
                })
        }
    }

    const fetchScene = (nextPhotoIndex: number | null = null) => {
        const params = {
            ...(nextPhotoIndex && { 'p': nextPhotoIndex.toString() }),
            ...(isChecking && { 'c': '1' } ),
            ...(isNextLevelChecking && { 'nlc' : '1'} )
        }

        const queryString = new URLSearchParams(params).toString();

            Api.get(`${(project.isDemo) ? '/demo' : ''}/batches/${batchId}/next-scene${ queryString.length ? `?${queryString}` : ''}`,
            ({data}) => {
                if (Object.entries(data).length) {
                    setImage({
                        url: data.imageURL,
                        id: data.batchPhotoId,
                        photoNumber: data.photoNumber,
                        photoSource: data.photoSource,
                        photoCreditLine: data.photoCreditLine,
                        width: data.photoSize.width,
                        height: data.photoSize.height
                    })

                    if (data.nextLevelComment) {
                        setNextLevelComment(data.nextLevelComment)
                    }

                    if (data.checkLabels) {
                        setLabels(data.checkLabels)
                    }

                    if (data.checkObjects) {
                        annotationProvider.setCheckLabels(data.checkObjects)
                    }

                    if (data.aiLabels) {
                        setLabels(data.aiLabels)
                    }

                    if (data.aiObjects) {
                        annotationProvider.setCheckLabels(data.aiObjects)
                    }

                    setHttpStatus(200)
                }
            },
            null,
            null,
            (error: AxiosError) => {
                if (error.response.status === 404) {
                    navigate(`/batch/end/${batchId}`);
                } else {
                    setHttpStatus(error.response.status)
                }
            })


    }

    const value = {
        viewer,
        setViewer,
        batch,
        project,
        dropdown,
        setDropdown,
        expanded,
        setExpanded,
        remark,
        setRemark,
        skipRemark,
        setSkipRemark,
        labels,
        setLabels,
        labelFilter,
        setLabelFilter,
        selectedLabel,
        setSelectedLabel,
        image,
        setImage,
        next,
        batches,
        setBatches,
        showTutorial,
        setShowTutorial,
        overlay,
        setOverlay,
        showDownload,
        setShowDownload,
        showStop,
        setShowStop,
        showRemark,
        setShowRemark,
        showSkipRemark,
        setShowSkipRemark,
        toggleTutorial,
        toggleRemark,
        toggleSkipRemark,
        toggleStop,
        stop,
        isChecked,
        setIsChecked,
        explicit,
        setExplicit,
        skipOption,
        setSkipOption,
        remarkSubmitted,
        setRemarkSubmitted,
        skipRemarkSubmitted,
        setSkipRemarkSubmitted,
        isCheckContainer,
        setIsCheckContainer,
        isSceneAnnotateContainer,
        setSceneAnnotateContainer,
        typeLabels,
        setTypeLabels,
        nextLevelComment,
        setIsSubStep,
        isSubStep
    };

    return <SceneContext.Provider value={value}>
        <AwaitFetch httpStatus={httpStatus}>
            {children}
        </AwaitFetch>
    </SceneContext.Provider>;
}