import {Link} from "react-router-dom";
import {Button} from "../styled-components/Button";
import React from "react";
import {withTranslation} from "react-i18next";

const ParticipationBlock = ({ message, button1, button2 }: {message: string, button1: {text: string}, button2: {text: string}}) => {
    return (
        <div className={"w-screen bg-gray-200 my-auto py-[80px] tablet-landscape:py-[100px]"}>
            <div className={"flex justify-center w-full container mx-auto flex-col items-center space-y-[70px] tablet-landscape:space-y-20"}>
                <h1 className={'text-center text-[28px] text-black font-bold font-mabry leading-[33px] max-w-[500px]'}>{ message }</h1>
                <div className={'flex flex-col w-full tablet-landscape:w-auto tablet-landscape:flex-row space-y-4 space-x-0 tablet-landscape:space-y-0 tablet-landscape:space-x-4'}>
                    <Link to='/register'>
                        <Button className={"w-full justify-center tablet-landscape:min-w-[200px]"} $color='light-primary'>{ button1.text }</Button>
                    </Link>
                    <Link to={`/project/demo`}>
                        <Button className={"w-full justify-center tablet-landscape:min-w-[200px]"} $color='white'>{ button2.text }</Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(ParticipationBlock)