import * as sanitizeHtml from 'sanitize-html'

const defaultOptions = {
    allowedTags: ['h1', 'h2', 'h3', 'p','b', 'i', 'em', 'strong', 'a', 'li', 'ul', 'ol', 'br'],
    allowedAttributes: {
        'a': [ 'href', 'target' ]
    },
};
const sanitize = (dirty, options) => ({
    __html: sanitizeHtml(
        dirty,
        {...defaultOptions, ...options}
    )
})

export default function SanitizeHTML({children, options}: {children: string, options?: any}) {
    return <div className={'font-bl-pylon text-base text-black-text'} dangerouslySetInnerHTML={sanitize(children, options)} />
}