import Icon from "../Icon";
import {faCaretDown, faChevronDown, faCircleInfo, faPlus} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import Annotation from "./Annotation";
import Dropdown from "../Dropdown";
import { WithTranslation, withTranslation } from "react-i18next";
import { Label } from "../../types/entities";
import { OptionSvg, SvgIcon } from "../../styled-components/SvgIcon";
import { useAnnotation } from "../../providers/AnnotationProvider";
import { useScene } from "../../providers/SceneProvider";
import {Fade} from "@mui/material";
import {HtmlTooltipLight} from "../HtmlTooltipLight";
import {isMobile} from "react-device-detect";
import {TabletWidth} from "../../constants/ScreenWidth";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {Button} from "../../styled-components/Button";
import {i18nStringFromObject} from "../../plugins/i18n";

interface AnnotableLabelProps extends WithTranslation {
    label: Label,
    isMissing?: boolean,
    isSubStep?: boolean
}

function AnnotableLabel({ t, i18n, label, isMissing, isSubStep}: AnnotableLabelProps) {
    const annotationProvider = useAnnotation()
    const scene = useScene()
    const stateRef = useRef()
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [toolState, setToolState] = useState('rect');
    const [drawnLabel, setDrawnLabel] = useState<boolean>(false)
    const {width} = useWindowDimensions();

    const toggleDropdown = (): void => setDropdownOpen(!dropdownOpen)
    const toggleNotFound = (): void => setNotFound(!notFound)
    const hasAnnotations = (): boolean => !!label.annotations.filter(a => a.type !== 'notFound')?.length

    const startAnnotating = (tool) => {
        annotationProvider.removeAllNotFoundAnnotationsOfLabel(label.id);
        annotationProvider.setIsDrawing(label.id)
        annotationProvider.setTool(tool)
        // @ts-ignore
        annotationProvider.setCurrentLabel(label)
        setNotFound(false)
        setToolState(tool)
        setDrawnLabel(true)
        setDropdownOpen(true)
    }

    useEffect(() => {
        if (annotationProvider.isDrawing == null) {
            setDrawnLabel(false)
        }
    }, [annotationProvider.isDrawing])

    useEffect(() => {
        if (label.annotations && label.annotations.length === 0 && label.id !== annotationProvider.isDrawing) {
            setDropdownOpen(false)
        }
    }, [annotationProvider.labels, annotationProvider.isDrawing])

    useEffect(() => {
        if (notFound) {
            annotationProvider.addNotFoundAnnotationToLabel(label.id);
        } else {
            annotationProvider.removeAllNotFoundAnnotationsOfLabel(label.id);
        }
    }, [notFound])

    useEffect(() => {
        if (!scene.isSubStep) {
            annotationProvider.setCurrentLabel(null)
            setDrawnLabel(null)

            annotationProvider.setIsDrawing(null)
        }
        annotationProvider.setActiveAnnotation(null)
    }, [scene.isSubStep]);

    useEffect(() => {
        if (width > TabletWidth || (annotationProvider.isDrawing !== null && (isMobile || window.innerWidth <= TabletWidth))) {

            // @ts-ignore
            function handleOutsideClick(event: MouseEvent) {
                const container = stateRef.current as HTMLElement
                if (container && !container.contains(event.target as Node)) {
                    if (!annotationProvider.annotorious._app.current.annotationLayer.selectedShape) {
                        annotationProvider.annotorious.cancelSelected()
                    }
                    setDrawnLabel(null)
                    annotationProvider.setIsDrawing(null)
                }
            }

            document.addEventListener('mousedown', handleOutsideClick)

            return () => {
                document.removeEventListener('mousedown', handleOutsideClick)
            }
        }
    }, [stateRef, annotationProvider.annotorious])


    //Changing the style of tool selector when you click something
    const getType = () => {
        if (toolState === 'polygon') {
            if (drawnLabel) {
                return (
                    <div className="flex">
                        <OptionSvg src="/assets/icons/polygon-active.svg" alt="polygon icon" />
                        <Icon className="pt-[5px] pl-1 !text-white" icon={faCaretDown} />
                    </div>
                )
            }
            return (
                <div className="flex">
                    <OptionSvg src="/assets/icons/polygon-inactive.svg" alt="polygon icon" />
                    <Icon className="pt-[5px] pl-1 !text-gray-warm" icon={faCaretDown} />
                </div>
            )
        }

        if (toolState === 'rect') {
            if (drawnLabel) {
                return (
                    <div className="flex">
                        <OptionSvg src="/assets/icons/rectangle-active.svg" alt="rectangle icon" />
                        <Icon className="pt-[2px] pl-1 !text-white" icon={faCaretDown} />
                    </div>
                )
            } else {
                return (
                    <div className="flex">
                        <OptionSvg src="/assets/icons/rectangle-inactive.svg" alt="rectangle icon" />
                        <Icon className="pt-[2px] pl-1 !text-gray-warm" icon={faCaretDown} />
                    </div>
                )
            }
        }

        return '';
    }

    if (isSubStep){
        return (
            <div className={"flex gap-4 items-center"}>
                <div onClick={() => { startAnnotating('polygon'); scene.setIsSubStep(true)}} className={`h-[32px] w-[52px] rounded-sm flex items-center justify-center ${annotationProvider.tool === 'polygon' ? 'bg-blue-default' : 'bg-gray-200'}`}>
                    <div className="flex">
                        <OptionSvg src={`${(annotationProvider.tool === 'polygon') ? '/assets/icons/polygon-active.svg' : '/assets/icons/polygon-inactive.svg'}`} alt="polygon icon" />
                        <Icon className={`pt-[5px] pl-1 ${annotationProvider.tool === 'polygon' ? '!text-white' : '!text-gray-warm'}`} icon={faPlus} />
                    </div>
                </div>
                <div onClick={() => { startAnnotating('rect'); scene.setIsSubStep(true)}} className={`h-[32px] w-[52px] rounded-sm flex items-center justify-center ${annotationProvider.tool === 'rect' ? 'bg-blue-default' : 'bg-gray-200'}`}>
                    <div className="flex">
                        <OptionSvg src={`${(annotationProvider.tool === 'rect') ? '/assets/icons/rectangle-active.svg' : '/assets/icons/rectangle-inactive.svg'}`} alt="rectangle icon" />
                        <Icon className={`pt-[5px] pl-1 ${annotationProvider.tool === 'rect' ? '!text-white' : '!text-gray-warm'}`} icon={faPlus} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={`${(drawnLabel && (!isMobile || width > TabletWidth)) ? '!outline-blue-default !outline' : 'outline-none'} ${isMissing ? 'outline-red-500 outline' : ''} ${scene.expanded ? 'hidden' : 'bg-white rounded shadow px-4'}`} ref={stateRef}>
            {(isMobile || width < TabletWidth) ?
                <div className={"flex flex-col w-full gap-4 p-3"}>
                    <div className={"w-full flex flex-row justify-between items-center"}>
                        <div className='flex items-center flex-1 h-full pl-4 -ml-4 cursor-pointer'>{ i18nStringFromObject(label, 'title') }</div>
                        <div className={"flex gap-5 items-center"}>
                            <HtmlTooltipLight TransitionComponent={Fade} enterDelay={0} enterTouchDelay={0}  title={ i18nStringFromObject(label, 'description') } tooltipClasses={'bg-red-500'} leaveTouchDelay={1500}
                                              className={`!-mt-[4vw] mini-mobile:!-mt-[2.5vw] tablet:!-mt-0`} arrow>
                                <Button className='cursor-default'><Icon icon={faCircleInfo}/></Button>
                            </HtmlTooltipLight>
                            <Icon className={`cursor-pointer ${label.annotations && label.annotations.filter(a => a.type !== 'notFound').length ? 'text-black' : 'text-gray-300'}`} icon={faChevronDown} onClick={toggleDropdown} />
                        </div>
                    </div>
                    <div className={"w-full flex flex-row justify-between items-center"}>
                        <div className={"flex gap-4 items-center"}>
                            <div onClick={() => { startAnnotating('polygon'); scene.setIsSubStep(true) }} className={`h-[32px] w-[52px] rounded-sm flex items-center justify-center bg-gray-200`}>
                                <div className="flex">
                                    <OptionSvg src="/assets/icons/polygon-inactive.svg" alt="polygon icon" />
                                    <Icon className="pt-[5px] pl-1 !text-gray-warm" icon={faPlus} />
                                </div>
                            </div>
                            <div onClick={() => { startAnnotating('rect'); scene.setIsSubStep(true) }} className={`h-[32px] w-[52px] rounded-sm flex items-center justify-center bg-gray-200`}>
                                <div className="flex">
                                    <OptionSvg src="/assets/icons/rectangle-inactive.svg" alt="rectangle icon" />
                                    <Icon className="pt-[2px] pl-1 !text-gray-warm" icon={faPlus} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <label className={`text-xs font-light space-x-1 flex-1 flex items-center ${hasAnnotations() ? 'text-gray-400' : 'cursor-pointer'}`}>
                                <input type="checkbox" className="h-[16px] w-[16px] border-[1px] border-gray-warm rounded-none" checked={notFound} onChange={toggleNotFound} disabled={hasAnnotations()} />
                                <div className={"text-[12px]"}>{t('general.actions.not_found')}</div>
                            </label>
                        </div>
                    </div>
                </div>
                :
                <div className='h-13 flex tablet:flex-row items-center justify-around space-x-4 select-none'>
                    <div className='flex items-center flex-1 h-full pl-4 -ml-4 cursor-pointer' onClick={() => startAnnotating(toolState)}>{ i18nStringFromObject(label, 'title') }</div>
                    <Dropdown className='flex-1'>
                        <div className={`h-[32px] w-[52px] rounded-sm flex items-center justify-center ${drawnLabel ? 'bg-blue-default' : 'bg-white'}`}>{getType()}</div>
                        <ul className='bg-white flex flex-col w-[110px]'>
                            <button className='text-center text-xs font-light flex px-3 py-2.5 border-b border-gray-500 space-x-2' onClick={() => startAnnotating('rect')}>
                                <SvgIcon src="/assets/icons/rectangle.svg" alt="rectangle icon" />
                                <span>{t('general.entities.rectangle.singular')}</span>
                            </button>
                            <button className='text-center text-xs font-light flex items-center px-3 py-2.5 space-x-2' onClick={() => startAnnotating('polygon')}>
                                <SvgIcon src="/assets/icons/polygon.svg" alt="polygon icon" />
                                <span>{t('general.entities.polygon.singular')}</span>
                            </button>
                        </ul>
                    </Dropdown>

                    {/*Divider*/}
                        <div className={`h-[32px] w-[1px] bg-[#ededed]`}></div>
                    {/*Divider*/}

                    <label className={`text-xs font-light space-x-1 flex-1 flex items-center ${hasAnnotations() ? 'text-gray-400' : 'cursor-pointer'}`}>
                        <input type="checkbox" className="h-[16px] w-[16px] border-[1px] border-gray-warm rounded-none" checked={notFound} onChange={toggleNotFound} disabled={hasAnnotations()} />
                        <span>{t('general.actions.not_found')}</span>
                    </label>
                    <HtmlTooltipLight TransitionComponent={Fade} enterDelay={0} enterTouchDelay={0} title={ i18nStringFromObject(label, 'description') } tooltipClasses={'bg-red-500'} leaveTouchDelay={1500}
                                      className={`!-mt-[4vw] mini-mobile:!-mt-[2.5vw] tablet:!-mt-0`} arrow>
                        <Button className='cursor-default'><Icon icon={faCircleInfo}/></Button>
                    </HtmlTooltipLight>

                    <Icon className={`cursor-pointer ${label.annotations && label.annotations.filter(a => a.type !== 'notFound').length ? 'text-black' : 'text-gray-300'}`} icon={faChevronDown} onClick={toggleDropdown} />
                </div>
            }
            {dropdownOpen && <div className='h-[1px] bg-gray-500 w-full' />}
            {dropdownOpen &&
                <div className='py-4 space-y-1' id="Annotations">
                    {label.annotations?.filter(a => a.type !== 'notFound').map((annotation, key) => { return <Annotation key={key} annotation={annotation} /> })}
                </div>
            }
        </div>
    )
}

export default withTranslation()(AnnotableLabel)