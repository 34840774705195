import {WithTranslation, withTranslation} from "react-i18next";
import NavBar from "../components/NavBar";
import {H1, H4} from "../styled-components/Headings";
import React, {useEffect} from "react";
import Footer from "../components/Footer";
import {Header} from "../components/Header";

function ColofonPage({t}: WithTranslation) {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [])

    return (
        <div className='min-h-screen bg-gray-200 tablet-landscape:bg-white'>
            <NavBar/>

            <Header title={t('colofon.header_title')} />

            {/*Contentblock sectie*/}
            <div className={"container mx-auto my-[80px] space-y-5"}>
                <p className={'font-bl-pylon text-base text-black-text'}>{ t('colofon.intro') }</p>
                <div>
                    <H4 className={"pb-1"}>{ t('colofon.substantive_partners.title') }</H4>
                    <p className={'font-bl-pylon text-base text-black-text'} dangerouslySetInnerHTML={{__html: t('colofon.substantive_partners.text')}}></p>
                </div>
                <div>
                    <H4 className={"pb-1"}>{ t('colofon.design.title') }</H4>
                    <p className={'font-bl-pylon text-base text-black-text'} dangerouslySetInnerHTML={{__html: t('colofon.design.text')}}></p>
                </div>
                <div>
                    <H4 className={"pb-1"}>{ t('colofon.technical_development.title') }</H4>
                    <p className={'font-bl-pylon text-base text-black-text'} dangerouslySetInnerHTML={{__html: t('colofon.technical_development.text')}}></p>
                </div>
                <div>
                    <H4 className={"pb-1"}>{ t('colofon.video.title') }</H4>
                    <p className={'font-bl-pylon text-base text-black-text'} dangerouslySetInnerHTML={{__html: t('colofon.video.text')}}></p>
                </div>
            </div>
            <div className={"bg-white py-[70px]"}>
                <div className={"container mx-auto"}>
                    <div className={"pb-[50px]"}>
                        <H4 className={"pb-1"}>{ t('colofon.partners.title') }</H4>
                        <p className={'font-bl-pylon text-base text-black-text'} dangerouslySetInnerHTML={{__html: t('colofon.partners.text')}}></p>
                    </div>
                    <div className={"space-y-10 tablet-landscape:mx-0 tablet-landscape:space-y-0 w-full tablet-landscape:grid-cols-3 tablet-landscape:grid tablet-landscape:grid-row-2 gap-[50px]"}>
                        <img className="mx-auto tablet-landscape:mx-0" src="/assets/images/VL_LOGO.svg" alt="VriendenLoterij"/>
                        <img className="mx-auto tablet-landscape:mx-0" src="/assets/images/MFO_LOGO.svg" alt="VriendenLoterij"/>
                        <img className="mx-auto tablet-landscape:mx-0" src="/assets/images/AFK_logo.svg" alt="VriendenLoterij"/>
                        <img className="mx-auto tablet-landscape:mx-0" src="/assets/images/PBCF-oranje-logo.svg" alt="VriendenLoterij"/>
                        <img className="mx-auto tablet-landscape:mx-0" src="/assets/images/Logo-go-fonds.svg" alt="VriendenLoterij"/>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>


    )
}

export default withTranslation()(ColofonPage)
