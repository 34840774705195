import {useEffect, useRef, useState} from "react";

interface DropdownProps {
    children: JSX.Element[],
    hoverable?: boolean, 
    className?: String,
    listLeftSide?: boolean,
    overlay?: boolean
}

export default function Dropdown({children, hoverable, listLeftSide, overlay}: DropdownProps) {
    const [open, setOpen] = useState(false)
    const containerRef = useRef(null);

    useEffect(() => {
        function handleOutsideClick(event: MouseEvent) {
            const container = containerRef.current as unknown as HTMLElement
            if (container && !container.contains(event.target as Node)) {
                setOpen(false)
            }
        }

        document.addEventListener('mousedown', handleOutsideClick)

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [containerRef])

    const toggleDropdown = () => setOpen(!open)

    return (
        <>
            {/*{ (open && overlay) && <div className={"!absolute w-screen h-screen left-0 top-0 !z-[999] bg-black bg-opacity-50"}></div> }*/}
            <div className='relative' ref={containerRef}>
                <div onMouseEnter={hoverable ? () => setOpen(true) : null}
                     onMouseLeave={hoverable ? () => setOpen(false): null}
                     onClick={hoverable ? null : toggleDropdown}>
                    {children[0]}
                </div>
                <div className={`!absolute ${listLeftSide && 'right-0'} top-full min-w-full z-[999] shadow-dropdown ${!open ? 'hidden' : ''}`}
                     onMouseEnter={hoverable ? () => setOpen(true) : null}
                     onMouseLeave={hoverable ? () => setOpen(false): null}
                >
                    {children.slice(1).map((child, key) => {
                        return <div key={key} onClick={toggleDropdown}>{child}</div>
                    })}
                </div>
            </div>
        </>

    )
}