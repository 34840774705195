import {Batch} from "../types/entities";
import {useAuth} from "../providers/AuthProvider";
import {useTranslation} from "react-i18next";
import {Tag} from "../styled-components/Tag";
import {H3, H4, H5} from "../styled-components/Headings";
import {Link} from "react-router-dom";
import {Button} from "../styled-components/Button";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import Icon from "./Icon";
import Dropdown from "./Dropdown";
import {TabletWidth} from "../constants/ScreenWidth";
import useWindowDimensions from "../hooks/useWindowDimensions";
import React, {useEffect, useState} from "react";
import {i18nStringFromObject} from "../plugins/i18n";

const PersonalBatchList = ({batches}: { batches: Batch[] }) => {
    const { t } = useTranslation()
    const {width} = useWindowDimensions()
    const selectedLanguage = localStorage.getItem('language');

    useEffect(() => {
        batches.sort((batchA, batchB) => {
            const projectTitleA = batchA.projectTitle[selectedLanguage] || "";
            const projectTitleB = batchB.projectTitle[selectedLanguage] || "";

            if (projectTitleA < projectTitleB) {
                return -1;
            } else if (projectTitleA > projectTitleB) {
                return 1;
            } else {

                const titleA = batchA.title[selectedLanguage] || "";
                const titleB = batchB.title[selectedLanguage] || "";

                if (titleA < titleB) {
                    return -1;
                } else if (titleA > titleB) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
    }, [batches]);



    const shouldShowBatchPrivatePublicState = (): boolean => {
        return batches.some(b => b.isPrivate);
    }

    return <>{ batches.map((batch, key) => {
        return (
            <div className={"flex flex-col"}>
                <div key={key} className="flex min-h-[8rem] w-full tablet:border-0 border-[1px] border-[#DDDDDD]">
                    <div className='bg-gray-500 w-1/3 tablet:w-auto tablet:min-w-[280px] relative'>
                        <img className='absolute top-0 left-0 w-full h-full object-cover' src={batch.thumbnail ? batch.thumbnail : `../assets/images/batch-placeholder.png`} alt=""/>
                        <Tag
                            className='px-2.5 relative z-[2]'>{t(`project.batches.status.${batch.status?.toLowerCase()}`)}</Tag>
                    </div>
                    <div className='flex flex-col tablet:w-full w-2/3 '>
                        <div className='flex-1 flex flex-col items-start justify-center pb-5 tablet:pb-0 pt-5 pl-5 tablet:space-y-2'>
                            <div className='flex items-center space-x-5'>
                                <div className={"flex flex-col tablet:flex-row tablet:space-x-8 space-y-2 tablet:space-y-0 tablet:items-end"}>
                                    <H3>{ i18nStringFromObject(batch, 'projectTitle') }</H3>
                                    <H4 className={'pb-[1px]'}>{ i18nStringFromObject(batch, 'title') }</H4>
                                </div>
                                { shouldShowBatchPrivatePublicState() && <>
                                    <div className='h-3/5 w-[1px] bg-black'/>
                                    <H5>{ batch.isPrivate ? t('project.batches.access.private') : t('project.batches.access.public')}</H5>
                                </>}
                            </div>
                            <p className={"mb-5 tablet:mb-0"}>{ batch.imageCount } {t('general.entities.photo.plural')}</p>
                            <div className={'w-full flex justify-between items-end'}>
                                <div className={'flex flex-col items-start tablet:space-y-1'}>
                                    <span className={'font-bold font-mabry'}>{ t('profile.my_batches.batches.your_contribution') }</span>
                                    {batch.statistics.userPhotoCount !== null && <div className={'tablet:bg-gray-100 tablet:py-3 tablet:px-4 flex space-x-2 items-center'}>
                                        <p className={'tablet:font-bold tablet:font-mabry mb-[1px]'}>{ batch.statistics.userPhotoCount} { t('profile.my_batches.batches.photos_reviewed') }</p>
                                    </div>}
                                </div>

                                {width > TabletWidth && <StartBatchButton batch={batch}/>}
                            </div>

                        </div>
                    </div>
                </div>
                <div className={"relative bottom-3"}>
                    {window.innerWidth <= TabletWidth && <StartBatchButton batch={batch} /> }
                </div>
            </div>
        )
    })}</>
}

const StartBatchButton = ({ batch }: { batch: Batch }) => {
    const { user } = useAuth();
    const { t } = useTranslation();
    const [displayWarningPopup, setDisplayWarningPopup] = useState(false);
    const [url, setUrl] = useState('');

    useEffect(() => {
        if (displayWarningPopup) {
            document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        } else {
            document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        }
    }, [displayWarningPopup]);

    const startDisabled = (): boolean => {
        if (batch.status !== 'Running') {
            return true;
        }

        if (user?.roles?.length) {
            if (batch.canStart.length > 0) {
                return false;
            }
        } else {
            if (batch.canStart.includes('annotate')) {
                return false;
            }
        }

        return true;
    }

    if (user && user.roles && user.roles.length) {
        return (
            <>
                <div className='w-auto mt-5 mb-2'>
                    <Dropdown>
                        <Button $color={'light-primary'} disabled={startDisabled()} $small className=' px-6 w-full !pr-5'>
                            <div className={"mx-auto items-center space-x-4"}>
                                <span>{batch.participated ? t('general.actions.continue') : t('general.actions.start')}</span>
                                <Icon icon={faChevronDown} />
                            </div>
                        </Button>
                        <div className={'bg-white flex flex-col'}>
                            <>
                                <Link
                                    to={`/${batch.type.toLowerCase()}/${batch.phase === 'Fase 1' ? 'annotate' : 'validate'}/${batch.id}`}
                                    className={`text-center text-xs flex items-center px-4 py-3 ${!batch.canStart.includes('annotate') && 'pointer-events-none text-gray-400'}`}>
                                    {t("project.batches.steps.step_1")}
                                </Link>
                            </>
                            { user.roles.includes('controleur') &&
                                <>
                                    <Link
                                        to={`/${batch.type.toLowerCase()}/check/${batch.id}`}
                                        className={`text-center text-xs flex items-center px-4 py-3 ${!batch.canStart.includes('check') && 'pointer-events-none text-gray-400'}`}>
                                        {t("project.batches.steps.step_2")}
                                    </Link>
                                </>
                            }
                            { user.roles.includes('next_level_controleur') &&
                                <>
                                    <Link
                                        to={`/${batch.type.toLowerCase()}/next-level-check/${batch.id}`}
                                        className={`text-center text-xs flex items-center px-4 py-3 ${!batch.canStart.includes('next-level-check') && 'pointer-events-none text-gray-400'}`}>
                                        {t("project.batches.steps.step_3")}
                                    </Link>
                                </>
                            }
                        </div>
                    </Dropdown>
                </div>
            </>

        )
    }

    return (
        <>
            { user && <div className='mt-5 mb-2'>
                <Link to={`/${batch.type.toLowerCase()}/${batch.phase === 'Fase 1' ? 'annotate' : 'validate'}/${batch.id}`}>
                    <Button disabled={!batch.canStart.includes('annotate')} $color={'light-primary'} $small className='px-6 mx-auto w-full justify-center'>{
                        batch.participated ? t('general.actions.continue') : t('general.actions.start')
                    }</Button>
                </Link>
            </div> }
        </>
    )
}

export default PersonalBatchList