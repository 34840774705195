import {WithTranslation, withTranslation} from "react-i18next";
import NavBar from "../components/NavBar";
import {H2, H3} from "../styled-components/Headings";
import Input from "../components/Input";
import {Button} from "../styled-components/Button";
import React, {useEffect, useState} from "react";
import {Header} from "../components/Header";
import {TabletWidth} from "../constants/ScreenWidth";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Api from "../Api";
import Footer from "../components/Footer";
import Textarea from "../components/Textarea";

function ContactPage({t}: WithTranslation) {
    const [success, setSuccess] = useState(false)
    const contactFormSchema = yup.object({
        firstname: yup.string().required(t('contact.form.firstname_required_error')),
        surname: yup.string().required(t('contact.form.lastname_required_error')),
        email: yup.string().email().required(t('contact.form.email_required_error')),
        message: yup.string().required(t('contact.form.message_required_error'))
    })

    const [registerFirstName, setRegisterFirstName] = useState('');
    const [registerLastName, setRegisterLastName] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerMessage, setRegisterMessage] = useState('');

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(contactFormSchema)
    })

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [])

    useEffect(() => {
        console.log(registerMessage)
    }, [registerMessage]);


    const onSubmit = ({firstname, surname, email} : {firstname: string, surname: string, email: string}) => {
        Api.post('/contact',
            {
                firstname: firstname,
                surname: surname,
                email: email,
                message: registerMessage
            },
            () => {
                setSuccess(true)
            },
            null,
            null,
            null
        )
    }

    const handleMessage = event => {
        setRegisterMessage(event.target.value)
    }

    const getError = (key: string): null | string => {
        if (errors[key]) {
            return errors[key].message as unknown as string
        } else {
            return null
        }
    }

    return (
        <div className='min-h-screen bg-gray-200'>
            <NavBar />
            { window.innerWidth <= TabletWidth ?
                <div className={"container mx-auto py-[40px] flex flex-col"}>
                    <div className={"flex flex-col space-y-2"}>
                        <H2 className={"text-black"}>{ t('contact.block.title')?.toUpperCase() }</H2>
                        <p className={"font-mabry font-normal text-[14px] leading-4"} dangerouslySetInnerHTML={{__html: t('contact.block.text')}}/>
                    </div>
                    { !success &&
                        <form onSubmit={handleSubmit(onSubmit)} className='w-full mt-[40px]'>
                            <div className='w-full flex flex-col'>
                                <label className='font-mabry font-semibold mb-1'>{t('contact.form.firstname_label')}</label>
                                <Input type='firstname'
                                       name={'firstname'}
                                       placeholder={t('contact.form.firstname_placeholder')}
                                       defaultValue={registerFirstName && registerFirstName}
                                       onInput={(event) => setRegisterFirstName(event.target.value)}
                                       onChange={(event) => setRegisterFirstName(event.target.value)}
                                       register={ registerFirstName && register('firstname', {value: registerFirstName})}
                                       error={getError('firstname')}
                                />
                            </div>
                            <div className='w-full mt-3 flex flex-col'>
                                <label className='font-mabry font-semibold mb-1'>{t('contact.form.lastname_label')}</label>
                                <Input type='surname'
                                       placeholder={t('contact.form.lastname_placeholder')}
                                       name={'surname'}
                                       defaultValue={registerLastName && registerLastName}
                                       onInput={(event) => setRegisterLastName(event.target.value)}
                                       onChange={(event) => setRegisterLastName(event.target.value)}
                                       register={ registerLastName && register('surname', {value: registerLastName})}
                                       error={getError('surname')}
                                />
                            </div>
                            <div className='flex mt-3 flex-col'>
                                <label className='font-mabry font-semibold mb-1'>{t('contact.form.email_label')}</label>
                                <Input type='email'
                                       placeholder={t('contact.form.email_placeholder')}
                                       name={'email'}
                                       defaultValue={registerEmail && registerEmail}
                                       onInput={(event) => setRegisterEmail(event.target.value)}
                                       onChange={(event) => setRegisterEmail(event.target.value)}
                                       register={ registerEmail && register('email', {value: registerEmail})}
                                       error={getError('email')}
                                />
                            </div>
                            <div className='flex mt-3 flex-col'>
                                <label className='font-mabry font-semibold mb-1'>{t('contact.form.message_label')}</label>
                                <Textarea className="min-h-[130px] align-top"
                                          name={'firstName'}
                                          defaultValue={registerMessage && registerMessage}
                                          onInput={handleMessage}
                                          onChange={handleMessage}
                                          onKeyUp={handleMessage}
                                          register={ registerMessage && register('message', {value: registerMessage})}
                                          error={getError('message')}
                                />
                            </div>
                            <div className='flex flex-col items-center mt-[60px]'>
                                <Button type='submit' $color='light-primary' className='px-20 w-full justify-center'>{ t('contact.form.send') }</Button>
                            </div>
                        </form>
                    }
                    { success &&
                        <div className={"w-full mt-4 text-center"}>
                            <H2>{ t('contact.form.message_is_sent_message') }</H2>
                        </div>
                    }
                </div>
                :
                <>
                    <Header title={t('contact.header_title')}/>
                    <div className='transform -translate-y-12'>
                        <div className='container mx-auto space-y-4'>
                            <div className='min-h-[512px] flex'>
                                <div className='w-5/12 bg-primary-light p-8 md:p-12 lg:p-24 space-y-6'>
                                    <H3 className='uppercase text-white'>{ t('contact.block.title') }</H3>
                                    <p className='text-white font-bl-pylon text-base' dangerouslySetInnerHTML={{__html: t('contact.block.text')}} />
                                </div>
                                {
                                    !success &&
                                    <form onSubmit={handleSubmit(onSubmit)} className='w-7/12 bg-white p-24'>
                                        <div className='flex flex-row justify-evenly w-12/12 space-x-3'>
                                            <div className='w-6/12 mb-3 flex flex-col'>
                                                <label className='font-light mb-1'>{t('contact.form.firstname_label')}</label>
                                                <Input type='firstname'
                                                       name={'firstname'}
                                                       placeholder={t('contact.form.firstname_placeholder')}
                                                       defaultValue={registerFirstName && registerFirstName}
                                                       onInput={(event) => setRegisterFirstName(event.target.value)}
                                                       onChange={(event) => setRegisterFirstName(event.target.value)}
                                                       register={ registerFirstName && register('firstname', {value: registerFirstName})}
                                                       error={getError('firstname')}
                                                />
                                            </div>
                                            <div className='w-6/12 mb-3 flex flex-col'>
                                                <label className='font-light mb-1'>{t('contact.form.lastname_label')}</label>
                                                <Input type='surname'
                                                       placeholder={t('contact.form.lastname_placeholder')}
                                                       name={'surname'}
                                                       defaultValue={registerLastName && registerLastName}
                                                       onInput={(event) => setRegisterLastName(event.target.value)}
                                                       onChange={(event) => setRegisterLastName(event.target.value)}
                                                       register={ registerLastName && register('surname', {value: registerLastName})}
                                                       error={getError('surname')}
                                                />
                                            </div>
                                        </div>
                                        <div className='mb-3 flex flex-col'>
                                            <label className='font-light mb-1'>{t('contact.form.email_label')}</label>
                                            <Input type='email'
                                                   placeholder={t('contact.form.email_placeholder')}
                                                   name={'email'}
                                                   defaultValue={registerEmail && registerEmail}
                                                   onInput={(event) => setRegisterEmail(event.target.value)}
                                                   onChange={(event) => setRegisterEmail(event.target.value)}
                                                   register={ registerEmail && register('email', {value: registerEmail})}
                                                   error={getError('email')}
                                            />
                                        </div>
                                        <div className='flex flex-col'>
                                            <label className='font-light mb-1'>{t('contact.form.message_label')}</label>
                                            <Textarea className="min-h-[130px] align-top"
                                                      name={'firstName'}
                                                      defaultValue={registerMessage && registerMessage}
                                                      onInput={handleMessage}
                                                      onChange={handleMessage}
                                                      onKeyUp={handleMessage}
                                                      register={registerMessage && register('message', {value: registerMessage})}
                                                      error={getError('message')}
                                            />
                                        </div>
                                        <div className='flex flex-col items-center space-y-3 mt-8'>
                                            <Button type='submit' $color='light-primary' className='px-20'>{ t('contact.form.send') }</Button>
                                        </div>
                                    </form>
                                }
                                { success &&
                                    <div className='w-7/12 my-auto p-24'>
                                        <H2 className={"text-black text-center"}>{ t('contact.form.message_is_sent_message') }</H2>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }

            <Footer />
        </div>
    )
}

export default withTranslation()(ContactPage)