import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {useScene} from "../providers/SceneProvider";
import {HtmlTooltipLight} from "./HtmlTooltipLight";
import {Fade} from "@mui/material";
import {Label} from "../types/entities";
import Icon from "./Icon";
import {RadioButton} from "./RadioButton";
import {i18nStringFromObject} from "../plugins/i18n";
import {Button} from "../styled-components/Button";

interface LabelSkeletonProps extends WithTranslation {
    label: Label,
    isMissing: boolean
}

function CheckableSceneLabel({ t, i18n, label, isMissing }: LabelSkeletonProps) {
    const scene = useScene();

    const setCheckValue = (event: React.MouseEvent<HTMLInputElement>) => {
        const newLabelArr = Object.assign([], scene.selectedLabel ?? [])
        const foundLabelIndex = newLabelArr.findIndex(l => l.id === label.id)

        if (foundLabelIndex >= 0) {
            newLabelArr[foundLabelIndex] = {...label, checkStatus: (event.target as HTMLInputElement).value === 'Goed'};
        } else {
            newLabelArr.push({...label, checkStatus: (event.target as HTMLInputElement).value === 'Goed'})
        }

        newLabelArr.forEach(l => {
                if (l.id === label.id) {
                    console.log((event.target as HTMLInputElement).value);
                    console.log((event.target as HTMLInputElement).value === 'Goed');
                    l.checkValue = (event.target as HTMLInputElement).value === 'Goed'
                }
        })

        scene.setSelectedLabel(newLabelArr);
    }

/*
    useEffect(() => {
        if (selectedOption !== null) {
            let newLabelArr = Object.assign([], scene.selectedLabel ?? []);
            const foundLabelIndex = newLabelArr.findIndex(l => l.id === label.id)

            if (foundLabelIndex >= 0) {
                newLabelArr[foundLabelIndex] = {...label, checkStatus: selectedOption === 'Goed'};
            } else {
                newLabelArr.push({...label, checkStatus: selectedOption === 'Goed'})
            }

            scene.setSelectedLabel(newLabelArr);
        }
    }, [selectedOption])
*/

    return (
        <div className={`min-h-[52px] flex flex-row items-center bg-white ${isMissing ? 'outline-red-600 outline' : ''} rounded shadow px-4 w-full px-4 bg-black}`}>
           <div className='min-h-13 w-full flex select-none justify-between items-center space-x-2'>
                <div className='flex items-center h-full pl-4'>{ i18nStringFromObject(label, 'title') }</div>
                <div className="flex space-x-6 items-center">
                    <RadioButton
                        name={`rdfsfdsdfs`}
                        activeElement={<img src='/assets/images/RadioSuccess.svg' />}
                        value={'Goed'}
                        checked={scene.selectedLabel?.find(sl => sl.id === label.id)?.checkStatus === true}
                        onClick={setCheckValue}
                        label={<div>Goed</div>}
                    />
                    <RadioButton
                        name={`sdasdadsasad`}
                        activeElement={<img src='/assets/images/RadioDanger.svg' />}
                        value={'Afkeuren'}
                        checked={scene.selectedLabel?.find(sl => sl.id === label.id)?.checkStatus === false}
                        onClick={setCheckValue}
                        label={<div>Afkeuren</div>}
                    />
                    <HtmlTooltipLight TransitionComponent={Fade} enterDelay={0} enterTouchDelay={0}  title={ i18nStringFromObject(label, 'description') } tooltipClasses={'bg-red-500'} leaveTouchDelay={1500}
                                      className={`!-mt-[4vw] mini-mobile:!-mt-[2.5vw] tablet:!-mt-0`} arrow>
                        <Button className='cursor-default'><Icon icon={faCircleInfo}/></Button>
                    </HtmlTooltipLight>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(CheckableSceneLabel)