import {Trans, WithTranslation, withTranslation} from "react-i18next";
import NavBar from "../components/NavBar";
import {H1, H2, H3} from "../styled-components/Headings";
import {Button} from "../styled-components/Button";
import Input from "../components/Input";
import * as yup from 'yup';
import Api from "../Api";
import {useEffect, useState} from "react";
import {useAuth} from "../providers/AuthProvider";
import {User} from "../types/entities";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useNavigate} from "react-router-dom";
import {TabletWidth} from "../constants/ScreenWidth";
import Footer from "../components/Footer";
import ErrorPopup from "../components/ErrorPopup";
import {AxiosError} from "axios";


function LoginPage({t}: WithTranslation) {
    const [registerError, setRegisterError] = useState(null);

    const registerSchema = yup.object({
        firstName: yup.string().required(t('register.form.firstname_required_error')),
        middleName: yup.string().nullable(),
        lastName: yup.string().required(t('register.form.lastname_required_error')),
        email: yup.string().email().required(t('register.form.email_required_error')),
        password: yup.string().required(t('register.form.password_required_error')).min(8, t('register.form.password_minimum_length_error')),
        gdprAccept: yup.bool().oneOf([true], t('register.form.accept_privacy_terms_required_error'))
    })
    const [registerFirstName, setRegisterFirstName] = useState('');
    const [registerMiddleName, setRegisterMiddleName] = useState('');
    const [registerLastName, setRegisterLastName] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');

    const auth = useAuth();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }} = useForm({
        resolver: yupResolver(registerSchema)
    })

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [])

    const onSubmit = ({firstName, middleName, lastName, email, password, gdprAccept} : {firstName: string, middleName: string, lastName: string, email: string, password: string, gdprAccept: boolean}) => {
        Api.post('/register',
            {
                firstName: firstName,
                middleName: middleName,
                lastName: lastName,
                email: email,
                password: password,
                gdprAccept: gdprAccept
            },
            ({data}: {data: User}) => {
                auth.login(data)
                continueNavigate();
            },
            null,
            null,
            (error: AxiosError) => {
                if (error.response.status === 400) {
                    if (error.response.data.error.includes("overwrite existing member")){
                        setRegisterError('existingMember')
                    } else {
                        setRegisterError('badRequest')
                    }
                }
            }
        )
    }

    const continueNavigate = () => {
        const params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
        if ('return' in params) {
            navigate(params.return)
        } else {
            navigate('/profile')
        }
    }

    const getError = (key: string): null | string => {
        if (errors[key]) {
            return errors[key].message as unknown as string
        }

        return null
    }

    return (
        <div className='min-h-screen bg-gray-200'>
            <NavBar />
            { window.innerWidth <= TabletWidth ?
            <div className={"container mx-auto my-[40px] flex flex-col"}>
                <div className={"flex flex-col space-y-2"}>
                    <H2 className={"text-black"}>{ t('register.header_title') }</H2>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className='w-full mt-[40px]'>
                    <div className={"space-y-[10px]"}>
                        <div>
                            <label className='font-mabry font-semibold text-[14px] leading-4 mb-1'>{t('register.form.firstname_label')}</label>
                            <Input type='firstName' name={'firstName'} defaultValue={registerFirstName && registerFirstName} onInput={(event) => setRegisterFirstName(event.target.value)} onChange={(event) => setRegisterFirstName(event.target.value)} register={ registerFirstName && register('firstName', {value: registerFirstName})} error={getError('firstName')} />
                        </div>
                        <div>
                            <label className='font-mabry font-semibold text-[14px] leading-4 mb-1'>{t('register.form.middlename_label')}</label>
                            <Input type='middleName' name={'middleName'} defaultValue={registerMiddleName && registerMiddleName} onInput={(event) => setRegisterMiddleName(event.target.value)} onChange={(event) => setRegisterMiddleName(event.target.value)} register={ registerMiddleName && register('middleName', {value: registerMiddleName})} error={getError('middleName')} />
                        </div>
                        <div>
                            <label className='font-mabry font-semibold text-[14px] leading-4 mb-1'>{t('register.form.lastname_label')}</label>
                            <Input type='lastName' name={'lastName'} defaultValue={registerLastName && registerLastName} onInput={(event) => setRegisterLastName(event.target.value)} onChange={(event) => setRegisterLastName(event.target.value)} register={ registerLastName && register('lastName', {value: registerLastName})} error={getError('lastName')} />
                        </div>
                        <div>
                            <label className='font-mabry font-semibold text-[14px] leading-4 mb-1'>{t('register.form.email_label')}</label>
                            <Input type='email' name={'email'} defaultValue={registerEmail && registerEmail} onInput={(event) => setRegisterEmail(event.target.value)} onChange={(event) => setRegisterEmail(event.target.value)} register={ registerEmail && register('email', {value: registerEmail})} error={getError('email')} />
                        </div>
                        <div>
                            <label className='font-mabry font-semibold text-[14px] leading-4 mb-1'>{t('register.form.password_label')}</label>
                            <Input type="password" name={'password'} defaultValue={registerPassword && registerPassword} onInput={(event) => setRegisterPassword(event.target.value)} onChange={(event) => setRegisterPassword(event.target.value)} register={registerPassword && register('password', {value: registerPassword})} error={getError('password')}/>
                        </div>
                    </div>

                    <div className='mt-5'>
                        <label className='font-light text-sm'>
                            <input className='mr-4 h-full' type='checkbox' {...register('gdprAccept')}/>
                            <span className={"font-mabry font-normal text-[14px]"}>
                                        <Trans className={"font-mabry font-normal text-[14px]"} i18nKey='register.form.accept_privacy_terms' components={{
                                            terms: <a className='text-blue-500 underline' target="_blank" href="/terms" />
                                        }} />
                                {t('')}
                                    </span>
                        </label>
                        <small className='text-red-500 font-light text-xs'>{errors.gdprAccept?.message}</small>
                    </div>
                    <div className='flex flex-col items-center space-y-3 mt-[60px]'>
                        <Button $color='light-primary' className='px-20 w-full justify-center'>{t('register.actions.register')}</Button>
                    </div>
                </form>
            </div>
                :
            <>
                <div className='h-[270px] w-full bg-gray-500'
                     style={{backgroundImage: "url(./assets/images/TTP-header-website.jpeg)"}}>
                    <div className='container mx-auto h-full relative'>
                        <H1 className='uppercase bg-white inline-block p-2 absolute bottom-16'>{ t('register.header_title') }</H1>
                    </div>
                </div>

                <div className='transform -translate-y-12'>
                    <div className='container mx-auto space-y-4'>
                        <div className='tablet:min-h-[512px] flex'>
                            <div className='w-5/12 bg-primary-light p-8 md:p-12 lg:p-24 space-y-6'>
                                <H3 className='uppercase text-white'>{ t('register.block.title') }</H3>
                                <p className='text-white font-bl-pylon text-base'>{ t('register.block.text') }</p>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} className='w-7/12 bg-white p-8 md:p-12 lg:p-24'>
                                <div className='mb-3'>
                                    <label className='font-light mb-1'>{ t('register.form.firstname_label') }</label>
                                    <Input type='firstName' name={'firstName'} defaultValue={registerFirstName && registerFirstName} onInput={(event) => setRegisterFirstName(event.target.value)} onChange={(event) => setRegisterFirstName(event.target.value)} register={ registerFirstName && register('firstName', {value: registerFirstName})} error={getError('firstName')} />
                                </div>
                                <div className='grid grid-cols-2 gap-3 mb-3'>
                                    <div className='col-span-2 lg:col-span-1'>
                                        <label className='font-light mb-1'>{ t('register.form.middlename_label') }</label>
                                        <Input type='middleName' name={'middleName'} defaultValue={registerMiddleName && registerMiddleName} onInput={(event) => setRegisterMiddleName(event.target.value)} onChange={(event) => setRegisterMiddleName(event.target.value)} register={ registerMiddleName && register('middleName', {value: registerMiddleName})} error={getError('middleName')} />
                                    </div>
                                    <div className='col-span-2 lg:col-span-1'>
                                        <label className='mb-1'>{ t('register.form.lastname_label') }</label>
                                        <Input type='lastName' name={'lastName'} defaultValue={registerLastName && registerLastName} onInput={(event) => setRegisterLastName(event.target.value)} onChange={(event) => setRegisterLastName(event.target.value)} register={ registerLastName && register('lastName', {value: registerLastName})} error={getError('lastName')} />
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <label className='mb-1'>{ t('register.form.email_label') }</label>
                                    <Input type='email' name={'email'} defaultValue={registerEmail && registerEmail} onInput={(event) => setRegisterEmail(event.target.value)} onChange={(event) => setRegisterEmail(event.target.value)} register={ registerEmail && register('email', {value: registerEmail})} error={getError('email')} />
                                </div>
                                <div>
                                    <label className='mb-1'>{ t('register.form.password_label') }</label>
                                    <Input type="password" name={'password'} defaultValue={registerPassword && registerPassword} onInput={(event) => setRegisterPassword(event.target.value)} onChange={(event) => setRegisterPassword(event.target.value)} register={registerPassword && register('password', {value: registerPassword})} error={getError('password')}/>
                                </div>
                                <div className='mt-3'>
                                    <label className='font-light text-sm'>
                                        <input className='mr-4 h-full' type='checkbox' {...register('gdprAccept')}/>
                                        <span>
                                        <Trans i18nKey='register.form.accept_privacy_terms' components={{
                                            terms: <a className='text-blue-500 underline' target="_blank" href="/terms" />
                                        }} />
                                    </span>
                                    </label>
                                    <small className='text-red-500 font-light text-xs'>{errors.gdprAccept?.message}</small>
                                </div>
                                <div className='flex flex-col items-center space-y-3 mt-8'>
                                    <Button $color='light-primary' className='px-20'>{t('register.actions.register')}</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
            }

            <Footer />
            { registerError &&
                <ErrorPopup message={`${registerError === 'existingMember' ? 'It seems this email adres is already taken. Try again with a different emailadres' : 'There seems to be something wrong. Try again later'}`} isOpen={true} onClose={() => setRegisterError(null)} />
            }
        </div>
    )
}

export default withTranslation()(LoginPage)