import SceneHeader from "./SceneHeader";
import SceneBottomBar from "./SceneBottomBar";
import React, {useEffect, useRef, useState} from "react";
import {useScene} from "../../providers/SceneProvider";
import {withTranslation, WithTranslation} from "react-i18next";
import CheckableObjectLabel from "../CheckableObjectLabel";
import {useAnnotation} from "../../providers/AnnotationProvider";
import Api from "../../Api";
import {AxiosError} from "axios";
import {Button} from "../../styled-components/Button";
import i18n from "i18next";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import SanitizeHTML from "../SanitizeHTML";
import SceneErrorPopup from "./SceneErrorPopup";
import {isMobile, mobileModel, mobileVendor} from "react-device-detect";
import {TabletWidth} from "../../constants/ScreenWidth";
import BottomDrawer from "../BottomDrawer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {i18nStringFromObject} from "../../plugins/i18n";

function ObjectValidateContainer({t}: WithTranslation) {
    const scene = useScene();
    const annotationProvider = useAnnotation();
    const [missingLabels, setMissingLabels] = useState([]);
    const [showPostError, setShowPostError] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const {width} = useWindowDimensions()

    useEffect(() => {
        localStorage.setItem("notes", '');
        scene.setSkipOption(null)
    }, [])

    const canContinue = (): boolean => {
        if (annotationProvider.checkLabels) {
            let canContinue = true;

            annotationProvider.checkLabels.forEach(l => {
                if (!l.objects.every(o => o.checkValue != null)) {
                    canContinue = false;
                }
            })

            return canContinue;
        }

        return false;
    }

    const submit = (skip = false) => {

        const values = {}

        if (skip) {
            if (scene.skipOption !== null) {
                values['skip'] = {
                    annotations: [{
                        type: 'skip',
                        content: scene.skipOption
                    }]
                }
            }
        }

        if (scene.explicit) {
            values['explicit'] = {
                annotations: [{
                    type: 'explicit',
                }]
            }
        }

        if (scene.isChecked && scene.remark !== "") {
            values['remark'] = {
                annotations: [{
                    type: 'remark',
                    content: scene.remark
                }]
            }
        }

        if (Object.entries(values).length) {
            Api.post('/attach-annotations-to-photo',
                {
                    photoId: scene.image.id,
                    labels: values
                },
                () => {
                    if (skip) {
                        scene.next(null)
                    }
                },
                null,
                null,
                (error: AxiosError) => {
                    if (skip) {
                        setShowPostError(true);
                    }
                })
        }

        if (!skip) {

            let annotations = [];
            annotationProvider.checkLabels.forEach(l => {
                annotations = [...annotations, ...l.objects.map(o => ({id: o.id, valid: o.checkValue}))]
            })

            setMissingLabels([])

            annotationProvider.checkLabels.forEach(l => {
                if (!l.objects.every(o => o.checkValue != null)) {
                    setMissingLabels(missingLabels => [...missingLabels, l.id])
                }
            })


            if (canContinue()) {
                Api.post('/validated-ai-objects',
                    {
                        photoId: scene.image.id,
                        annotations: annotations,
                        device: mobileVendor + ' ' + mobileModel
                    },
                    () => {
                        scene.next(null)
                    },
                    null,
                    null,
                    (error: AxiosError) => {
                        setShowPostError(true);
                    })
                setIsDisabled(true)
            }
        }
    }

    const Skip = () => {
        const [skipOption, setSkipOption] = useState(null)

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSkipOption((event.target as HTMLInputElement).value)
        }

        const send = () => {
            if (skipOption !== null) {
                scene.setSkipOption(skipOption)
            }
        }

        useEffect(() => {
            if (scene.skipOption !== null) {
                submit(true)
            }
        }, [])

        return (
            <div className={"outro w-full"}>
                <div className={`h-full tablet:min-w-[400px] w-full my-auto space-y-4 flex flex-col justify-center`}>
                    <div className="h-full my-auto space-y-4 flex flex-col justify-center">
                        <h1>{t("annotate.popups.skip.title")}</h1>
                        <p>{t("annotate.popups.skip.text")}</p>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                onChange={handleChange}
                                value={skipOption}
                            >
                                <FormControlLabel value="dont-know" control={<Radio size={"small"}/>} label={<p>{t("annotate.popups.skip.i_dont_know")}</p>} />
                                <FormControlLabel value="unclear-scan" control={<Radio size={"small"}/>} label={<p>{t("annotate.popups.skip.unclear_scan")}</p>}/>
                            </RadioGroup>
                        </FormControl>
                        <div className={"flex flex-col tablet:flex-row tablet:justify-center w-full gap-2 pt-3"}>
                            <Button disabled={!skipOption} onClick={() => send()}
                                    $color={'light-primary'}>{t("annotate.popups.skip.actions.skip")}</Button>
                            <Button onClick={scene.toggleSkipRemark} $color={'light-white'}>{t("general.actions.cancel")}</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const TutorialAnnotate = () => {
        const scene = useScene();

        return (
            <div className={"outro"}>
                <div className="h-full tablet:min-w-[400px] my-auto space-y-4 flex flex-col justify-center">
                    <div className="h-full my-auto space-y-4 flex flex-col justify-center">
                        <SanitizeHTML>
                            { i18nStringFromObject(scene.batch, 'batchIntroReview') }
                        </SanitizeHTML>
                    </div>
                </div>
            </div>
        )
    }

    const Remark = () => {
        const t = i18n.t;
        const input = useRef(null)

        const addRemark = () => {
            if (input && input.current) {
                scene.setRemark(input.current.value)
            }
            scene.setRemarkSubmitted(true)
        }

        return (
            <div className={"outro w-full"}>
                {scene.remarkSubmitted ? (
                    <div className={`h-full tablet:min-w-[400px] my-auto space-y-4 flex flex-col justify-center`}>
                        <div className="h-full my-auto space-y-4 flex flex-col justify-center">
                            <h1>{t("annotate.popups.remark.after_sent.title")}</h1>
                            <p>{t("annotate.popups.remark.after_sent.text")}</p>
                        </div>
                    </div>
                ) : (
                    <div className={`tablet:min-w-[400px] my-auto space-y-4 flex flex-col justify-center`}>
                        <h1>{t("annotate.popups.remark.title")}</h1>
                        <p dangerouslySetInnerHTML={{__html: t("annotate.popups.remark.text")}} />
                        <label className={`text-xs font-light space-x-1 flex-1 flex items-center`}>
                            <input id="explicit" type="checkbox"
                                   className="h-[16px] w-[16px] border-[1px] border-gray-warm rounded-none"
                                   onChange={() => scene.setExplicit(!scene.explicit)} checked={scene.explicit}
                            />
                            <span>{t("annotate.popups.remark.options.mark_as_explicit_content")}</span>
                        </label>
                        <label className={`text-xs font-light space-x-1 flex-1 flex items-center`}>
                            <input id="other-remark" type="checkbox"
                                   className="h-[16px] w-[16px] border-[1px] border-gray-warm rounded-none"
                                   onChange={() => scene.setIsChecked(!scene.isChecked)} checked={scene.isChecked}/>
                            <span>{t("annotate.popups.remark.options.add_remark")}</span>
                        </label>
                        <div className={`w-full ${scene.isChecked ? 'block' : 'hidden'}`}>
                            <h2 className={"font-mabry text-[14px] font-normal"}>{t("annotate.popups.remark.add_remark_explanation")}</h2>
                            <textarea id='remark'
                                      ref={input}
                                      className="w-full !mt-0 p-2 overflow-auto outline-0 resize-none text-[14px] font-mabry font-normal border-[1px] border-gray-500 min-h-[185px]"
                                      name="remark"/>
                            <h2 className={"font-mabry text-[14px] font-normal"}>{t("annotate.popups.remark.add_remark_extra_info")}</h2>
                        </div>
                        <div className={"flex relative tablet:flex-row flex-col tablet:justify-center w-full tablet:w-auto gap-2 pt-3"}>
                            <Button onClick={() => {
                                addRemark()
                            }}
                                    $color={'light-primary'}>{t("annotate.popups.remark.actions.add")}</Button>
                            <Button onClick={scene.toggleRemark}
                                    $color={'light-white'}>{t("general.actions.cancel")}</Button>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <>
            { (isMobile || window.innerWidth <= TabletWidth) ?
                <>
                    {(scene.overlay && !scene.isSubStep) && <div className={`absolute bottom-0 left-0 right-0 bg-black h-screen w-screen opacity-50 z-[78] transition-all duration-500 ease-in-out`}></div>}
                    <SceneHeader remark={<Remark/>} tutorialElement={<TutorialAnnotate/>} type='object-validate'/>
                    <BottomDrawer isValidating annotableLabel={
                        annotationProvider.checkLabels?.map((label, key) => {
                            return <CheckableObjectLabel key={key} label={label} isMissing={missingLabels.includes(label.id)}/>
                        })
                    }/>
                    <div className={"bottom-0 fixed z-[101] w-full"}>
                        <SceneBottomBar skipRemark={<Skip/>} canContinue={!isDisabled} onSubmit={() => submit(false)}/>
                    </div>
                    { showPostError && <SceneErrorPopup onClose={() => setShowPostError(false)} />}
                </>
                    :
                <>
                    <div className={scene.expanded ? 'w-[5%]' : 'bg-gray-200 w-5/12 h-full flex flex-col'}>
                        <SceneHeader remark={<Remark/>} tutorialElement={<TutorialAnnotate/>} type='object-validate'/>
                        <div className={scene.expanded ? 'hidden' : 'flex-1 flex flex-col overflow-y-auto'}>
                            <h1 className={'font-mabry font-medium text-[14px] px-9 mt-2 pb-2'}>Het algoritme stelt deze tags en kaders voor</h1>
                            <div className='flex-1 min-h-min px-9 py-2 space-y-2.5'>
                                {annotationProvider.checkLabels?.map((label, key) => {
                                    return <CheckableObjectLabel key={key} label={label}
                                                                 isMissing={missingLabels.includes(label.id)}/>
                                })}
                            </div>
                        </div>
                        <SceneBottomBar skipRemark={<Skip/>} canContinue={!isDisabled} onSubmit={() => submit(false)}/>
                    </div>
                    { showPostError && <SceneErrorPopup onClose={() => setShowPostError(false)} />}
                </>
            }
        </>
    )
}

export default withTranslation()(ObjectValidateContainer)