import {Button, ButtonProps} from "../styled-components/Button";

interface SuffixButtonProps extends ButtonProps {
    suffix?: string | JSX.Element
    children?: string | Element | JSX.Element
}

interface PrefixButtonProps extends ButtonProps {
    prefix?: string | JSX.Element
    children?: string | Element | JSX.Element
}

export function SuffixButton({children, suffix, ...props}: SuffixButtonProps) {
    return (
        <Button {...props}>
            {children}
            <div className={`${props.$small ? 'ml-6 transform translate-y-[1px]' : 'ml-8 transform translate-y-[1px]'}`}>
                {suffix}
            </div>
        </Button>
    )
}

export function PrefixButton({children, prefix, ...props}: PrefixButtonProps) {
    return (
        <Button {...props}>
            <div className={`${props.$small ? 'mr-2 transform translate-y-[1px]' : 'mr-3 transform translate-y-[1px]'}`}>
                {prefix}
            </div>
            {children}
        </Button>
    )
}