import i18n from "i18next";
import I18NextHttpBackend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";

export default i18n
    .use(I18NextHttpBackend)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: process.env.REACT_APP_API_URL + '/websiteContent/{{lng}}.json',
            backendOption: {
                allowMultiLoading: true
            }
        },
        fallbackLng: 'nl',
        debug: false
        // debug: process.env.REACT_APP_ENV !== "production" && process.env.REACT_APP_ENV !== "prod"
    })

export const i18nStringFromObject = (object: object, field: string): string => {
    if (object == null) {
        return "";
    }

    const lang = i18n.language.toLowerCase()

    if (field in object) {
        return object[field][lang] ?? "";
    }

    console.log(`field ${field} not found`, object)

    return "";
}

