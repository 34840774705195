import Label from "./Label";
import {Label as LabelInterface} from "../types/entities";
import React, {MouseEventHandler, useEffect, useRef, useState} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {useScene} from "../providers/SceneProvider";
import Api from "../Api";
import Spinner from "./Spinner";
import {Button} from "../styled-components/Button";
import {Fade} from "@mui/material";
import Icon from "./Icon";
import {faChevronDown, faChevronUp, faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {HtmlTooltipLight} from "./HtmlTooltipLight";
import {i18nStringFromObject} from "../plugins/i18n";
import {isMobile} from "react-device-detect";

interface LabelSkeletonProps extends WithTranslation {
    label: LabelInterface
    alreadyExpanded?: boolean

    isDemo: boolean
}

function LabelSkeleton({label, alreadyExpanded, isDemo, t, tReady, i18n}: LabelSkeletonProps) {
    const scene = useScene();
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const labelRef = useRef(null)
    const selectedLabelRef = useRef(null)
    const [showTooltip, setShowTooltip] = useState(false);

    const selectLabel = () => {
        if(label.labelCount === 0 || expanded) {
            let newLabelArr = Object.assign([], scene.selectedLabel ?? []);

            if (newLabelArr.find(l => l.id === label.id)) {
                // remove clicked label if label exists in array
                newLabelArr = newLabelArr.filter(l => l.id !== label.id);
            } else {
                if (newLabelArr.length < scene.batch.maxLabels || scene.batch.maxLabels === 0) {
                    // add clicked label if label does not exist in array
                    newLabelArr.push(label)
                }
            }
            scene.setSelectedLabel(newLabelArr);
        }
        setExpanded(true)
    }

    const containsObject = (): boolean => {
        return scene.selectedLabel && !!scene.selectedLabel.find(l => l.id === label.id);
    }

    useEffect(() => {
        if (expanded && label.labelCount > 0) {
            if (label.labels == null || label.labelCount !== label.labels.length) {
                setLoading(true);
                Api.get(`${isDemo ? '/demo' : ''}/labels/${label.id}/labels`,
                    ({data}) => {
                        label.labels = data.items
                        setLoading(false);
                        selectedLabelRef.current.scrollIntoView({behavior: "smooth", block: "center"})
                    })
            } else {

            }
        }
    }, [expanded])

    const toggleTooltip = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        setShowTooltip(!showTooltip)
    }

    const toggleExpanded = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setExpanded(!expanded);
    }

    return (
        <div ref={labelRef}>
            <ul className='space-y-1 h-full w-full list'>
                <Label ref={selectedLabelRef} onClick={() => selectLabel()}
                       selected={containsObject()}>
                    <div>{ i18nStringFromObject(label, 'title') }</div>
                    <div  className='flex space-x-2.5 items-center'>
                        <HtmlTooltipLight  open={showTooltip}
                                           onOpen={() => setShowTooltip(true)}
                                           onClose={() => setShowTooltip(false)}
                                           TransitionComponent={Fade}
                                           enterDelay={0}
                                           enterTouchDelay={0}
                                           title={ i18nStringFromObject(label, 'description') }
                                           tooltipClasses={'bg-red-500'}
                                           leaveTouchDelay={1500}
                                           className={`!-mt-[4vw] mini-mobile:!-mt-[2.5vw] tablet:!-mt-0`}
                                           arrow>
                            <Button className='cursor-default'><Icon icon={faCircleInfo}/></Button>
                        </HtmlTooltipLight>
                        <div className='flex items-center w-4 h-4' onClick={toggleExpanded}>
                            {label.labelCount > 0 && (
                                <Icon icon={expanded ? faChevronUp : faChevronDown} />
                            )}
                        </div>
                    </div>
                </Label>
                {loading && <div className='flex justify-center'>
                    <Spinner/>
                </div>}

                <li className={expanded || alreadyExpanded ? 'pl-5 space-y-1 labelItem' : 'pl-5 space-y-1 hidden labelItem'}>
                    {label.labels?.map((label: LabelInterface, key) => {
                        return <LabelSkeleton isDemo={isDemo} alreadyExpanded={alreadyExpanded} key={key} label={label} t={t}
                                              tReady={tReady} i18n={i18n}/>
                    })}
                </li>
            </ul>
        </div>
    )
}

export default withTranslation()(LabelSkeleton)