import {WithTranslation, withTranslation} from "react-i18next";
import NavBar from "../components/NavBar";
import {H2, H5} from "../styled-components/Headings";
import {Button} from "../styled-components/Button";
import React, {useEffect} from "react";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import {Header} from "../components/Header";
import ParticipationBlock from "../components/ParticipationBlock";
import YoutubeContainer from "../components/YoutubeContainer";

function InstructionPage({t, i18n}: WithTranslation) {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [])

    return (
        <div className='min-h-screen bg-gray-200'>
            <NavBar/>

            <Header
                title={ t('how_it_works.header_title') }
            />

            {/*Contentblock sectie*/}
            <div className={"container mx-auto h-full w-full tablet-landscape:min-h-[361px] tablet-landscape:mb-[67px] tablet-landscape:grid tablet-landscape:grid-cols-12 tablet-landscape:gap-5"}>
                <div className={'tablet-landscape:col-start-1 tablet-landscape:col-end-7 flex flex-col tablet-landscape:my-auto'}>
                    <div className={"tablet-landscape:mb-5"}>
                        <H2 className={'mb-[25px] tablet-landscape:mb-[35px]'}>{ t('how_it_works.block_1.title_1') }</H2>
                        <p className={'font-bl-pylon text-base text-black-text mb-[40px] tablet-landscape:mb-5 pr-5'} dangerouslySetInnerHTML={{ __html: t('how_it_works.block_1.text_1')}}></p>
                        <Link className={'w-full mb-5'} to={'/register'}>
                            <Button $color={'light-primary'} className={'text-center justify-center w-full tablet-landscape:w-fit min-w-[200px]'}>
                                { t('how_it_works.block_1.button') }</Button>
                        </Link>
                    </div>

                    <div className={"mt-[40px] tablet-landscape:mb-5 space-y-2 tablet-landscape:mt-0"}>
                        <H5>{ t('how_it_works.block_1.title_2') }</H5>
                        <p className={'font-bl-pylon text-base text-black-text mb-5 pr-5'} dangerouslySetInnerHTML={{ __html: t('how_it_works.block_1.text_2')}}></p>
                    </div>
                    <div className={"mt-[40px] mb-10 space-y-2 tablet-landscape:mt-0"}>
                        <H5>{ t('how_it_works.block_1.title_3') }</H5>
                        <p className={'font-bl-pylon text-base text-black-text mb-5 pr-5'} dangerouslySetInnerHTML={{ __html: t('how_it_works.block_1.text_3')}}></p>
                    </div>
                </div>
                <div className={"shadow-primary-left tablet-landscape:shadow-none top-0 right-[-20px] h-full w-[calc(100%-20px)] relative tablet-landscape:w-full tablet-landscape:col-start-7 tablet-landscape:col-end-13 pb-[56.25%] pt-[25px]"}>
                    <YoutubeContainer src={i18n.language === 'nl' ? "https://www.youtube.com/embed/oX1jVATqUu4" : "https://www.youtube.com/embed/1CUvkDjELDM"} />
                </div>
            </div>

            <ParticipationBlock
                message={t('how_it_works.block_2.title')}
                button1={{
                    text:t('how_it_works.block_2.buttons.button_1')
                }}
                button2={{
                    text:t('how_it_works.block_2.buttons.button_2')
                }} />
            <Footer/>
        </div>


    )
}

export default withTranslation()(InstructionPage)
