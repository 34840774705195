import Viewer from "../../../components/Viewer";
import SceneProvider from "../../../providers/SceneProvider";
import SceneAnnotateContainer from "../../../components/Scene/SceneAnnotateContainer";

export default function DemoSceneAnnotatePage() {
    return (
        <div className='h-screen w-full flex'>
            <SceneProvider>
                <Viewer />
                <SceneAnnotateContainer />
            </SceneProvider>
        </div>
    )
}