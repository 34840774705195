import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Icon from './Icon';

const Tutorial = props => {

    return (
        <div className="fixed top-0 left-0 w-screen h-full visible z-[999999999999]">
            <div className="border-[1px] border-[#DDD] w-[90vw] max-w-[90vw] tablet:max-w-[800px] h-[90%] max-h-[90%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white opacity-100 z-[999999]">
                <button className="absolute right-[15px] top-[10px]" onClick={props.handleClose}>
                    <Icon className="text-black text-xs" icon={faXmark} />
                </button>
                <div className="overflow-y-scroll h-full p-[30px]">
                    {props.children}
                </div>
            </div>
            <div className="absolute w-screen h-screen bg-black opacity-50 z-[999]"></div>
        </div>
    )
}

export default Tutorial