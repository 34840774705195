import { PrefixButton, SuffixButton } from "../Buttons";
import Icon from "../Icon";
import {
    faAnglesLeft,
    faAnglesRight, faCross,
    faDownload, faEllipsisV,
    faInfo, faListDots,
    faSearch,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../styled-components/Button";
import {H2, H3, H4} from "../../styled-components/Headings";
import {useTranslation, WithTranslation, withTranslation} from "react-i18next";
import { SvgIcon } from "../../styled-components/SvgIcon";
import React, { useEffect } from 'react';
import Input from "../Input";
import { useScene } from "../../providers/SceneProvider";
import { Link } from "react-router-dom";
import Tutorial from "../Tutorial";
import Remark from "../Remark";
import Stop from "../Stop";
import {Tooltip} from "@mui/material";
import Popup from "../Popup";
import LanguageSwitcher from "../LanguageSwitcher";
import {isMobile} from "react-device-detect";
import {TabletWidth} from "../../constants/ScreenWidth";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {i18nStringFromObject} from "../../plugins/i18n";
import {useAnnotation} from "../../providers/AnnotationProvider";
import Dropdown from "../Dropdown";

interface SceneHeaderProps extends WithTranslation {
    search?: boolean
    searchError?: string
    tutorialElement?: JSX.Element
    remark?: JSX.Element
    stopElement?: JSX.Element
    type: string
    downloadElement?: JSX.Element
}

function SceneHeader({ t, search, searchError, tutorialElement, remark, type, stopElement, downloadElement }: SceneHeaderProps) {
    const scene = useScene();
    const annotationProvider = useAnnotation();
    const {width} = useWindowDimensions()

    useEffect(() => {
        setTimeout(() => {
            const pop_status = localStorage.getItem(`pop_status_${type}`);
            if (!pop_status) {
               scene.setShowTutorial(true);
                localStorage.setItem(`pop_status_${type}`, String(1));
            }
        }, 1000)
    })

    const tutorial = () => {
        localStorage.removeItem(`pop_status_${type}`);
    }

    const toggler = () => {
        scene.setExpanded(!scene.expanded);
    }

    const searchChange = (e) => {
        scene.setLabelFilter(e.target.value)
    }

    const download = () => {
        if (scene.image.photoCreditLine == null || scene.image.photoCreditLine === "") {
            scene.setShowDownload(true);
        }
    };

    const instructionText = ():string => {
        if (scene.batch.type === "Object") {
            if (scene.batch.phase === "Fase 1") {
                if (scene.isCheckContainer) {
                    return t('annotate.header.check_object_annotation_title')
                } else {
                    return t('annotate.header.annotate_object_title')
                }
            } else {
                return t('annotate.header.check_tags_title')
            }
        } else {
            return t('annotate.header.choose_tags_title')
        }
    }


    return (
        <>
            {scene.showTutorial && <Tutorial className="absolute w-screen h-screen top-0 left-0 z-[9999999999]"
                                             handleClose={scene.toggleTutorial}
            >
                {tutorialElement}
            </Tutorial>}
            {scene.showRemark && <Remark className="absolute w-screen h-screen top-0 left-0 z-[9999999999]"
                                         handleClose={scene.toggleRemark}
            >
                {remark}
            </Remark>}
            {scene.showStop && <Stop className="absolute w-screen h-screen top-0 left-0"
                                     handleClose={scene.stop}
            />}
            {scene.showDownload && <DownloadPopup />}
            { isMobile || window.innerWidth <= TabletWidth ?
                <div id={"sceneHeader"} className={"px-3 pb-3 pt-6 fixed w-full top-0 bg-white border-b-1"}>
                    <div className={"flex flex-row justify-between items-center"}>
                        <div className={"flex flex-col"}>
                            <div className={`flex tablet:mt-3 tablet-landscape:mt-0 tablet:items-stretch ${scene.expanded ? 'hidden' : 'tablet:space-x-3'}`}>
                                { scene.image.photoSource && <div className={"flex flex-row gap-1"}>
                                    <span className={'font-bold text-[10px]'}> {/*{ scene.image.photoSource }*/}</span>
                                    <div className={'w-[1px] bg-black'} />
                                </div>}
                                <span className={`${scene.image.photoSource && 'pl-1'} font-mabry font-bold text-[10px] tablet:font-light tablet:text-sm`}>{ t('annotate.header.photo_number') }: {scene.image.photoNumber}</span>
                            </div>
                            <H2 className={`!text-[16px] pr-4 text-primary-light`}>
                                { scene.isSubStep ?
                                    i18nStringFromObject(annotationProvider.currentLabel, 'title')
                                 : instructionText()?.toUpperCase()}
                            </H2>
                        </div>
                        <div className={"flex flex-row gap-1"}>
                            <LanguageSwitcher type={'light'} $small buttonClassName={'!h-7'} />
                            <Dropdown listLeftSide overlay>
                                <Button $icon $color='white'>
                                    <Icon icon={faEllipsisV} />
                                </Button>
                                <ul className={"bg-white p-2 flex flex-col gap-2"}>
                                    <div onClick={scene.toggleTutorial} className={"flex flex-row gap-3 items-center"}>
                                        <Button $icon $color='light-white'>
                                            <Icon icon={faInfo} />
                                        </Button>
                                        <span className={"font-mabry text-[12px]"}>{t('general.tooltips.instructions')}</span>
                                    </div>
                                    <div onClick={scene.toggleRemark} className={"flex flex-row gap-3 items-center"}>
                                        <Button $icon $color='light-white'>
                                            <SvgIcon src="/assets/icons/Message.svg" alt="message icon" />
                                        </Button>
                                        <span className={"font-mabry text-[12px]"}>{t('general.tooltips.remark')}</span>
                                    </div>
                                    <div onClick={() => download()} className={"flex flex-row gap-3 items-center"}>
                                        <Button $icon $color='light-white' className={`${scene.image.photoCreditLine && 'pointer-events-none opacity-40'}`}>
                                            <Icon icon={faDownload} />
                                        </Button>
                                        <span className={"font-mabry text-[12px]"}>{t('general.tooltips.download')}</span>
                                    </div>
                                </ul>
                            </Dropdown>
                            <div className={'cursor-pointer'} onClick={scene.stop} >
                                <Button $icon $color='black'>
                                    <Icon icon={faTimes} />
                                </Button>
                            </div>
                        </div>

                    </div>
                </div>
                :
                <div id={"sceneHeader"} className={`${scene.expanded ? 'flex items-center justify-center' : 'bg-white border-b border-gray-500'} p-9`}>
                    <div id="endscreen" className="absolute w-screen h-screen bg-black top-0 left-0 z-[9999999] invisible"></div>
                    <div className='flex'>
                        <div className={`flex w-full gap-2 justify-between tablet-landscape:gap-0 flex-row`}>
                            {/*Inklappen button*/}
                            <div onClick={toggler}>
                                <PrefixButton $small className={scene.expanded ? 'flex items-center' : ''} prefix={scene.expanded ? <Icon icon={faAnglesLeft} /> : <Icon icon={faAnglesRight} />}>
                                    {scene.expanded ? '' : t('annotate.header.actions.collapse')}
                                </PrefixButton>
                            </div>

                            <div className={`${scene.expanded ? 'hidden' : 'flex-1 flex flex-row justify-end space-x-2'}`}>
                                <LanguageSwitcher type={'light'} $small />
                                <Tooltip title={t('general.tooltips.instructions')} enterDelay={500}>
                                    <span>
                                        <Button onClick={scene.toggleTutorial} $icon $color='light-white'>
                                            <Icon icon={faInfo} />
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Tooltip title={t('general.tooltips.remark')} enterDelay={500}>
                                    <span>
                                        <Button onClick={scene.toggleRemark} $icon $color='light-white'>
                                            <SvgIcon src="/assets/icons/Message.svg" alt="message icon" />
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Tooltip title={t('general.tooltips.download')} enterDelay={500}>
                                    <span>
                                        <Button onClick={() => download()} $icon $color='light-white' className={`${scene.image.photoCreditLine && 'pointer-events-none opacity-40'}`}>
                                            <Icon icon={faDownload} />
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Tooltip title={t('general.tooltips.stop')} enterDelay={500}>
                                    <div className={'cursor-pointer'} onClick={scene.stop} >
                                        <SuffixButton $small $color='black' suffix={<Icon icon={faTimes} />}>
                                            {t('general.actions.stop')}
                                        </SuffixButton>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className={`flex mt-3 items-stretch ${scene.expanded ? 'hidden' : 'tablet:space-x-3'}`}>
                        { scene.image.photoSource && <>
                            <span className={'font-bold text-sm'}>{ scene.image.photoSource }</span>
                            <div className={'w-[1px] bg-black'} />
                        </>}
                        <span className='font-mabry font-bold text-[10px] tablet:font-light tablet:text-sm'>{t("annotate.header.photo_number")}: {scene.image.photoNumber}</span>
                    </div>
                    {/*<H2 className={`text-red-light ${scene.expanded ? 'hidden' : ''}`}>{t(`pages.scene.header.title.${scene.batch.type.toLowerCase()}`)}</H2> */}
                    <div className={"space-y-3 mt-3"}>
                        <H2 className={`text-primary-light ${scene.expanded ? 'hidden' : ''}`}>{instructionText()?.toUpperCase()}</H2>
                        { search &&
                            <Input
                                className={scene.expanded ? 'hidden' : ''}
                                placeholder={t('annotate.header.search_placeholder')}
                                suffixElement={<Icon className='text-primary-light' icon={faSearch}/>}
                                inputClassName='placeholder:italic placeholder:text-black placeholder:font-light'
                                error={searchError}
                                onChange={searchChange}
                            />
                        }
                        { scene.isSceneAnnotateContainer &&
                            <H4 className={`underline text-primary-light ${scene.expanded ? 'hidden' : ''}`}>{ t('annotate.header.select_amount_tags', { number: scene.batch.maxLabels, }) }</H4>
                        }
                    </div>
                </div>
            }
        </>
    )
}

const DownloadPopup = () => {
    const { t } = useTranslation();
    const scene = useScene();

    return (
        <Popup className="absolute w-screen h-screen top-0 left-0" handleClose={() => scene.setShowDownload(false)}>
            <div className={'py-4'}>
                <p>{ t('annotate.popups.download.text') }</p>
            </div>
            <div>
                <div className={"flex justify-center space-x-2 pt-3"}>
                    <a className={"!no-underline"} href={`${process.env.REACT_APP_API_URL}/batch-photo-download/?id=${scene.image.id}`} target={'_blank'}>
                        <Button $color={'light-primary'}>
                            { t('annotate.popups.download.actions.download') }
                        </Button>
                    </a>
                    <Button onClick={() => scene.setShowDownload(false) }>
                        { t('annotate.popups.download.actions.cancel') }
                    </Button>
                </div>
            </div>
        </Popup>
    )
}

export default withTranslation()(SceneHeader)