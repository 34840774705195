import {Button} from "../styled-components/Button";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {H1, H2, H3} from "../styled-components/Headings";
import styled from "styled-components";
import Input from "./Input";
import tw from "tailwind-styled-components";
import {useGlobalState} from "../utils/GlobalState";

const CookiePopup = (props) => {
    const [showPopup, setShowPopup] = useGlobalState('showPopup', false)
    const [showExtraContent, setShowExtraContent] = useState(false)

    const { t } = useTranslation();

    const [checkboxValues, setCheckboxValues] = useState({
        functional: true,
        youtube: false,
        analytics: false,
    });

    useEffect(() => {
        const consents =  localStorage.getItem('cookieConsents')
        if (consents) {
            try {
                const savedCheckboxValues = JSON.parse(consents);
                if (savedCheckboxValues) {
                    setCheckboxValues(savedCheckboxValues);
                }
            } catch (e) {
                localStorage.removeItem('cookieConsents');
            }
        }

    }, []);

    useEffect(() => {
        const accepted = localStorage.getItem('cookieConsents');
        if (!accepted) {
            setShowPopup(true);
        }
    }, [])

    const accept = () => {
        setShowPopup(false)
        if (!showExtraContent) {
            localStorage.setItem('cookieConsents', JSON.stringify({functional: true, youtube: true, analytics: true}));
        } else {
            localStorage.setItem('cookieConsents', JSON.stringify(checkboxValues));
        }
        window.location.reload();
    }

    const decline = () => {
        setShowPopup(false)
        localStorage.setItem('cookieConsents', JSON.stringify({functional: true, youtube: false, analytics: false}));
        window.location.reload();
    }

    const showMore = () => {
        setShowExtraContent(true)
    }

    const handleCookieChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxValues((prevCheckboxValues) => ({
            ...prevCheckboxValues,
            [name]: checked,
        }));
    };

    return (
        <>
            { !showExtraContent &&
                <CookiePopupContainer className={`tablet:w-[65%] w-[90%] tablet:mx-0 mx-2 rounded-2xl z-[1000000] ease-linear duration-300 fixed left-1/2 -translate-x-1/2 bg-[#333] p-[30px] shadow-tooltip flex items-center space-x-2 ${showPopup ? 'tablet:bottom-[40px] bottom-2' : '-bottom-[100vh]'}`}>
                    <div className={"flex flex-col w-full gap-[40px]"}>
                        <div className={"flex flex-col gap-4"}>
                            <H2 className={"uppercase text-white font-mabry"}>{ t('general.cookie_statement.popup_1.title') }</H2>
                            <p className="tablet:text-[16px] text-[12px] font-light font-mabry text-white leading-[1.7]">
                                { t('general.cookie_statement.popup_1.text') }
                            </p>
                        </div>
                        <div className={"flex tablet:flex-row flex-col justify-between"}>
                            <div className={"flex tablet:flex-row flex-col gap-4"}>
                                <Button className="text-[10px] tablet:text-xs whitespace-nowrap" $color='light-primary' onClick={accept}>{ t('general.cookie_statement.popup_1.actions.i_understand') }</Button>
                                <Button className={"text-white"} onClick={showMore}>{ t('general.cookie_statement.popup_1.actions.set_preferences') }</Button>
                            </div>
                            <Button onClick={decline} className={"text-white"}>{ t('general.cookie_statement.popup_1.actions.reject_all') }</Button>
                        </div>
                    </div>
                </CookiePopupContainer>
            }

            { showExtraContent &&
                <div className='tablet:w-[65%] w-[90%] max-h-[90vh] fixed rounded-2xl tablet:bottom-8 bottom-2 z-[1000000] left-1/2 -translate-x-1/2 bg-[#333] p-[30px] shadow-tooltip flex items-center'>
                    <div className={"flex flex-col gap-4 w-full min-h-min"}>
                        <div className={"flex flex-col tablet:gap-[40px]"}>
                            <H2 className={"uppercase text-white font-mabry"}>{ t('general.cookie_statement.popup_2.title') }</H2>
                        </div>
                        <Container className={"tablet:grid-cols-2 grid-cols-1 w-full overflow-y-auto tablet:m-[20px] max-h-[70vh]"}>
                            <Checkbox>
                                <div className={"flex flex-row gap-2.5"}>
                                    <input type={"checkbox"} name={"functional"} disabled checked={true} className={"w-[20px] h-[20px] my-auto"}/>
                                    <p className={"tablet:text-[16px] text-[12px] my-auto text-white"}>{ t('general.cookie_statement.popup_2.functional_cookies.label') }</p>
                                </div>
                                <p className={"text-[12px] tablet:text-sm text-white"}>{ t('general.cookie_statement.popup_2.functional_cookies.text') }</p>
                            </Checkbox>
                            <Checkbox>
                                <div className={"flex flex-row gap-2.5"}>
                                    <input type={"checkbox"} name={"analytics"} checked={checkboxValues.analytics} onChange={handleCookieChange} className={"w-[20px] h-[20px] my-auto"}/>
                                    <p className={"tablet:text-[16px] text-[12px] my-auto text-white"}>{ t('general.cookie_statement.popup_2.analytics_cookies.label') }</p>
                                </div>
                                <p className={"text-[12px] tablet:text-sm text-white"}>{ t('general.cookie_statement.popup_2.analytics_cookies.text') }</p>
                            </Checkbox>
                            <Checkbox>
                                <div className={"flex flex-row gap-2.5"}>
                                    <input type={"checkbox"} name={"youtube"} checked={checkboxValues.youtube} onChange={handleCookieChange} className={"w-[20px] h-[20px] my-auto"}/>
                                    <p className={"tablet:text-[16px] text-[12px] my-auto text-white"}>{ t('general.cookie_statement.popup_2.3rd_party_cookies.label') }</p>
                                </div>
                                <p className={"text-[12px] tablet:text-sm text-white"}>{ t('general.cookie_statement.popup_2.3rd_party_cookies.text') }</p>
                            </Checkbox>
                        </Container>
                        <Button className={"w-fit"} $color='light-primary' onClick={accept}>{ t('general.cookie_statement.popup_1.actions.i_understand') }</Button>
                    </div>
                </div>
            }
        </>
    )
}

const CookiePopupContainer = styled.div`
`

const Container = styled.div`
  width: 100%;
  display:grid;
  grid-row: auto;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  margin: 0px;
`;

const Checkbox = tw.div`
  @apply py-[20px] rounded-[10px] text-white flex text-[20px] font-mabry flex flex-col gap-2
`;

export default CookiePopup