import NavBar from "../components/NavBar";
import {H3} from "../styled-components/Headings";
import React, {useEffect} from "react";
import Footer from "../components/Footer";
import {Header} from "../components/Header";
import {useTranslation} from "react-i18next";

function TermsPage() {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [])

    return (
        <div className='min-h-screen bg-white'>
            <NavBar/>

            {/*Header*/}
            <Header title={ t('terms_of_use.header_title') }/>

            {/*Contentblock sectie*/}
            <div className={"container mx-auto my-[80px] space-y-5"}>
                <div>
                    <H3 className={"pb-1"}>{ t('terms_of_use.intro.title')}</H3>
                    <p className={'font-bl-pylon text-base text-black-text'} dangerouslySetInnerHTML={{__html: t('terms_of_use.intro.text')}}></p>
                </div>
                <div>
                    <H3 className={"pb-1"}>{ t('terms_of_use.terms_of_use.title')}</H3>
                    <p className={'font-bl-pylon text-base text-black-text'} dangerouslySetInnerHTML={{__html: t('terms_of_use.terms_of_use.text')}}></p>
                </div>
                <div>
                    <H3 className={"pb-1"}>{ t('terms_of_use.privacy_of_participants.title')}</H3>
                    <p className={'font-bl-pylon text-base text-black-text'} dangerouslySetInnerHTML={{__html: t('terms_of_use.privacy_of_participants.text')}}></p>
                </div>
                <div>
                    <H3 className={"pb-1"}>{ t('terms_of_use.personal_password.title')}</H3>
                    <p className={'font-bl-pylon text-base text-black-text'} dangerouslySetInnerHTML={{__html: t('terms_of_use.personal_password.text')}}></p>
                </div>
                <div>
                    <H3 className={"pb-1"}>{ t('terms_of_use.rules_of_conduct.title')}</H3>
                    <p className={'font-bl-pylon text-base text-black-text'} dangerouslySetInnerHTML={{__html: t('terms_of_use.rules_of_conduct.text')}}></p>
                </div>
                <div>
                    <H3 className={"pb-1"}>{ t('terms_of_use.points.title')}</H3>
                    <p className={'font-bl-pylon text-base text-black-text'} dangerouslySetInnerHTML={{__html: t('terms_of_use.points.text')}}></p>
                </div>
                <div>
                    <H3 className={"pb-1"}>{ t('terms_of_use.intellectual_property.title')}</H3>
                    <p className={'font-bl-pylon text-base text-black-text'} dangerouslySetInnerHTML={{__html: t('terms_of_use.intellectual_property.text')}}></p>
                </div>
                <div>
                    <H3 className={"pb-1"}>{ t('terms_of_use.liability.title')}</H3>
                    <p className={'font-bl-pylon text-base text-black-text'} dangerouslySetInnerHTML={{__html: t('terms_of_use.liability.text')}}></p>
                </div>
                <div>
                    <H3 className={"pb-1"}>{ t('terms_of_use.complains.title')}</H3>
                    <p className={'font-bl-pylon text-base text-black-text'} dangerouslySetInnerHTML={{__html: t('terms_of_use.complains.text')}}></p>
               </div>
                <div>
                    <H3 className={"pb-1"}>{ t('terms_of_use.conflicts.title')}</H3>
                    <p className={'font-bl-pylon text-base text-black-text'} dangerouslySetInnerHTML={{__html: t('terms_of_use.conflicts.text')}}></p>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default TermsPage;
