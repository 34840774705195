import tw from "tailwind-styled-components";
import React from "react";

export interface ButtonProps {
    $color?: 'light-green' | 'dark-green' | 'light-primary' | 'dark-primary' | 'light-red' | 'dark-red' | 'light-gray' | 'dark-gray' | 'light-white' | 'dark-white' | 'black' | 'homepage-white' | 'white'
    $text?: 'white' | 'black'
    $center?: boolean
    $icon?: boolean
    $small?: boolean
    disabled?: boolean,
    className?: string,
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const Button = tw.button<ButtonProps>`
    font-mabry
    flex
    items-center
    text-base
    font-bold
    border
    
    ${(props) => `
        ${props.$center ? 'justify-center' : ''}
        ${props.$icon ? 'w-7 h-7 tablet:w-10 tablet:h-10 justify-center px-0' : ''}
        ${props.$small ?
            `h-10 ${ hasPadding(props) ? '' : 'px-[10px]' }`
            : 
            `${props.$icon ? '' : `h-[50px] ${ hasPadding(props) ? '' : 'px-[24px]' }`}`
        }
        
        ${!props.$color ? 'border-transparent' : ''}
        
        ${props.disabled ? 'bg-black/40 text-black/35' : `
            ${props.$color === 'light-green'  ? `bg-green-light text-white` : ''}
            ${props.$color === 'dark-green' ? `bg-green-dark text-white` : ''}
            
            ${props.$color === 'light-primary' ? `bg-primary-light text-white border-none hover:bg-primary-dark` : ''}
            ${props.$color === 'dark-primary' ? `bg-primary-dark text-white` : ''}
            
            ${props.$color === 'light-red' ? `bg-red-light text-white border-none hover:bg-red-dark` : ''}
            ${props.$color === 'dark-red' ? `bg-red-dark text-white` : ''}
            
            ${props.$color === 'light-gray' ? `bg-gray-warm text-white` : ''}
            ${props.$color === 'dark-gray' ? `bg-black text-white` : ''}  
            
            ${props.$color === 'light-white' ? `text-black border border-gray-500` : ''}
            ${props.$color === 'dark-white' ? `text-black border border-black` : ''}
            ${props.$color === 'white' ? `text-black border border-gray-500 bg-white` : ''}
            
            ${props.$color === 'homepage-white' ? `border-blue text-white` : ''}
            
            ${props.$color === 'black' ? `text-white bg-black` : ''}
            
            ${props.$text === 'black' ? `text-black` : ''}
            ${props.$text === 'white' ? `text-white` : ''}
        `}
    `}
`

function hasPadding(props) {
    return props.className && ['p-', 'px-', 'pr-', 'pl-'].some(_ => props.className.includes(_))
}