const Donut = ({ children, percentage }: { children: JSX.Element, percentage: number}) => {
    return (
        <div className={'relative'}>
            <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut -rotate-90">
                <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954"
                        fill="transparent"></circle>
                <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954"
                        fill="transparent" stroke="#3669d8" strokeWidth="5"></circle>

                <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954"
                        fill="transparent" stroke="white" strokeWidth="5"
                        strokeDasharray={`${percentage} ${100 - percentage}`} strokeDashoffset="0"></circle>
            </svg>
            <div className={'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'}>
                { children }
            </div>
        </div>
    )
}

export default Donut