import {a, config, useSpring} from "@react-spring/web";
import {useDrag} from "@use-gesture/react";
import {useScene} from "../providers/SceneProvider";
import Icon from "./Icon";
import {faAnglesDown, faAnglesUp, faSearch} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Input from "./Input";
import {withTranslation, WithTranslation} from "react-i18next";
import {H4} from "../styled-components/Headings";
import {PrefixButton} from "./Buttons";
import AnnotableLabel from "./Annotate/AnnotableLabel";


interface BottomDrawerProps extends WithTranslation {
    isSceneAnnotatePage?: boolean
    isValidating?: boolean
    isNextLevel?: boolean
    search?: boolean
    searchError?: string
    annotableLabel?: any
}

const BottomDrawer = ({t, search, searchError, isSceneAnnotatePage, isValidating, isNextLevel, ...props}: BottomDrawerProps) => {
    const scene = useScene();
    const height = scene.isSceneAnnotateContainer ? 310 : 330;

    /*Bottom drawer functions*/
    const [{ y }, api] = useSpring(() => ({ y: height}))

    function open() {
        scene.setOverlay(true)
        api.start({ y: 0, immediate: false, config: config.stiff })
    }
    const close = ({velocity = 0}) => {
        scene.setOverlay(false)
        api.start({ y: height, immediate: false, config: { ...config.stiff, velocity } })
    }

    const bind = useDrag(
        ({ last, velocity: [, vy], direction: [, dy], movement: [, my], cancel, canceled }) => {
            if (my < -70) cancel()
            if (last) {
                my > height * 0.5 || (vy > 0.5 && dy > 0) ? close({velocity: vy}) : open()
            } else api.start({ y: my, immediate: false })
        },
        { from: () => [0, y.get()], filterTaps: true, bounds: { top: 0 }, rubberband: true },
    )

    const display = y.to((py) => (py < height && 'block'))

    const searchChange = (e) => {
        scene.setLabelFilter(e.target.value)
    }

    return (
        <>
            <a.div id={"drawer"} className={`${scene.isSubStep && 'invisible'} shadow-drawer flex flex-col h-[500px] fixed bottom-0 w-screen rounded-t-[20px] touch-none bg-white z-[998]`} style={{ display, y}}>

                {/*Slider indicator*/}
                <div {...bind()} onClick={() => open()} className={"h-5 rounded-t-[12px] flex justify-center"}>
                    <div className={"w-[100px] h-[1px] bg-[#333] opacity-[0.15] rounded mt-2"}/>
                </div>

                <div className={"w-fit text-center mx-auto mb-4 border-[1px] border-[#eee]"}>
                    <PrefixButton onClick={() => scene.overlay ? close({velocity: 0}) : open() }  prefix={scene.overlay ? <Icon icon={faAnglesDown} /> : <Icon icon={faAnglesUp} />}>
                        {t("annotate.bottom_bar.descriptions")}
                    </PrefixButton>
                </div>

                { isNextLevel &&
                    <div className={'px-6'}>
                        <h1 className={'font-mabry font-medium text-[14px] pb-2'}>Opmerking van de controleur</h1>
                        <div className={'bg-gray-300 shadow p-4'}>
                            <div className={"flex flex-1 space-x-5"}>
                                <img className={"w-[80px] h-[80px]"} src={scene.nextLevelComment.annotator.profilePicture} alt=""/>
                                <div>
                                    <H4>{scene.nextLevelComment.annotator.firstname}  {scene.nextLevelComment.annotator.surname}</H4>
                                    <p className={"italic text-[10px]"}>{scene.nextLevelComment.createdAt}</p>
                                    <p className={"mt-4"}>{ scene.nextLevelComment.comment }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                { isValidating &&
                    <h1 className={'font-mabry font-medium text-[14px] px-6'}>Het algoritme stelt deze tags en kaders voor</h1>
                }

                { search &&
                    <div className={"h-auto flex flex-col gap-2 px-6"}>
                        <Input
                            className={scene.expanded ? 'hidden' : ''}
                            placeholder={t('annotate.header.search_placeholder')}
                            suffixElement={<Icon className='text-primary-light' icon={faSearch}/>}
                            inputClassName='placeholder:italic placeholder:text-black placeholder:font-light'
                            error={searchError}
                            onChange={searchChange}
                        />
                        { scene.isSceneAnnotateContainer && <H4 className={`underline text-primary-light ${scene.expanded ? 'hidden' : ''}`}>Let op, je kunt maximaal {scene.batch.maxLabels} {(scene.batch.maxLabels > 1) ? 'tags' : 'tag'} selecteren</H4>}
                    </div>
                }

                {/*Daadwerkelijke content */}
                <div className={`real max-h-[244px] gap-[5px] flex flex-col`}>
                    <div className='overflow-y-scroll flex-1 min-h-min px-6 tablet:px-9 py-2 space-y-2.5' id='label-tray'>
                        { props.annotableLabel }
                    </div>
                </div>
            </a.div>
        </>
    )


}

export default withTranslation()(BottomDrawer)