import {Trans, WithTranslation, withTranslation} from "react-i18next";
import NavBar from "../components/NavBar";
import {H1, H2, H3} from "../styled-components/Headings";
import Input from "../components/Input";
import {Button} from "../styled-components/Button";
import {useAuth} from "../providers/AuthProvider";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Api from "../Api";
import {useEffect, useRef, useState} from "react";
import {AxiosError} from "axios";
import {User} from "../types/entities";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {TabletWidth} from "../constants/ScreenWidth";
import Footer from "../components/Footer";

function LoginPage({t}: WithTranslation) {
    const loginSchema = yup.object({
        email: yup.string().email(t('login.form.email_invalid_error')).required(t('login.form.email_required_error')),
        password: yup.string().required(t('login.form.password_required_error'))
    })
    const auth = useAuth();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [invalidLogin, setInvalidLogin] = useState(false)
    const { register, handleSubmit, trigger,  formState: { errors } } = useForm({
        resolver: yupResolver(loginSchema)
    });
    const {width} = useWindowDimensions()

    const [newEmail, setEmail] = useState('');
    const [newPassword, setPassword] = useState('');

    useEffect(() => {
        if (auth.user) {
            continueNavigate();
        }
    },[auth.user])

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [])

    const onSubmit = ({email, password} : { email: string, password: string}) => {
        Api.post('/login',
            {
                email: email, password: password
            },
            ({data}: {data: User}) => {
                auth.login(data)
                continueNavigate();
            },
            null,
           null,
            (error: AxiosError) => {
                if (error.response.status === 401) {
                    setInvalidLogin(true)
                }
            },
        )
    }

    const handleEmail = event => {
        setEmail(event.target.value)
    }

    const handlePassword = event => {
        setPassword(event.target.value)
    }

    const continueNavigate = () => {
        if (searchParams.get('return')) {
            navigate(searchParams.get('return'))
        } else {
            navigate('/profile')
        }
    }

    const navigateToRegister = () => {
        const returnParam = searchParams.get('return')
        navigate(`/register${returnParam ? `?return=${returnParam}` : ''}`)
    }

    const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            (e.target as HTMLInputElement).blur();
            document.getElementById('form-submit-button').click();
        }
    }

    const getError = (key: string): null | string => {
        if (errors[key]) {
            return errors[key].message as unknown as string
        }

        if (invalidLogin) {
            return ' ';
        }

        return null
    }

    return (
        <div className='min-h-screen bg-gray-200'>
            <NavBar />

            { window.innerWidth <= TabletWidth ?
                <div className={"container mx-auto my-[40px] flex flex-col"}>
                    <div className={"flex flex-col space-y-2"}>
                        <H2 className={"text-black"}>{ t('login.header_title') }</H2>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className='w-full mt-[40px]' noValidate>
                        <div className='mb-3 flex flex-col'>
                            <label className='font-mabry font-semibold text-[14px] leading-4 mb-1'>{ t('login.form.email_label') }</label>
                            <Input type='email' name={'email'} value={newEmail} defaultValue={newEmail && newEmail} onKeyDown={onEnter} onInput={handleEmail} onChange={handleEmail} register={ newEmail && register('email', {value: newEmail})} error={getError('email')}/>
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-mabry font-semibold text-[14px] leading-4 mb-1'>{ t('login.form.password_label') }</label>
                            <Input type="password" name={'password'} value={newPassword} defaultValue={newPassword && newPassword} onKeyDown={onEnter} onInput={handlePassword} onChange={handlePassword} register={newPassword && register('password', {value: newPassword})} error={getError('password')}/>
                        </div>
                        <div className='flex justify-end mt-4'>
                            <a className={"font-normal link font-mabry text-[14px] leading-4"} href='/forgot-password'>{ t('login.form.forgot_password') }</a>
                        </div>

                        {invalidLogin && <div className='text-center text-red-500 mt-4 font-light text-sm'>{ t('login.form.invalid_login_error') }</div>}
                        <div className='flex flex-col items-center space-y-3 mt-[60px]'>
                            <Button id='form-submit-button' type={'submit'} $color='light-primary' className='px-20 w-full justify-center'>{t('general.actions.login')}</Button>
                            <a href="/register" className='text-sm font-light link'>{ t('login.form.create_an_account') }</a>
                        </div>
                    </form>
                </div>
            :
                <>
                    <div className='h-[270px] w-full bg-gray-500'>
                        <div className='container mx-auto h-full relative'>
                            <H1 className='uppercase bg-white inline-block p-2 absolute bottom-16 text-black'>{t('login.header_title')}</H1>
                        </div>
                    </div>

                    <div className='transform -translate-y-12'>
                        <div className='container mx-auto space-y-4'>
                            <div className='min-h-[512px] flex'>
                                <div className='w-5/12 bg-primary-light p-8 md:p-12 lg:p-24 space-y-6'>
                                    <H3 className='uppercase text-white'>{ t('login.block.title') }</H3>
                                    <p className='text-white font-bl-pylon text-base'>{ t('login.block.text') }</p>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)} className='w-7/12 bg-white p-24'>
                                    <div className='mb-3 flex flex-col'>
                                        <label className='font-light mb-1'>{ t('login.form.email_label') }</label>
                                        <Input type='email' name={'email'} value={newEmail} defaultValue={newEmail && newEmail} onKeyDown={onEnter} onInput={handleEmail} onChange={handleEmail} register={ newEmail && register('email', {value: newEmail})} error={getError('email')}/>
                                    </div>
                                    <div className='flex flex-col'>
                                        <label className='font-light mb-1'>{ t('login.form.password_label') }</label>
                                        <Input type="password" name={'password'} value={newPassword} defaultValue={newPassword && newPassword} onKeyDown={onEnter} onInput={handlePassword} onChange={handlePassword} register={newPassword && register('password', {value: newPassword})} error={getError('password')}/>
                                    </div>
                                    <div className='flex justify-end'>
                                        <Link to='/forgot-password' className='text-sm font-light link'>{ t('login.form.forgot_password') }</Link>
                                    </div>

                                    {invalidLogin && <div className='text-center text-red-500 mt-4 font-light text-sm'>{ t('login.form.invalid_login_error') }</div>}
                                    <div className='flex flex-col items-center space-y-3 mt-8'>
                                        <Button id='form-submit-button' type={'submit'} $color='light-primary' className='px-20'>{t('general.actions.login')}</Button>
                                        <a href="/register" className='text-sm font-light link'>{ t('login.form.create_an_account') }</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            }

            <Footer />
        </div>
    )
}

export default withTranslation()(LoginPage)