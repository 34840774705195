import AppStorage, {StorageKey} from "./AppStorage";
import axios, {AxiosRequestHeaders} from "axios";
import {AxiosError} from "axios";

class Api {
    static delete(url, onSuccess, onValidate = null, onLocalFailure = null, onServerFailure = null, config = {}) {
        axios.delete(url, this.getRequestConfig())
            .then(function (response) {
                try {
                    Api.onValidate(response);
                    onValidate && onValidate(response);
                    onSuccess(response);
                } catch (error) {
                    onLocalFailure && onLocalFailure(error);
                }
            })
            .catch(function (error: AxiosError) {
                Api.onServerFailure(error);
                onServerFailure && onServerFailure(error);
            })
            .then(function () {
                // always executed
            });
    }

    static post(url, data, onSuccess, onValidate = null, onLocalFailure = null, onServerFailure = null, config = {}) {
        const finalConfig = this.getRequestConfig(config);

        axios.post(url, data, finalConfig)
            .then(function (response) {
                try {
                    !!onValidate ? onValidate(response) : Api.onValidate();
                    onSuccess(response);
                } catch (error) {
                    onLocalFailure && onLocalFailure(error);
                }
            })
            .catch(function (error: AxiosError) {
                Api.onServerFailure(error);
                onServerFailure && onServerFailure(error);
            })
            .then(function () {
                // always executed
            });
    }

    static put(url, data, onSuccess, onValidate = null, onLocalFailure = null, onServerFailure = null, config = {}) {
        const finalConfig = this.getRequestConfig(config);

        axios.put(url, data, finalConfig)
            .then(function (response) {
                try {
                    !!onValidate ? onValidate(response) : Api.onValidate();
                    onSuccess(response);
                } catch (error) {
                    onLocalFailure && onLocalFailure(error);
                }
            })
            .catch(function (error: AxiosError) {
                Api.onServerFailure(error);
                onServerFailure && onServerFailure(error);
            })
            .then(function () {
                // always executed
            });
    }

    static patch(url, data, onSuccess, onValidate = null, onLocalFailure = null, onServerFailure = null, config = {}) {
        const finalConfig = this.getRequestConfig(config);

        axios.patch(url, data, finalConfig)
            .then(function (response) {
                try {
                    !!onValidate ? onValidate(response) : Api.onValidate();
                    onSuccess(response);
                } catch (error) {
                    onLocalFailure && onLocalFailure(error);
                }
            })
            .catch(function (error: AxiosError) {
                Api.onServerFailure(error);
                onServerFailure && onServerFailure(error);
            })
            .then(function () {
                // always executed
            });
    }

    static get(url, onSuccess, onValidate = null, onLocalFailure = null, onServerFailure = null, config = {}) {
        const finalConfig = this.getRequestConfig(config);

        axios.get(url, finalConfig)
            .then(function (response) {
                try {
                    !!onValidate ? onValidate(response) : Api.onValidate();
                    onSuccess(response);
                } catch (error) {
                    onLocalFailure && onLocalFailure(error);
                }
            })
            .catch(function (error: AxiosError) {
                Api.onServerFailure(error);
                onServerFailure && onServerFailure(error);
            })
            .then(function () {
                // always executed
            });
    }

    static onValidate(response = null) {

    }

    static onServerFailure(error) {
        //We're not logged, thus try to login and go back to the current url
        console.error(error)
        if(error.response) {
            if (error.response.status === 401) {
                //remove api access, will logout automatically
                AppStorage.remove(StorageKey.USER_ID);
                AppStorage.remove(StorageKey.API_ACCESS_TOKEN);
                if(window.location.pathname !== '/login') {
                    window.location.replace(`/login${`?return=${window.location.pathname}${window.location.search}`}`);
                }
            } else {
                // user feedback
            }
        } else {
            // user feedback
        }
    }


    static getRequestConfig(config: {headers?: AxiosRequestHeaders} = {}) {
        let defaultConfig = {
            baseURL: process.env.REACT_APP_API_URL,
            headers: null
        };

        let defaultHeaders = {};

        const accessToken = AppStorage.get(StorageKey.API_ACCESS_TOKEN);
        if (accessToken) {
            defaultHeaders["Authorization"] = 'Bearer ' + accessToken;
        }

        defaultConfig.headers = defaultHeaders;
        return {
            ...defaultConfig,
            ...config,
            headers: {
                ...defaultConfig.headers,
                ...config.headers
            },
            validateStatus: function (statusCode) {
                return statusCode >= 200 && statusCode < 300

            }
        };
    }
}

export default Api;