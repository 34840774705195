import Icon from "../Icon";
import {faEye, faEyeSlash, faTrash} from "@fortawesome/free-solid-svg-icons";
import {withTranslation, WithTranslation} from "react-i18next";
import {Annotation as AnnotationInterface} from "../../types/entities";
import {SvgIcon} from "../../styled-components/SvgIcon";
import {useAnnotation} from "../../providers/AnnotationProvider";
import React, {useEffect, useState} from "react";
import {Tooltip} from "@mui/material";
import scene from "../../lang/pages/scene";
import {useScene} from "../../providers/SceneProvider";

interface AnnotationProps extends WithTranslation {
    annotation: AnnotationInterface
}

function Annotation({t, annotation}: AnnotationProps) {
    const annotationProvider = useAnnotation()
    const [isActive, setIsActive] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const scene = useScene();

    useEffect(() => {
        if (annotationProvider.activeAnnotation) {
            setIsActive(annotation.id === annotationProvider.activeAnnotation.id)
        } else {
            setIsActive(false)
        }
    }, [annotationProvider.activeAnnotation])

    const selectAnnotation = (): void => {
        annotationProvider.setActiveAnnotation(annotation)
        annotationProvider.annotorious.selectAnnotation(annotation.base.id)

        annotationProvider.annotorious._app.current.annotationLayer.selectedShape.element.dispatchEvent(new MouseEvent('mouseenter'));
        annotationProvider.annotorious._app.current.annotationLayer.selectedShape.element.dispatchEvent(new MouseEvent('mouseleave'));
    }

    const deleteAnnotation = (e: React.MouseEvent<SVGSVGElement, MouseEvent>): void => {
        e.stopPropagation();
        annotationProvider.deleteAnnotation(annotation);
    }

    const hideAnnotation = (e: React.MouseEvent<SVGSVGElement, MouseEvent>): void => {
        e.stopPropagation();
        setIsHidden(!isHidden)
        if (annotation.base && annotation.base.id) {
            const element = document.querySelector(".a9s-annotation[data-id=" + CSS.escape(annotation.base.id) + "]") as HTMLElement;      
            if (!isHidden) {
                element.style.setProperty('display', 'none')
                element.style.pointerEvents = 'none'
            } else {
                element.style.setProperty('display', 'block')
            }
        }
    }

    const getType = (): string => {
        if (annotation.type === 'polygon') {
            return t('general.entities.polygon.singular')
        }

        if (annotation.type === 'rect') {
            return t('general.entities.rectangle.singular')
        }

        return '';
    }

    const getColour = (): string => {
        if (annotation?.base?.body?.value == null) {
            return '';
        }

        return `bg-${annotation.base.body.value.toLowerCase()}-300`
    }

    return (
        <div className={`h-13 flex items-center rounded border-l-[6px] border-gray-500 px-4 cursor-pointer ${isActive ? 'bg-gray-300' : 'bg-gray-100'}`} onClick={() => selectAnnotation()} >
            <div className='flex items-center space-x-5 flex-1'>
                {annotation.type === 'polygon' ? <SvgIcon src="/assets/icons/polygon.svg" alt="polygon icon" /> : null}
                {annotation.type === 'rect' ? <SvgIcon src="/assets/icons/rectangle.svg" alt="rectangle icon" /> : null}
                <div className='font-bold flex-1'>{getType()}</div>
                <div className={`w-[15px] h-[15px] rounded-full border-2 border-white ${getColour()}`}/>
                {
                    !scene.isSubStep &&
                    <Tooltip title={t('general.tooltips.show_hide_annotation')}  enterDelay={500}>
                        <span>
                            {isHidden ?
                                <Icon icon={faEyeSlash} onClick={(e) => hideAnnotation(e)}/> :
                                <Icon icon={faEye} onClick={(e) => hideAnnotation(e)}/>
                            }
                        </span>
                    </Tooltip>
                }
                <Tooltip title={t('general.tooltips.delete_annotation')}  enterDelay={500}>
                    <span>
                        <Icon icon={faTrash} onClick={(e) => deleteAnnotation(e)}/>
                    </span>
                </Tooltip>
            </div>
        </div>
    )
}

export default withTranslation()(Annotation)